import { useForm, Controller } from 'react-hook-form';

import Link from 'next/link';

import { yupResolver } from '@hookform/resolvers/yup';

import { usePhoneAuth } from '@modules/auth/hooks/useAuth';
import { authFormSchema, AuthFormValues } from '@modules/auth/authFormSchema';

import PhoneInput from '@components/PhoneInput';
import Button from '@components/Button';
import Text from '@components/Text';

import s from './AuthForm.module.scss';

interface Props {
  onSubmit: (data: AuthFormValues) => void;
}

const AuthForm = ({ onSubmit }: Props) => {
  const [authMutation, { loading, error }] = usePhoneAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthFormValues>({
    resolver: yupResolver(authFormSchema),
  });

  const handleFormSubmit = async (data: AuthFormValues) => {
    try {
      await authMutation({ variables: data });
      onSubmit(data);
    } catch (er) {
      // eslint-disable-next-line no-console
      console.log('error', er);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className={s.formItem}>
        <Controller
          control={control}
          name="phone"
          render={({ field }) => (
            <PhoneInput value={field.value} onChange={field.onChange} label="Телефон" isError={errors.phone} />
          )}
        />
      </div>

      {error ? (
        <Text className={s.errorText} size="small" fontgraphik>
          Повторите позже
        </Text>
      ) : null}

      <Button
        disabled={loading || !!errors.phone}
        type="submit"
        size="large"
        fontgraphik
        fullWidth
        className={s.button}
      >
        Получить код
      </Button>

      <Text fontgraphik muted className={s.text}>
        Нажимая «Получить код», вы принимаете условия
        <br />
        <Link href="/terms">
          <a target="_blank" rel="noreferrer">
            Пользовательского соглашения
          </a>
        </Link>
      </Text>
    </form>
  );
};

export default AuthForm;
