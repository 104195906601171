import * as yup from 'yup';

import { isValidPhone } from '@utils/isValidPhone';

const ALLOW_FIFTEEN_DIGITS_PHONE = false;

export const authFormSchema = yup
  .object({
    phone: yup
      .string()
      .trim()
      .test('is-phone', '', (value) => isValidPhone(value, ALLOW_FIFTEEN_DIGITS_PHONE))
      .required(),
  })
  .required();

export type AuthFormValues = yup.InferType<typeof authFormSchema>;
