import { usePayHomeMutation } from '@graphql/generated';
import { useState } from 'react';

export const StepOrderVerificationViewModel = () => {
  const [PayHomeMutation] = usePayHomeMutation();
  const [loadingPayHome, setLoadingPayHome] = useState(false);

  let orderId: string = '';
  function getOrderId(id: string) {
    orderId = id;
  }

  async function sendOrderHomePay() {
    if (orderId && parseInt(orderId, 10) > 0) {
      try {
        setLoadingPayHome(true);
        await PayHomeMutation({
          variables: {
            input: {
              orderId,
            },
          },
        });

        return true;
      } catch (error) {
        return false;
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }

  return {
    sendOrderHomePay,
    getOrderId,
    loadingPayHome,
  };
};
