import { ReactNode } from 'react';

import Title from '@components/Title';
import Text from '@components/Text';
import s from './StepHeader.module.scss';

interface Props {
  title: string;
  notice?: string | ReactNode;
}

const StepHeader = ({ title, notice }: Props) => (
  <>
    <Title level={1} weight="medium" className={s.title}>
      {title}
    </Title>

    {notice ? (
      <Text size={'extra-large'} className={s.notice} muted>
        {notice}
      </Text>
    ) : (
      notice
    )}
  </>
);

export default StepHeader;
