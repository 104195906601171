import React from 'react';
import Image from 'next/image';
import classNames from 'classnames';

import { labsList } from '@components/constants';
import styles from './PartnersCards.module.scss';

const PartnersCards: React.FC<{ className?: string; labs?: string[] }> = ({ className, labs }) => (
  <div className={classNames(styles.root, className)}>
    {labsList.map(({ id, name, imageSrc, width, height }) =>
      labs ? (
        labs.includes(name) && (
          <div key={id} className={styles.partnerCard}>
            <Image alt="lab" src={imageSrc} width={width} height={height} layout="fixed" />
          </div>
        )
      ) : (
        <div key={id} className={styles.partnerCard}>
          <Image alt="lab" src={imageSrc} width={width} height={height} layout="fixed" />
        </div>
      )
    )}
  </div>
);

export default PartnersCards;
