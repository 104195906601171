import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { useDebouncedCallback } from 'use-debounce';
import useSearch from '@hooks/useSearch';
import { Logo } from '@components/index';
import Text from '@components/Text';
import SearchInput from '@components/SearchInput/SearchInput';
import isValidLabs from '@utils/isValidLabs';
import useMainLabels from '@hooks/useMainLabels';
import { BasketLeftIcon, UserIcon, PhoneHeaderIcon } from '@components/Icon';
import { CONTACTS_PHONE } from '@constants/index';
import { useBasket } from '@hooks/useBasket';
import { useProfile } from '@modules/profile/hooks/useProfile';
import ProfileName from '@components/ProfileName';
import { useAuthModal } from '@modules/auth/AuthContext';
import useDeviceType from '@hooks/useDeviceType';
import { SCREEN } from '@constants/screen';

import styles from './HeaderFull.module.scss';

const HeaderFull: React.FC<{ className?: string }> = ({ className }) => {
  const router = useRouter();

  const { mainLabelsForSearch } = useMainLabels();
  const { basket, onAddToBasket, onRemoveProductFromBasket } = useBasket();
  const { profile, isUnauthorized } = useProfile();
  const { screenWidth } = useDeviceType();
  const { onModalOpen } = useAuthModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { onFindProducts, products, loadingSearch } = useSearch();

  const [searchValue, setSearchValue] = useState<string>(router?.query?.search ? String(router?.query?.search) : '');
  const basketCount = basket?.products.length || 0;

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    handleFindProductsDebounced(value);
  };

  const handleFindProductsDebounced = useDebouncedCallback(async (value: string) => {
    setIsLoading(true);
    await onFindProducts(String(value));
    setIsLoading(false);
  }, 500);

  const handleProductClick = (id: string | undefined, isInBasket: boolean) => {
    if (!id) {
      return;
    }

    if (isInBasket) {
      onRemoveProductFromBasket(id);
    } else {
      onAddToBasket(id);
    }
  };

  const containerClass = 'container-full-width';

  return (
    <>
      <header className={cn(styles.root, containerClass)}>
        <nav className={cn(styles.navBar, className)}>
          {screenWidth < SCREEN.MOBILE_MD_BREAKPOINT ? (
            <div className={cn(styles.logo, styles.logoMobile)}>
              <Logo />
            </div>
          ) : (
            <div className={styles.searchDesktop}>
              <div className={styles.logo}>
                <Logo isWithTitle={false} />
              </div>
              <SearchInput
                value={searchValue}
                onChange={handleSearchValue}
                suggestions={products.filter((product) => isValidLabs(product)).map((product) => ({ ...product }))}
                suggestionsMenuClass={styles.suggestionsMenu}
                isLoading={isLoading || loadingSearch}
                onSuggestionClick={handleProductClick}
                showAllResearchesBtn
              />
            </div>
          )}

          <ul className={styles.linksList}>
            {!!mainLabelsForSearch.length &&
              // <div className={s.scrollButtons}>
              mainLabelsForSearch.map((item) => {
                const isActive = router?.query.category === item.id;

                return (
                  <Link href={item.id ? `/search?category=${item.id}` : '/search'} key={item.id} passHref>
                    <li key={item.id} className={cn(isActive ? styles.active : '')}>
                      <Link href={`/search?category=${item.id}`}>
                        <a>
                          <Text size="medium" weight="medium" lineInherit={true}>
                            {item.title}
                          </Text>
                        </a>
                      </Link>
                    </li>
                  </Link>
                );
              })}
          </ul>
          <ul className={styles.iconsList}>
            <li>
              <a href={`tel:${CONTACTS_PHONE}`}>
                <PhoneHeaderIcon />
              </a>
            </li>

            <li>
              <Link href={'/basket'}>
                <a style={{ position: 'relative' }}>
                  {basketCount > 0 ? <span className={styles.basketCount}>{basketCount}</span> : null}
                  <BasketLeftIcon />
                </a>
              </Link>
            </li>

            <li className={cn(!!profile?.nameLast && !isUnauthorized ? styles.authorized : '')}>
              {!!profile?.nameLast && !isUnauthorized && (
                <Link href={'/profile'} passHref>
                  <ProfileName profile={profile} screenWidth={screenWidth} />
                </Link>
              )}
              {!profile?.nameLast && !isUnauthorized && (
                <Link href={'/profile'} passHref>
                  <UserIcon />
                </Link>
              )}
              {isUnauthorized && (
                <a onClick={onModalOpen}>
                  <UserIcon />
                </a>
              )}
            </li>
          </ul>

          {screenWidth >= SCREEN.MOBILE_MD_BREAKPOINT ? (
            <></>
          ) : (
            <SearchInput
              value={searchValue}
              onChange={handleSearchValue}
              className={styles.searchMobile}
              suggestions={products.filter((product) => isValidLabs(product)).map((product) => ({ ...product }))}
              suggestionsMenuClass={styles.suggestionsMenu}
              isLoading={isLoading || loadingSearch}
              onSuggestionClick={handleProductClick}
              showAllResearchesBtn
            />
          )}
        </nav>
      </header>
    </>
  );
};

export default HeaderFull;
