import React from 'react';

import cn from 'classnames';

import { socialsArray } from '@modules/home/constants';

import s from './SocialLinks.module.scss';

interface Props {
  className?: string;
}

const SocialLinks: React.FC<Props> = ({ className }) => {
  const classes = cn(s.root, className);

  return (
    <ul className={classes}>
      {socialsArray.map(({ id, title, href, icon }) => (
        <li key={id}>
          <a className={s.socialsLink} href={href} target="_blank" rel="noopener noreferrer" title={title}>
            {icon}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialLinks;
