import React from 'react';
import useTagsLabels from '@hooks/useTagsLabels';
import { CategoryCard } from '@components/index';
import Carousel from 'react-multi-carousel';
import cn from 'classnames';

import styles from './CategoryTable.module.scss';
import 'react-multi-carousel/lib/styles.css';

import ButtonGroup from './ButtonGroup/ButtonGroup';
import { SCREEN } from '@constants/screen';

interface TableProps {
  tableCategoryId: number;
  cardDisplayAmount?: number;
}

const CategoryTable: React.FC<TableProps> = ({ tableCategoryId }) => {
  // const DEFAULT_CARD_AMOUNT = 4;

  const { tagsLabels } = useTagsLabels(tableCategoryId || 1);
  const containerClass = 'container-full-width';
  // const labelsToShow = tagsLabels.slice(0, cardDisplayAmount || DEFAULT_CARD_AMOUNT);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: SCREEN.MOBILE_LG_BREAKPOINT },
      items: 4,
      slidesToSlide: 1,
      paritialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: SCREEN.MOBILE_LG_BREAKPOINT, min: SCREEN.MOBILE_LG_BREAKPOINT },
      items: 2,
      paritialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: SCREEN.MOBILE_LG_BREAKPOINT, min: 0 },
      items: 1,
      paritialVisibilityGutter: 120,
      centerMode: false,
      partialVisible: true,
    },
  };

  return (
    <div className={cn(styles.carouselWrapper, containerClass)}>
      <Carousel
        responsive={responsive}
        infinite={true}
        containerClass={styles.carouselContainer}
        itemClass={styles.carouselItem}
        autoPlay={false}
        shouldResetAutoplay={false}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        additionalTransfrom={0}
        partialVisible
      >
        {tagsLabels.map(({ id, title, image, productsMinPrice }) => (
          <CategoryCard
            title={title}
            minPrice={productsMinPrice!}
            image={image!}
            link={`/search?category=${tableCategoryId}&sub_category=${id}`}
            key={id}
          />
        ))}
      </Carousel>
    </div>
  );
};
export default CategoryTable;
