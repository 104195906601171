import React, { useState, TextareaHTMLAttributes } from 'react';

import cn from 'classnames';

import InputWrapper from '@components/InputWrapper';
import Text from '@components/Text';

export interface TextareaPropsTypes extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  preContent?: React.ReactNode;
  postContent?: React.ReactNode;
  isError?: boolean;
  onFocus?: (e: React.FocusEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  label?: string;
  className?: string;
}

import s from './TextArea.module.scss';

const TextArea = React.forwardRef<HTMLTextAreaElement, TextareaPropsTypes>(
  (
    { preContent, postContent, disabled = false, isError = false, name, label, className, placeholder, ...props },
    ref
  ) => {
    const [hasFocus, setFocus] = useState(false);

    const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      const { onFocus } = props;

      setFocus(true);
      onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      const { onBlur } = props;

      setFocus(false);
      onBlur?.(event);
    };

    const classNames = cn(
      s.root,
      className,
      label && s.hasLabel,
      hasFocus && s.focus,
      disabled && s.disabled,
      isError && s.error
    );

    return (
      <InputWrapper
        isError={isError}
        isDisabled={disabled}
        isFocused={hasFocus}
        preContent={preContent}
        postContent={postContent}
      >
        <>
          <textarea
            {...props}
            className={classNames}
            id={name}
            name={name}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={label ? label : placeholder}
            ref={ref}
          />
          {label ? (
            <Text className={s.label} inline muted>
              {label}
            </Text>
          ) : null}
        </>
      </InputWrapper>
    );
  }
);

export default TextArea;
