import Image from 'next/image';
import classNames from 'classnames';

import Checkbox from '@components/Checkbox';
import Title from '@components/Title';
import Text from '@components/Text';
import s from './Lab.module.scss';

interface Props {
  id: string;
  isActive?: boolean;
  title: string;
  notice?: string;
  image: string;
  onClick: (id: string) => void;
}

const Lab: React.FC<Props> = ({ id, isActive, title, notice, image, onClick }) => {
  const handleClick = () => {
    onClick(id);
  };

  return (
    <button key={title} type="button" className={classNames(s.root, isActive && s.active)} onClick={handleClick}>
      <span className={s.content}>
        <span className={s.image}>
          <Image alt={title} src={image} layout="fixed" width={48} height={48} />
        </span>

        <span className={s.naming}>
          <Title className={s.title} level={3} muted={false}>
            {title}
          </Title>
          {notice ? (
            <Text className={s.notice} inline fontgraphik size="small">
              {notice}
            </Text>
          ) : null}
        </span>
      </span>

      <Checkbox
        className={s.checkBox}
        defaultChecked={false}
        checked={isActive}
        disabled={false}
        variant="round"
        size="large"
      />
    </button>
  );
};

export default Lab;
