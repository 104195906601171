import pluralizeText, { PluralForms } from '@utils/formatPluralText';
import { ClockSmallIcon, DropIcon, SubProductsIcon, TestTubeIcon } from '@components/Icon';
import Text, { TextSize } from '@components/Text';
import { Product } from '@graphql/generated';

import styles from './AnalysisLabels.module.scss';
import classNames from 'classnames/bind';
import { useMemo } from 'react';

interface Props {
  textSize?: TextSize;
  code?: Product['code'];
  isCodeInline?: boolean;
  subProductsNumber?: number;
  deadline?: Product['deadline'];
  biomaterial?: Product['biomaterial'];
  labs?: string[];
  showLabsLabel?: boolean;
  isPackage?: boolean;
  orderPage?: boolean;
  subProducts?: Product['subProducts'];
}

const AnalysisLabels: React.FC<Props> = ({
  textSize = 'small',
  code,
  subProductsNumber,
  deadline,
  biomaterial,
  labs,
  showLabsLabel = false,
  isCodeInline = true,
  isPackage,
  subProducts,
  orderPage = false,
}) => {
  const biomaterials = useMemo(() => {
    let arr: string[] = [];
    if (isPackage && subProducts?.length) {
      subProducts.forEach((item: Product) => {
        if (item?.biomaterial?.length) {
          arr.push(item.biomaterial.trim().toLowerCase());
        }
      });
    }
    if (!isPackage && biomaterial) {
      arr.push(biomaterial);
    }
    return Array.from(new Set(arr)).join(', ');
  }, [isPackage, subProducts, biomaterial]);

  return (
    <div className={styles.root}>
      <Text
        className={classNames(styles.code, !isCodeInline && styles.wrap)}
        size={textSize}
        fontgraphik
        uppercase
        inline
      >
        {code}
      </Text>
      {!!subProductsNumber && subProductsNumber !== 0 && (
        <Text className={styles.item} size={textSize} fontgraphik uppercase inline>
          <SubProductsIcon />{' '}
          {pluralizeText(subProductsNumber, {
            [PluralForms.one]: 'анализ',
            [PluralForms.few]: 'анализа',
            [PluralForms.many]: 'анализов',
          })}
        </Text>
      )}
      {deadline && (
        <Text className={styles.item} size={textSize} fontgraphik uppercase inline>
          <ClockSmallIcon />{' '}
          {!orderPage
            ? pluralizeText(
                deadline,
                {
                  [PluralForms.one]: 'дня',
                  [PluralForms.few]: 'дней',
                  [PluralForms.many]: 'дней',
                },
                true,
                orderPage
              )
            : pluralizeText(
                deadline,
                {
                  [PluralForms.one]: 'день',
                  [PluralForms.few]: 'дня',
                  [PluralForms.many]: 'дней',
                },
                true,
                orderPage
              )}
        </Text>
      )}
      {biomaterials && (
        <Text className={styles.item} size={textSize} fontgraphik uppercase inline>
          <DropIcon /> {biomaterials}
        </Text>
      )}
      {labs && (
        <Text className={styles.item} size={textSize} fontgraphik uppercase inline>
          <TestTubeIcon /> {showLabsLabel && 'лаборатории:'} {labs.join(', ')}
        </Text>
      )}
    </div>
  );
};

export default AnalysisLabels;
