import { useMemo } from 'react';

import cn from 'classnames';
import { useSelect, UseSelectStateChange } from 'downshift';

import { SuggestionsMenu, SuggestionsMenuItem } from '@components/SuggestionsMenu';
import { DropdownArrowIcon } from '@components/Icon';

import s from './Select.module.scss';

export type SelectItemProps = {
  title: string;
  value: string;
};

interface SelectProps {
  value?: string | null;
  label?: string;
  options?: Array<SelectItemProps>;
  isError?: boolean;
  isDisabled?: boolean;
  onChange?: (changes: UseSelectStateChange<SelectItemProps>) => void;
}

const itemToString = (item: SelectItemProps | null) => item?.value ?? '';

const Select = ({ options = [], label = '', isError, isDisabled, value, onChange }: SelectProps) => {
  const controlledSelectedItem = useMemo(() => options.find((option) => option.value === value), [options, value]);
  const { isOpen, getLabelProps, getMenuProps, getToggleButtonProps, highlightedIndex, getItemProps } = useSelect({
    items: options,
    // https://github.com/downshift-js/downshift/blob/bcc793a194a7c30f84b4514b8541a6b7ee3d991f/src/utils.js#L442
    selectedItem: controlledSelectedItem || null, // null (not undefined) because controlled selectedItem must be value | null
    onSelectedItemChange: onChange,
    itemToString: itemToString,
  });

  const valueClasses = cn(s.value, { [s.active]: isOpen, [s.error]: isError, [s.disabled]: isDisabled });

  const labelClasses = cn(s.label, { [s.active]: isOpen || Boolean(controlledSelectedItem), [s.error]: isError });

  const listClasses = cn({ [s.active]: isOpen });

  return (
    <div className={s.root}>
      <button className={valueClasses} type="button" {...getToggleButtonProps()}>
        {controlledSelectedItem ? controlledSelectedItem.title : null}
      </button>

      {label ? (
        <label {...getLabelProps()} className={labelClasses}>
          {label}
        </label>
      ) : null}

      <span className={cn(s.icon, isOpen && s.active)}>
        <DropdownArrowIcon />
      </span>

      <SuggestionsMenu isOpen={isOpen} className={listClasses} {...getMenuProps()}>
        {options.map((item, index) => (
          <SuggestionsMenuItem
            key={item.title}
            className={cn(s.option, highlightedIndex === index && s.active)}
            {...getItemProps({ item, index })}
          >
            {item.title}
          </SuggestionsMenuItem>
        ))}
      </SuggestionsMenu>
    </div>
  );
};

export default Select;
