import React from 'react';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';
import cn from 'classnames';

import { CheckIcon } from '@components/Icon';
import s from './Toast.module.scss';

export const displayIcon = (type: TypeOptions) => {
  switch (type) {
    case 'success':
      return <CheckIcon />;
    case 'info':
      return <CheckIcon />;
    case 'error':
      return <CheckIcon />;
    case 'warning':
      return <CheckIcon />;
    default:
      return <CheckIcon />;
  }
};

const setClassName = ({ type }: { type?: TypeOptions } | undefined = {}) => cn(s.toast, s[type || 'default']);
const setBodyClassName = () => s.toastBody;

interface Props {
  type: TypeOptions;
  message: string;
  options?: ToastOptions;
}

const DEFAULT_OPTIONS: ToastOptions = {
  hideProgressBar: true,
  closeButton: false,
  className: setClassName,
  bodyClassName: setBodyClassName,
  closeOnClick: true,
};

const Toast = ({ type, message, options }: Props) =>
  toast(
    message,
    Object.assign(DEFAULT_OPTIONS, options, {
      type,
      icon: displayIcon(type),
    })
  );

Toast.dismiss = toast.dismiss;

export default Toast;
