import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { setHours } from 'date-fns';

import { ArrowLeftRedesignIcon, CalendarIcon } from '@components/Icon';
import { Slots } from '@graphql/generated';
import useSlots from '@hooks/useSlots';
import { DateChooserContent, Modal, Title, Text, Input, Overlay, BottomBar } from '@components/index';
import { KEY_CODES } from '@constants/keyCodes';
import { SCREEN } from '@constants/screen';
import useDeviceType from '@hooks/useDeviceType';

import { TODAY_RESETED_TIME } from './constants';
import { formatDate } from './utils';

import s from './DateChooser.module.scss';

interface Props {
  placeholderText?: string;
  value?: Date;
  onChange: (value: Date, slotId?: Slots['id']) => void;
  isError?: boolean;
  slotId?: string;
}

const DateChooser = ({
  placeholderText = 'Дата и время',
  slotId,
  value = TODAY_RESETED_TIME,
  onChange,
  ...props
}: Props) => {
  const [activeModal, setActiveModal] = useState<boolean>(false);

  const { onGetSlots, slots, loadingSlots } = useSlots();
  const { screenWidth } = useDeviceType();

  const handleCloseModal = () => {
    setActiveModal(false);
  };

  const handleOpenModal = () => {
    setActiveModal(true);
    const slotDate = new Date(value);
    slotDate.setHours(0);
    onGetSlots(slotDate);
  };

  const handleGetSlots = (date: Date) => {
    const resetedTimeDate = setHours(new Date(date), 0);
    onChange(resetedTimeDate);
    onGetSlots(resetedTimeDate);
  };

  const handleSubmit = (date: Date, timeSlotId: string) => {
    onChange(date, timeSlotId);
    handleCloseModal();
  };

  const formatedDate = useMemo(() => {
    const newValue = new Date(value);

    return formatDate(newValue);
  }, [value]);

  return (
    <div className={s.root}>
      <div className={s.input}>
        <Input
          {...props}
          label={placeholderText}
          onKeyDown={(event) => event.keyCode === KEY_CODES.ENTER_KEY && handleOpenModal()}
          onClick={handleOpenModal}
          value={formatedDate}
          onChange={() => null}
          readOnly
        />

        <CalendarIcon />
      </div>

      {screenWidth >= SCREEN.MOBILE_LG_BREAKPOINT ? (
        <Modal
          open={activeModal}
          onClose={() => setActiveModal(false)}
          showCloseButton={false}
          contentStyle={{ width: 'fit-content', padding: '24px' }}
        >
          <Title className={s.dateModalTitle} level={3}>
            Изменить дату и время
          </Title>
          <DateChooserContent
            className={s.dateChooser}
            slots={slots}
            selectedSlotId={slotId}
            isLoadingSlots={loadingSlots}
            onGetSlots={handleGetSlots}
            selectedDate={value}
            onSubmit={handleSubmit}
          />
        </Modal>
      ) : (
        <Overlay isOpen={activeModal} onClose={() => setActiveModal(false)}>
          <BottomBar
            isOpen={activeModal}
            className={classNames(activeModal && s.open)}
            contentClassName={s.bottomBarContent}
            onClose={() => setActiveModal(false)}
          >
            <Text className={s.dateBottomBarTitle} size="large-plus">
              <ArrowLeftRedesignIcon /> Изменить дату и время
            </Text>

            <DateChooserContent
              slots={slots}
              selectedSlotId={slotId}
              isLoadingSlots={loadingSlots}
              onGetSlots={handleGetSlots}
              selectedDate={value}
              onSubmit={handleSubmit}
            />
          </BottomBar>
        </Overlay>
      )}
    </div>
  );
};

export default DateChooser;
