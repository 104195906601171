import React, { InputHTMLAttributes, ReactNode } from 'react';

import cn from 'classnames';

import { CheckboxCheckIcon, CheckboxCheckBoldIcon } from '@components/Icon';
import Text from '@components/Text';

import s from './Checkbox.module.scss';

interface InputPropsTypes extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string | ReactNode;
  className?: string;
  name?: string;
  isError?: boolean;
  disabled?: boolean;
  variant?: 'round' | 'square';
  size?: 'medium' | 'large';
}

const Checkbox = React.forwardRef<HTMLInputElement, InputPropsTypes>(
  (
    { label, name, className, isError, checked, disabled = false, variant = 'square', size = 'medium', ...props },
    ref
  ) => {
    const classes = cn(s.root, className, isError && s.isError);

    return (
      <label className={classes}>
        <input disabled={disabled} type="checkbox" checked={checked} name={name} ref={ref} {...props} />

        <div className={cn(s.checkBlock, s[`size-${size}`], s[`variant-${variant}`], { [s.checked]: checked })}>
          {variant === 'square' ? <CheckboxCheckIcon /> : <CheckboxCheckBoldIcon />}
        </div>

        {label ? (
          <Text size={'medium'} className={s.text}>
            {label}
          </Text>
        ) : null}
      </label>
    );
  }
);

export default Checkbox;
