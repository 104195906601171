import { ReactNode } from 'react';
import Popup from 'reactjs-popup';

import Button from '@components/Button';

import { CrossIcon } from '@components/Icon';

import { isServer } from '@constants/index';

import s from './Modal.module.scss';

interface Props {
  className?: string;
  contentStyle?: {};
  children: ReactNode;
  trigger?: JSX.Element;
  lockScroll?: boolean;
  open?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
}

const Modal = ({ children, lockScroll = true, open = false, showCloseButton = true, ...props }: Props) => {
  if (isServer) {
    return null;
  }

  return (
    <Popup open={open} modal lockScroll={lockScroll} {...props}>
      {(close: () => void) => (
        <>
          {showCloseButton && (
            <Button theme="ghost" onClick={close} className={s.closeButton}>
              <CrossIcon />
            </Button>
          )}
          {children}
        </>
      )}
    </Popup>
  );
};

export default Modal;
