import { useCallback } from 'react';

import { useGetSlotsLazyQuery } from '@graphql/generated';

const useSlots = () => {
  const [runQuery, { data, loading }] = useGetSlotsLazyQuery();

  const onGetSlots = useCallback(
    async (date: Date) => {
      await runQuery({ variables: { date } });
    },
    [runQuery]
  );

  return {
    onGetSlots,
    slots: data?.getSlots.nodes || [],
    loadingSlots: loading,
  };
};

export default useSlots;
