import { gql } from '@apollo/client';

import { BASKET_PRODUCT_FRAGMENT } from '@graphql/fragments/basket';

export const GET_MY_BASKET = gql`
  query GetMyBasket {
    getMyBasket {
      sum
      total
      nurseVisit
      products {
        ...BasketProductsFields
      }
    }
  }
  ${BASKET_PRODUCT_FRAGMENT}
`;

export const GET_SERVICE_BY_KEY = gql`
  query GetServiceByKey($key: String!) {
    getServiceByKey(key: $key) {
      id
      key
      title
      value
    }
  }
`;
