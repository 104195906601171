import { useCallback } from 'react';
import { NetworkStatus } from '@apollo/client';

import { useFindProductsLazyQuery } from '@graphql/generated';
import { useBasket } from '@hooks/useBasket';
import { getIsInBasketProducts } from '@utils/getIsInBasketProducts';

const PER_PAGE = 30;

const useSearch = () => {
  const { basket } = useBasket();
  const [runQuery, { data: products, fetchMore, loading, networkStatus }] = useFindProductsLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const onFindProducts = useCallback(
    async (value: string, labels?: Array<string>) => {
      await runQuery({ variables: { search: value, labels, offsetPaging: { offset: 0, limit: PER_PAGE } } });
    },
    [runQuery]
  );

  const onFetchMore = useCallback(async () => {
    if (!products?.findProducts?.pageInfo?.hasNextPage || networkStatus !== NetworkStatus.ready) {
      return;
    }

    await fetchMore({ variables: { offsetPaging: { offset: products?.findProducts.nodes.length, limit: PER_PAGE } } });
  }, [fetchMore, networkStatus, products?.findProducts]);

  const productsCheckedInBasket = getIsInBasketProducts(products?.findProducts.nodes || [], basket);

  return {
    onFindProducts,
    onFetchMore,
    products: productsCheckedInBasket,
    loadingSearch: loading,
    isFetchingMore: networkStatus === NetworkStatus.fetchMore,
  };
};

export default useSearch;
