import CarouselArrow from '@components/CarouselArrow/CarouselArrow';
import classNames from 'classnames';
import styles from './ButtonGroup.module.scss';
interface Props {
  className?: string;
  next?: () => void;
  previous?: () => void;
}

const ButtonGroup: React.FC<Props> = ({ next = () => {}, previous = () => {}, className }) => (
  <div className={classNames('carousel-button-group', className, styles.root)}>
    <CarouselArrow onClick={previous} />

    <CarouselArrow direction="right" onClick={next} />
  </div>
);

export default ButtonGroup;
