import { Gender } from '@graphql/generated';

export const SITE_ADDRESS = 'labclick.ru';
export const FULL_SITE_ADDRESS = `https://www.${SITE_ADDRESS}`;
export const CONTACTS_EMAIL = `info@${SITE_ADDRESS}`;

export const DEFAULT_API_URI = `${FULL_SITE_ADDRESS}/graphql`;

export const TOKEN_STORAGE_KEY = 'token';
export const FOR_CHILD_STORAGE_KEY = 'forChild';

export const CURRENT_YEAR = new Date().getFullYear();

export const DATE_NOW = new Date().toISOString();

export const CURRENCY_SYMBOL = '₽';

export const ORDER_STATUS_TYPES = {
  CREATED: 'CREATED',
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  READY: 'READY',
  CANCELED: 'CANCELED',
  ARCHIVED: 'ARCHIVED',
} as const;

export const ORDER_STATUSES = [
  { type: ORDER_STATUS_TYPES.CREATED, title: 'Создан' },
  { type: ORDER_STATUS_TYPES.NEW, title: 'Новый' },
  { type: ORDER_STATUS_TYPES.IN_PROGRESS, title: 'В работе' },
  { type: ORDER_STATUS_TYPES.READY, title: 'Готов' },
  { type: ORDER_STATUS_TYPES.CANCELED, title: 'Отменен' },
  { type: ORDER_STATUS_TYPES.ARCHIVED, title: 'Архив' },
] as const;

export const GENDER_VALUES = [
  {
    value: Gender.Male,
    title: 'Мужской',
  },
  {
    value: Gender.Female,
    title: 'Женский',
  },
  {
    value: '', // is parsed as undefined (useProfile.ts line:32)
    title: 'Предпочитаю не указывать',
  },
];

export const isServer = typeof window === 'undefined';

export const START_TIME_TIMER = 60;

export const VERIFY_CODE_LENGTH = 6;

export const YANDEX_MAPS_SCRIPT_URL = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${process.env.NEXT_PUBLIC_YANDEX_API_KEY}`;

export const YOOMONEY_WIDGET_URL = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';

export const CONTACTS_PHONE = '+7 (926) 515-68-41';

export const TG_USERNAME = 'Lab_Click';

export const AUTH_ONLY_PATHES = ['/profile', '/profile/addresses', '/profile/orders', '/profile/help'];

export const TOAST_SHOW_TIME = 5000;
