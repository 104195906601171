import { useState, useEffect } from 'react';

import cn from 'classnames';

import { usePhoneAuth } from '@modules/auth/hooks/useAuth';

import Text from '@components/Text';
import Button from '@components/Button';

import { minTwoDigits } from '@utils/minTwoDigits';
import { START_TIME_TIMER } from '@constants/index';

import s from './Timer.module.scss';

export interface PropsTypes {
  className?: string;
  phone: string;
}

const Timer = ({ className, phone }: PropsTypes) => {
  const [authMutation, { loading }] = usePhoneAuth();

  const [counter, setCounter] = useState(START_TIME_TIMER);

  useEffect(() => {
    const timer = counter > 0 ? setInterval(() => setCounter(counter - 1), 1000) : 0;

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [counter]);

  const formatedCount = minTwoDigits(counter);

  const resetCounter = () => {
    setCounter(START_TIME_TIMER);
  };

  const handleSubmitCode = async () => {
    try {
      await authMutation({ variables: { phone: phone } });
      resetCounter();
    } catch (er) {
      // eslint-disable-next-line no-console
      console.log('error', er);
    }
  };

  return (
    <div className={cn(s.root, className)}>
      {counter ? (
        <Text className={s.text} size="medium" fontgraphik muted>
          Отправить код повторно через <span className={s.time}>0:{formatedCount}</span>
        </Text>
      ) : (
        <Button theme="ghost" onClick={handleSubmitCode} className={s.link} disabled={loading} type="button">
          Отправить повторно
        </Button>
      )}
    </div>
  );
};

export default Timer;
