import { Reducer, useCallback, useEffect, useReducer } from 'react';

import { Address, Product, User } from '@graphql/generated';
import { GUEST_PROFILE_KEY } from '@modules/profile/constants';

export type GuestData = {
  address: Partial<Address>;
  profile: Partial<User>;
  products: Array<Record<'productId', Product['id']>>;
};

export const DEFAULT_DATA: GuestData = {
  address: {},
  profile: {},
  products: [],
};

enum ActionType {
  SET_ADDRESS,
  SET_PROFILE,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  EMPTY_PRODUCTS,
  SET_ALL,
  RESET,
}

type Action =
  | {
      type: ActionType.SET_ADDRESS;
      payload: GuestData['address'];
    }
  | {
      type: ActionType.SET_PROFILE;
      payload: GuestData['profile'];
    }
  | {
      type: ActionType.ADD_PRODUCT;
      payload: Record<'productId', Product['id']>;
    }
  | {
      type: ActionType.REMOVE_PRODUCT;
      payload: Record<'productId', Product['id']>;
    }
  | {
      type: ActionType.EMPTY_PRODUCTS;
    }
  | {
      type: ActionType.SET_ALL;
      payload: GuestData;
    }
  | {
      type: ActionType.RESET;
    };

function reducer(state: GuestData, action: Action) {
  switch (action.type) {
    case ActionType.SET_ADDRESS:
      return { ...state, address: { ...state.address, ...action.payload } };
    case ActionType.SET_PROFILE:
      return { ...state, profile: { ...state.profile, ...action.payload } };
    case ActionType.ADD_PRODUCT:
      return { ...state, products: [...state.products, action.payload] };
    case ActionType.REMOVE_PRODUCT:
      return { ...state, products: state.products.filter((product) => product.productId !== action.payload.productId) };
    case ActionType.EMPTY_PRODUCTS:
      return { ...state, products: [] };
    case ActionType.SET_ALL:
      return { ...state, ...action.payload };
    case ActionType.RESET:
      return { ...DEFAULT_DATA };
    default:
      return state;
  }
}

export function useGuest() {
  const [state, dispatch] = useReducer<Reducer<GuestData, Action>>(reducer, DEFAULT_DATA);

  useEffect(() => {
    const storageData = localStorage.getItem(GUEST_PROFILE_KEY);
    const guestProfileData: GuestData = storageData ? JSON.parse(storageData) : DEFAULT_DATA;

    dispatch({ type: ActionType.SET_ALL, payload: guestProfileData });
  }, []);

  useEffect(() => {
    localStorage.setItem(GUEST_PROFILE_KEY, JSON.stringify(state));
  }, [state]);

  const onAddressChange = useCallback((values: Partial<Address>) => {
    dispatch({ type: ActionType.SET_ADDRESS, payload: values });
  }, []);

  const onProfileChange = useCallback((values: Partial<User>) => {
    dispatch({ type: ActionType.SET_PROFILE, payload: values });
  }, []);

  const onAddToProducts = useCallback((productId: Product['id']) => {
    dispatch({ type: ActionType.ADD_PRODUCT, payload: { productId } });
  }, []);

  const onRemoveFromProducts = useCallback((productId: Product['id']) => {
    dispatch({ type: ActionType.REMOVE_PRODUCT, payload: { productId } });
  }, []);

  const onEmptyProducts = useCallback(() => {
    dispatch({ type: ActionType.EMPTY_PRODUCTS });
  }, []);

  const onClearData = useCallback(() => {
    localStorage.removeItem(GUEST_PROFILE_KEY);
    dispatch({ type: ActionType.RESET });
  }, []);

  return {
    address: state.address,
    profile: state.profile,
    products: state.products,
    productIds: state.products.map(({ productId }) => productId),
    onAddressChange,
    onProfileChange,
    onAddToProducts,
    onRemoveFromProducts,
    onEmptyProducts,
    onClearData,
  };
}
