import classNames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';

import styles from './Logo.module.scss';

interface LogoProps {
  className?: string;
  isWithTitle?: boolean;
}

const Logo = ({ className, isWithTitle = true }: LogoProps) => (
  <Link href="/">
    <a className={classNames(styles.root, className, isWithTitle && styles.withTitle)}>
      <Image alt="logo" src={isWithTitle ? '/images/logo.svg' : '/images/logoIcon.svg'} layout="fill" color="#fff" />
    </a>
  </Link>
);

export default Logo;
