import { useMemo } from 'react';
import { useQueryGetSubCategoryLabelsQuery } from '@graphql/generated';

const useTagsLabels = (categoryId: number) => {
  const { data } = useQueryGetSubCategoryLabelsQuery({ variables: { categoryId } });

  return useMemo(() => ({ tagsLabels: data?.getSubCategoryLabels?.subCategory || [] }), [data]);
};

export default useTagsLabels;
