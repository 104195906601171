import cn from 'classnames';

import FooterNav from './FooterNav';
import s from './Footer.module.scss';

const Footer: React.FC<JSX.IntrinsicElements['footer']> = ({ className }) => {
  const classes = cn(s.root, className, 'container-full-width');

  return (
    <footer className={classes}>
      <FooterNav />
    </footer>
  );
};

export default Footer;
