import classNames from 'classnames';
import { getHours, getMinutes } from 'date-fns';

import Button from '@components/Button';
import Text from '@components/Text';

import styles from './TimeButtons.module.scss';

interface Props {
  slots: Array<{
    id: string;
    type?: string;
    date: Date;
    isActive?: boolean;
    isValid?: boolean;
    slotDayId?: string;
    label?: string;
  }>;
  type: 'MORNING' | 'DAY';
  handleClick: (hours: number, minutes: number, id: string) => void;
  timeChoosedId: number | string;
}

const TimeButtons = ({ slots = [], timeChoosedId, handleClick, type }: Props) => {
  if (slots.length === 0) {
    return <Text className={styles.pureText}>Свободного времени нет</Text>;
  }

  return (
    <div className={styles.root}>
      {slots
        .filter((item) => item.type === type)
        .map((slot) => ({ ...slot, ...(slot.label && { label: `0${slot.label}`.slice(-5) }) }))
        .map((slot) => {
          const hours = getHours(new Date(slot.date));
          const minutes = getMinutes(new Date(slot.date));

          return (
            <Button
              size="medium"
              theme="ghost"
              key={slot.id}
              onClick={() => {
                if (slot.isActive && slot.isValid) {
                  handleClick(hours, minutes, slot.id);
                }
              }}
              className={classNames(styles.timeSlot, slot.id === timeChoosedId && styles.choosedButton)}
              disabled={!slot.isActive || !slot.isValid}
            >
              {slot.label}
            </Button>
          );
        })}
    </div>
  );
};

export default TimeButtons;
