export enum PluralForms {
  one,
  few,
  many,
}

function pluralizeText(
  count: number | undefined,
  lookup: { [key in PluralForms]: string },
  additional: boolean = false,
  orderPage: boolean = false
) {
  if (count == null) return;

  const lastTwoDigits = +count.toString().slice(-2);
  const lastDigit = +count.toString().slice(-1);

  let key: PluralForms;

  if (isInRange(lastTwoDigits, [0, 0]) || isInRange(lastTwoDigits, [5, 19])) {
    key = PluralForms.many;
  } else if (lastDigit === 1) {
    key = PluralForms.one;
  } else {
    key = PluralForms.few;
  }

  if (additional && !orderPage) {
    return `от ${count} ${lookup[key]}`;
  } else {
    return `${count} ${lookup[key]}`;
  }
}

function isInRange(numberToCheck: number, range: [number, number]) {
  return numberToCheck >= range[0] && numberToCheck <= range[1];
}

export default pluralizeText;
