import React, { useState } from 'react';

import cn from 'classnames';

import { useBasket } from '@hooks/useBasket';
import formatPhone from '@utils/formatPhone';
import { formatPrice } from '@utils/formatPrice';

import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import StepHeader from '@modules/checkout/components/StepHeader';
import { useCheckoutWizard } from '@modules/checkout/CheckoutWizardContext';

import { Button, Text, Title } from '@components/index';

import s from './StepOrderVerification.module.scss';
import getLabNameByEnumValue from '@utils/getLabNameByEnumValue';
import CheckOutProductCard from '@modules/checkout/components/StepOrderVerification/CheckOutProductCard';
import PaymentModal from '@modules/checkout/components/PaymentModal/PaymentModal';
import { useDeadline } from '@hooks/useDeadline';
import { StepOrderVerificationViewModel } from '@modules/checkout/components/StepOrderVerification/StepOrderVerificationViewModel';
import { useRouter } from 'next/router';
import Loader from '@components/Loader';

const StepOrderVerification = () => {
  const { formValues, goToPrevStep } = useCheckoutWizard();
  const [paymentDisabled, setPaymentDisabled] = useState(false);
  const { basket, forChild } = useBasket();
  const {
    nameFirst,
    nameLast,
    nameMiddle,
    passportNumber,
    phone,
    email,
    address,
    floor,
    entrance,
    apartment,
    comment,
    date,
    laboratories,
  } = formValues;
  const fullName = `${nameLast} ${nameFirst} ${nameMiddle}`;
  const router = useRouter();

  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const { sendOrderHomePay, getOrderId, loadingPayHome } = StepOrderVerificationViewModel();

  const payHome = async () => {
    try {
      const res = await sendOrderHomePay();
      if (res) {
        router.push('payment-success?home=ok');
      } else {
        router.push('payment-failure');
      }
    } catch (err) {
      router.push('payment-failure');
    }
  };

  const handleModalError = () => {
    setPaymentDisabled(true);
  };

  const handlePrevButtonOnClick = () => {
    goToPrevStep();
  };

  const handlePaymentOnClick = (isOpen: boolean) => {
    setIsOpenPaymentModal(isOpen);
  };

  const getTotalPrice = () => basket?.total; // TODO:

  const checkOutProductCardView = (item: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { deadline } = useDeadline(item.product, item.laboratory);
    return (
      <CheckOutProductCard
        className={s.productItem}
        key={item.id}
        id={item.product.id}
        code={item.product.code}
        title={item.product.title}
        deadline={deadline}
        price={item.product.price}
        subProducts={item.product.subProducts}
        isPackage={item.product.isPackage}
        lab={getLabNameByEnumValue(item.laboratory)}
        biomaterial={item.product.biomaterial}
        orderPage={true}
      ></CheckOutProductCard>
    );
  };

  return (
    <>
      <StepHeader title="Подтверждение заказа" />
      <div className={s.cardWrapper}>
        <Title level={3} className={s.cardTitle}>
          Контактные данные
        </Title>
        <div className={s.cardContent}>
          {/*TODO: we could move the card to the separate component*/}
          <div className={s.cardItem}>
            <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
              Пациент
            </Text>
            <Text size={'large'} className={s.cardItemValue}>
              {fullName}
            </Text>
          </div>
          <div className={s.cardItem}>
            <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
              Номер и серия паспорта
            </Text>
            <Text size={'large'} className={s.cardItemValue}>
              {passportNumber}
            </Text>
          </div>
          <div className={s.cardItem}>
            <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
              Номер телефона
            </Text>
            <Text size={'large'} className={s.cardItemValue}>
              {formatPhone(phone)}
            </Text>
          </div>
          <div className={s.cardItem}>
            <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
              E-mail
            </Text>
            <Text size={'large'} className={s.cardItemValue}>
              {email}
            </Text>
          </div>
        </div>
      </div>
      <div className={s.cardWrapper}>
        <Title level={3} className={s.cardTitle}>
          Выезд медсестры
        </Title>
        <div className={s.cardContent}>
          {/*TODO: we could move the card to the separate component*/}
          <div className={s.cardItem}>
            <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
              Дата
            </Text>
            <Text size={'large'} className={s.cardItemValue}>
              {date && format(new Date(date), 'd MMMM, H:mm', { locale: ru })}
            </Text>
          </div>
          <div className={s.cardItem}>
            <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
              Адрес
            </Text>
            <Text size={'large'} className={s.cardItemValue}>
              {address}
            </Text>
          </div>
          {apartment && (
            <div className={s.cardItem}>
              <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
                Квартира
              </Text>
              <Text size={'large'} className={s.cardItemValue}>
                {apartment}
              </Text>
            </div>
          )}
          {floor && (
            <div className={s.cardItem}>
              <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
                Этаж
              </Text>
              <Text size={'large'} className={s.cardItemValue}>
                {floor}
              </Text>
            </div>
          )}
          {entrance && (
            <div className={s.cardItem}>
              <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
                Подъезд
              </Text>
              <Text size={'large'} className={s.cardItemValue}>
                {entrance}
              </Text>
            </div>
          )}
          {comment && (
            <div className={s.cardItem}>
              <Text size={'large'} className={cn(s.cardItemTitle, s.light)}>
                Комментарий
              </Text>
              <Text size={'large'} className={s.cardItemValue}>
                {comment}
              </Text>
            </div>
          )}
        </div>
      </div>
      <div className={s.cardWrapper}>
        <Title level={3} className={s.cardTitle}>
          Исследования
        </Title>
        {laboratories?.map((item) => checkOutProductCardView(item))}
      </div>
      <div className={s.cardWrapper}>
        <Title level={3} className={s.cardTitle}>
          Стоимость заказа
        </Title>
        <div className={s.cardContent}>
          <div className={s.cardItem}>
            <Text size={'large'} className={s.cardItemTitle}>
              Исследования
            </Text>
            <div className={s.cardItemValue}>
              {basket?.total && <Text size={'large'}>{formatPrice(basket.total)}</Text>}
            </div>
          </div>
          <div className={s.cardItem}>
            <Text size={'large'} className={s.cardItemTitle}>
              Выезд медсестры
            </Text>
            <Text size={'large'} className={s.cardItemValue}>
              {formatPrice(0)}
            </Text>{' '}
            {/*TODO:*/}
          </div>
          {false && ( // TODO: promoCode
            <div className={s.cardItem}>
              <Text size={'large'} className={s.cardItemTitle}>
                Промокод
              </Text>
              <Text size={'large'} className={s.cardItemValue}>
                -500 ₽
              </Text>
            </div>
          )}
          {forChild && (
            <div className={cn(s.cardItem)}>
              <div className={s.cardItemTitle}>
                <Text size={'large'}>Выезд для ребенка</Text>
              </div>
              <div className={s.cardItemValue}>
                <Text size={'large'} className={cn(s.cardItemValueLabel)}>
                  Бесплатно
                </Text>
              </div>
            </div>
          )}
        </div>
        <div className={s.cardContent}>
          <div className={s.cardItem}>
            <Text size={'large'} className={s.cardItemTitle}>
              Итого
            </Text>
            <Text size={'large'} className={s.cardItemValue}>
              {basket?.total && <Text>{formatPrice(getTotalPrice() ?? 0)}</Text>}
              {/*//TODO: handle 0 price*/}
            </Text>
          </div>
        </div>
      </div>
      {!loadingPayHome ? (
        <>
          <Button
            type="submit"
            size="large"
            disabled={paymentDisabled}
            fullWidth
            onClick={() => handlePaymentOnClick(true)}
          >
            Онлайн-оплата картой
          </Button>
          <Button
            type="submit"
            size="large"
            className={s.payhomeButton}
            disabled={paymentDisabled}
            fullWidth
            onClick={() => payHome()}
          >
            Оплата после получения услуги
          </Button>
          <Button
            onClick={handlePrevButtonOnClick}
            className={s.prevStepButton}
            theme={'secondary'}
            size="large"
            fullWidth
          >
            Назад
          </Button>
        </>
      ) : (
        <Loader variant="spinner" className={s.loader} />
      )}
      <PaymentModal
        getOrderId={getOrderId}
        isOpen={isOpenPaymentModal}
        onClose={() => handlePaymentOnClick(false)}
        onModalError={() => handleModalError()}
      ></PaymentModal>
    </>
  );
};

export default StepOrderVerification;
