import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useProfile } from '@modules/profile/hooks/useProfile';
import { StepAddressFormValues } from '@modules/checkout/stepAddressFormSchema';
import { StepContactsFormValues } from '@modules/checkout/stepContactsFormSchema';
import { StepPersonalDataFormValues } from '@modules/checkout/stepPersonalDataFormSchema';
import { StepCodeFormValues } from '@modules/checkout/stepCodeFormSchema';
import { StepLabsFormValues } from '@modules/checkout/stepLabsFormSchema';
import StepAddress from '@modules/checkout/components/StepAddress';
import StepLabs from '@modules/checkout/components/StepLabs';
import StepPersonalData from '@modules/checkout/components/StepPersonalData';
import StepOrderVerification from '@modules/checkout/components/StepOrderVerification';

import { LABA_CHOICE, handleSendAnalytics } from '@constants/yandexMetrikaEvents';
import { useBasket } from '@hooks/useBasket';

export type CheckoutWizardValues = Partial<
  StepAddressFormValues & StepContactsFormValues & StepPersonalDataFormValues & StepCodeFormValues & StepLabsFormValues
>;

export interface StepProps {
  defaultValues?: Partial<CheckoutWizardValues>;
  onSubmit: (value?: Partial<CheckoutWizardValues>, isLastStep?: boolean) => void;
}

type Step = {
  id: string;
  label: string;
  shouldSkip: () => boolean;
  Component: () => JSX.Element;
};

type CheckoutWizardContextType = {
  activeStepIndex: number;
  prevStepIndex: number;
  steps: Array<Step>;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  goToStep: (step: number) => void;
  formValues: CheckoutWizardValues;
  handleStepSubmit: (values?: CheckoutWizardValues) => void;
  currentStep: Step;
};

export const CheckoutWizardContext = createContext<CheckoutWizardContextType>({} as CheckoutWizardContextType);

interface Props {
  children: React.ReactNode;
}

export function CheckoutWizardProvider({ children }: Props) {
  const { isUnauthorized } = useProfile();
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [formValues, setFormValues] = useState<CheckoutWizardValues>({});
  const isGuestStarted = useRef(isUnauthorized);
  const { basket } = useBasket();

  const handleStepSubmit = useCallback((values?: CheckoutWizardValues) => {
    if (values) {
      setFormValues((current) => Object.assign({}, current, values));

      handleSendAnalytics(LABA_CHOICE);
    }
  }, []);

  useEffect(() => {
    setActiveStepIndex(0);
  }, [basket]);

  const steps = useMemo(() => {
    let resultSteps = [
      {
        id: 'ADDRESS',
        label: 'Адрес и дата',
        Component: StepAddress,
        shouldSkip: () => false,
        idleEffect: () => {},
      },
      {
        id: 'LABS',
        label: 'Выбор лаборатории',
        Component: StepLabs,
        shouldSkip: () => false,
        idleEffect: () => {},
      },
      {
        id: 'PERSONAL_DATA',
        label: 'Личные данные',
        Component: StepPersonalData,
        shouldSkip: () => false,
        idleEffect: () => {},
      },
      {
        id: 'ORDER_VERIFICATION',
        label: 'Подтверждение и оплата',
        Component: StepOrderVerification,
        shouldSkip: () => false,
        idleEffect: () => {},
      },
    ];

    resultSteps = resultSteps.filter((step) => (step.id === 'CODE_VERIFICATION' ? isGuestStarted.current : true));

    return resultSteps;
  }, [isGuestStarted]);

  const currentStep = useMemo(() => steps[activeStepIndex] || {}, [steps, activeStepIndex]);

  const prevStepIndex = useMemo(() => {
    let prevStepToTrigger = activeStepIndex - 1;

    while (prevStepToTrigger > 0 && steps[prevStepToTrigger].shouldSkip()) {
      prevStepToTrigger--;
    }

    return prevStepToTrigger;
  }, [activeStepIndex, steps]);

  const goToNextStep = useCallback(() => {
    setActiveStepIndex((current) => {
      let nextStepToTrigger = current + 1;

      while (nextStepToTrigger < steps.length - 1 && steps[nextStepToTrigger].shouldSkip()) {
        nextStepToTrigger++;
      }

      return nextStepToTrigger;
    });
  }, [steps]);

  const goToPrevStep = useCallback(() => {
    setActiveStepIndex(prevStepIndex);
  }, [prevStepIndex]);

  const goToStep = useCallback((step: number) => {
    setActiveStepIndex(step);
  }, []);

  const value = useMemo(
    () => ({
      activeStepIndex,
      prevStepIndex,
      steps,
      goToNextStep,
      goToPrevStep,
      goToStep,
      currentStep,
      formValues,
      handleStepSubmit,
    }),
    [
      activeStepIndex,
      prevStepIndex,
      steps,
      goToNextStep,
      goToPrevStep,
      goToStep,
      currentStep,
      formValues,
      handleStepSubmit,
    ]
  );

  return <CheckoutWizardContext.Provider value={value}>{children}</CheckoutWizardContext.Provider>;
}

export function useCheckoutWizard() {
  return useContext(CheckoutWizardContext);
}
