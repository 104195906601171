import { HeartIcon, PointerIcon, ClockIcon, VkIcon, TelegramIcon, WhatsappIcon } from '@components/Icon';

export const advatageArray = [
  {
    id: '0',
    title: '2&nbsp;часа',
    text: 'Приедем в&nbsp;удобное для вас время или в&nbsp;течение двух часов',
    imgLink: '/images/icon-water.svg',
    alt: 'icon-water',
  },
  {
    id: '1',
    title: 'от&nbsp;12&nbsp;часов',
    text: 'Готовность большинства исследований ',
    imgLink: '/images/icon-clock.svg',
    alt: 'icon-clock',
  },
  {
    id: '2',
    title: '50&nbsp;медсестер',
    text: 'Сертифицированные и&nbsp;опытные медсестры в&nbsp;команде ЛабКлик',
    imgLink: '/images/icon-smile.svg',
    alt: 'icon-smile',
  },
];

export const benefitsList = [
  {
    id: 'clock',
    icon: ClockIcon,
    title: 'Быстро',
    text: 'Приедем в течение 2 часов или точно ко времени с 7:00 до 16:00',
  },
  {
    id: 'phone',
    icon: PointerIcon,
    title: 'Удобно',
    text: 'Без звонков и очередей. Без рисков для здоровья. Всё онлайн в несколько кликов',
  },
  {
    id: 'safe',
    icon: HeartIcon,
    title: 'Безопасно',
    text: 'Все медсестры прошли обучение, им можно доверить любого пациента',
  },
];

export const socialsArray = [
  {
    id: '2',
    title: 'telegram',
    href: 'https://t.me/Lab_Click',
    icon: <TelegramIcon />,
  },
  {
    id: '3',
    title: 'whatsapp',
    href: 'https://api.whatsapp.com/send?phone=79265156841',
    icon: <WhatsappIcon />,
  },
  {
    id: '4',
    title: 'vk',
    href: 'https://vk.com/public212055373',
    icon: <VkIcon />,
  },
];

export const labsList = [
  { id: 'gemotest', imageSrc: '/images/gemotestLogo.svg', width: 235, height: 25 },
  { id: 'labQuest', imageSrc: '/images/labQuestLogo.svg', width: 74, height: 74 },
  { id: 'dialab', imageSrc: '/images/dialabLogo.svg', width: 180, height: 58 },
];

export const resultStepsList = [
  {
    id: 'medical-research',
    imageSrc: '/images/first-step.png',
    title: 'Выберите анализы',
    text: 'Быстрый поиск, категории для удобства и понятные описания анализов помогут быстро разобраться и сделать выбор.\nАвторские чекапы дадут объективную картину здоровья.',
  },
  {
    id: 'wait-for-nurse',
    imageSrc: '/images/second-step.png',
    title: 'Дождитесь медсестру',
    text: `Медсестра приедет к назначенному времени.\nАккуратно проведет процедуру взятия биоматериала у вас или у ребенка.\nС соблюдением всех требований передаст биоматериал в лабораторию.`,
  },
  {
    id: 'results',
    imageSrc: '/images/third-step.png',
    title: 'Результаты онлайн',
    text: 'Придет сообщение о готовности.\nВ этот момент результаты будут у вас в личном кабинете и на почте.',
  },
];
