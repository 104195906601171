import Text from '@components/Text';
import s from './InputError.module.scss';

interface Props {
  message: string;
}

const InputError = ({ message }: Props) => (
  <Text className={s.errorText} size="small" fontgraphik>
    {message}
  </Text>
);

export default InputError;
