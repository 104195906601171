import SearchIcon from '@assets/icons/search.svg';
import BasketIcon from '@assets/icons/basket-header.svg';
import BasketLeftIcon from '@assets/icons/basket-left-icon.svg';
import PlusIcon from '@assets/icons/plus.svg';
import CloseIcon from '@assets/icons/close.svg';
import CheckedIcon from '@assets/icons/checked.svg';
import CheckIcon from '@assets/icons/check.svg'; // тоньше
import CheckboxCheckIcon from '@assets/icons/checkbox-check.svg'; // тоньше
import CheckboxCheckBoldIcon from '@assets/icons/checkedBold.svg';
import DropdownArrowIcon from '@assets/icons/dropdown-arrow.svg';
import ProfileIcon from '@assets/icons/profile.svg';
import VkIcon from '@assets/icons/vk.svg';
import FacebookIcon from '@assets/icons/facebook.svg';
import TelegramIcon from '@assets/icons/telegram.svg';
import WhatsappIcon from '@assets/icons/whatsapp.svg';
import InstagramIcon from '@assets/icons/instagram.svg';
import MapMarkIcon from '@assets/icons/map-mark.svg';
import UnionIcon from '@assets/icons/union.svg';
import ArrowRightIcon from '@assets/icons/arrow-right.svg';
import FileIcon from '@assets/icons/file.svg';
import FileDownloadIcon from '@assets/icons/file-download.svg';
import QuestionIcon from '@assets/icons/question.svg';
import CameraIcon from '@assets/icons/camera.svg';
import EmailIcon from '@assets/icons/email.svg';
import ShieldIcon from '@assets/icons/shield.svg';
import DirectionIcon from '@assets/icons/direction.svg';
import EditIcon from '@assets/icons/edit.svg';
import TrashIcon from '@assets/icons/trash.svg';
import ChevronRightIcon from '@assets/icons/chevron-right.svg';
import ChevronDownIcon from '@assets/icons/chevron-down.svg';
import CrossIcon from '@assets/icons/cross.svg';
import CrossCircleIcon from '@assets/icons/cross-circle.svg';
import CalendarIcon from '@assets/icons/calendar.svg';
import GoogleCalendarIcon from '@assets/icons/google-calendar.svg';
import ExitIcon from '@assets/icons/exit.svg';
import LoaderIcon from '@assets/icons/loader.svg';
import ArchiveIcon from '@assets/icons/archive.svg';
import PhoneIcon from '@assets/icons/phone.svg';
import PhoneSolidIcon from '@assets/icons/phone-solid.svg';
import UserIcon from '@assets/icons/user.svg';
import ShoppingCartIcon from '@assets/icons/shoppingCart.svg';
import ClockIcon from '@assets/icons/clock.svg';
import HeartIcon from '@assets/icons/heart.svg';
import PointerIcon from '@assets/icons/pointer.svg';
import HamburgerIcon from '@assets/icons/hamburger.svg';
import ArrowLeftRedesignIcon from '@assets/icons/arrow-left.svg';
import ArrowRightSmallIcon from '@assets/icons/arrow-right-small.svg';
import IconBack from '@assets/icons/icon-back.svg';
import CopyIcon from '@assets/icons/copy-icon.svg';
import DarkClockIcon from '@assets/icons/clock-icon.svg';
import BloodIcon from '@assets/icons/blood-icon.svg';
import LabIcon from '@assets/icons/lab-icon.svg';
import ServiceFastIcon from '@assets/icons/service-fast-icon.svg';
import ServiceConvenientIcon from '@assets/icons/service-convenient-icon.svg';
import ServiceHeartIcon from '@assets/icons/service-heart-icon.svg';
import CartIcon from '@assets/icons/cart-icon.svg';
import ArrowRightRoundRedesignIcon from '@assets/icons/arrow-right-round.svg';
import ClockSmallIcon from '@assets/icons/clockSmall.svg';
import DropIcon from '@assets/icons/drop.svg';
import TestTubeIcon from '@assets/icons/testTube.svg';
import SubProductsIcon from '@assets/icons/subProducts.svg';
import BusketIcon from '@assets/icons/busket.svg';
import UserWhiteIcon from '@assets/icons/userWhite.svg';
import BusketWhiteIcon from '@assets/icons/busketWhite.svg';
import SearchWithBgIcon from '@assets/icons/searchWithBg.svg';
import PhoneHeaderIcon from '@assets/icons/phoneHeader.svg';
import PhoneHeaderWhiteIcon from '@assets/icons/phoneHeaderWhite.svg';
import ProtectIcon from '@assets/icons/protect.svg';
import MailIcon from '@assets/icons/mail.svg';
import NewCrossSmallIcon from '@assets/icons/newCrossSmall.svg';

export {
  CloseIcon,
  CheckedIcon,
  CheckIcon,
  CheckboxCheckIcon,
  CheckboxCheckBoldIcon,
  DropdownArrowIcon,
  SearchIcon,
  BasketIcon,
  BasketLeftIcon,
  PlusIcon,
  ProfileIcon,
  VkIcon,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  InstagramIcon,
  MapMarkIcon,
  UnionIcon,
  ArrowRightIcon,
  FileIcon,
  FileDownloadIcon,
  QuestionIcon,
  CameraIcon,
  EmailIcon,
  ShieldIcon,
  DirectionIcon,
  EditIcon,
  TrashIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  CrossIcon,
  CalendarIcon,
  GoogleCalendarIcon,
  ExitIcon,
  LoaderIcon,
  CrossCircleIcon,
  ArchiveIcon,
  PhoneIcon,
  PhoneSolidIcon,
  UserIcon,
  ShoppingCartIcon,
  BusketIcon,
  ClockIcon,
  HeartIcon,
  PointerIcon,
  HamburgerIcon,
  ArrowLeftRedesignIcon,
  ArrowRightSmallIcon,
  IconBack,
  CopyIcon,
  DarkClockIcon,
  BloodIcon,
  LabIcon,
  ServiceFastIcon,
  ServiceConvenientIcon,
  ServiceHeartIcon,
  CartIcon,
  ArrowRightRoundRedesignIcon,
  ClockSmallIcon,
  DropIcon,
  TestTubeIcon,
  SubProductsIcon,
  UserWhiteIcon,
  BusketWhiteIcon,
  SearchWithBgIcon,
  PhoneHeaderIcon,
  PhoneHeaderWhiteIcon,
  ProtectIcon,
  MailIcon,
  NewCrossSmallIcon,
};
