import cn from 'classnames';

import { LoaderIcon } from '@components/Icon';
import Text from '@components/Text';

import s from './Button.module.scss';

interface TOwnProps {
  size?: 'small' | 'medium' | 'large' | 'large-plus';
  theme?: 'primary' | 'secondary' | 'gray' | 'gray-outline' | 'danger' | 'success' | 'ghost';
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (param: React.MouseEvent<HTMLElement>) => void;
  icon?: React.ReactNode;
  fontgraphik?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
}

const Button: React.FC<TOwnProps & JSX.IntrinsicElements['button']> = ({
  size = 'medium',
  className,
  theme = 'primary',
  children,
  disabled = false,
  onClick,
  type = 'button',
  icon,
  fontgraphik = false,
  fullWidth,
  loading = false,
  ...props
}) => {
  const classes = cn(
    theme === 'ghost' ? s.ghost : s.root,
    s[`size-${size}`],
    s[`theme-${theme}`],
    fullWidth && s.fullWidth,
    loading && s.loading,
    className
  );
  const textSize = size === 'small' ? 'medium' : size;

  return (
    <button type={type} className={classes} onClick={onClick} disabled={disabled} {...props}>
      {loading ? (
        <span className={cn(s.loader, { [s.active]: loading })}>
          <LoaderIcon />
        </span>
      ) : null}

      {icon ? icon : null}
      {typeof children === 'string' ? (
        <Text size={textSize} className={s.text} inline fontgraphik={fontgraphik}>
          {children}
        </Text>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
