import { useCallback } from 'react';

import cn from 'classnames';

import Button from '@components/Button';

import s from './Tabs.module.scss';

type Props = {
  title: string;
  index: number;
  active: boolean;
  setSelectedTab: (index: number) => void;
  isFullWidth?: boolean;
  theme?: 'primary' | 'secondary';
};

const TabTitle: React.FC<Props> = ({ title, setSelectedTab, active, index, isFullWidth, theme = 'primary' }) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <li className={cn(s.item, isFullWidth && s.full)}>
      <Button
        onClick={onClick}
        theme="ghost"
        className={cn(isFullWidth && s.buttonWide, s[`theme-${theme}`], active && s.active)}
        fullWidth={isFullWidth}
      >
        {title}
      </Button>
    </li>
  );
};

export default TabTitle;
