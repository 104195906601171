import { add, getHours, setHours } from 'date-fns';

export const TODAY = new Date();

export const TODAY_RESETED_TIME = setHours(TODAY, 0);

export const TOMMOROW = add(TODAY, {
  days: 1,
});

export const YESTERDAY = add(TODAY, {
  days: -1,
});

export const THIRTY_DAYS = add(TODAY, {
  days: 30,
});

export const DAY_AFTER_TOMORROW = add(TODAY, {
  days: 2,
});

export const timerArrayFiltered = (
  timeData: Array<{
    id: string;
    title: string;
    times: Array<{ id: string; time: string; hours: number; minutes: number }>;
  }>,
  date: Date
) =>
  timeData.map(({ times, id, title }) => {
    const filteredTimes = times.filter((item) => item.hours > getHours(date));
    return {
      id,
      title,
      times: filteredTimes,
    };
  });

export const daysArray = [
  {
    id: '1',
    date: TODAY,
  },
  {
    id: '2',
    date: TOMMOROW,
  },
  {
    id: '3',
    date: DAY_AFTER_TOMORROW,
  },
];
