import { KeySpecifier, FieldMergeFunction } from '@apollo/client/cache/inmemory/policies';
import isEqual from 'lodash.isequal';

export function offsetLimitPagination(
  keyArgs: KeySpecifier | false | undefined = false,
  { ignoreArgs = null }: { ignoreArgs?: { query: any } | null } = {}
) {
  const mergeFunc: FieldMergeFunction = (existing, incoming, { args }) => {
    if (ignoreArgs && isEqual(ignoreArgs, args)) {
      return incoming;
    }

    let mergedData = existing && existing.nodes ? existing.nodes.slice(0) : [];
    const incomingData = incoming && incoming.nodes ? incoming.nodes.slice(0) : [];

    if (args) {
      const { offsetPaging: { offset = 0 } = {} } = args;
      // eslint-disable-next-line id-length
      for (let i = 0; i < incomingData.length; ++i) {
        mergedData[offset + i] = incomingData[i];
      }
    } else {
      mergedData.push.apply(mergedData, incomingData);
    }

    return {
      ...(incomingData.length > 0 ? incoming : existing),
      nodes: mergedData,
    };
  };

  return {
    keyArgs,
    merge: mergeFunc,
  };
}
