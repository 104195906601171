import React from 'react';
import classNames from 'classnames';

import { ArrowLeftRedesignIcon, ArrowRightIcon } from '@components/Icon';
import { Button } from '@components/index';

import styles from './CarouselArrow.module.scss';

interface CarouselArrowProps {
  direction?: 'left' | 'right';
  onClick: () => void;
}

const CarouselArrow: React.FC<CarouselArrowProps> = ({ direction = 'left', onClick }) => {
  const directionIsLeft = direction === 'left';
  const Icon = directionIsLeft ? ArrowLeftRedesignIcon : ArrowRightIcon;
  const className = classNames(styles.root, directionIsLeft ? styles.arrowLeft : styles.arrowRight);

  return (
    <Button theme="secondary" onClick={onClick} className={className}>
      <Icon />
    </Button>
  );
};

export default CarouselArrow;
