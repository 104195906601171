import { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { usePhoneAuthVerify } from '@modules/auth/hooks/useAuth';
import { codeFormSchema, CodeFormValues } from '@modules/auth/codeFormSchema';

import Text from '@components/Text';
import OtpInput from '@components/OtpInput';
import Timer from '@modules/auth/components/Timer';

import { callAll } from '@utils/callAll';

import { VERIFY_CODE_LENGTH } from '@constants/index';

import s from './CodeVerificationForm.module.scss';
import { useGuestProfile } from '@modules/profile/GuestProfileContext';
import { useFillProfileDataFromGuest } from '@modules/profile/hooks/useFillProfileDataFromGuest';

export type OwnProps = {
  phone?: string;
  onSuccess?: () => void;
};

const CodeVerificationForm: React.FunctionComponent<OwnProps> = ({ phone = '', onSuccess = () => null }) => {
  const [authVerifyMutation, { loading, error, reset }] = usePhoneAuthVerify();
  const [fillProfileDataFromGuestMutation] = useFillProfileDataFromGuest();
  const { address, profile, products, onClearData } = useGuestProfile();
  const [load, setLoad] = useState(false);
  const { handleSubmit, control, watch } = useForm<CodeFormValues>({
    resolver: yupResolver(codeFormSchema),
  });

  const code = watch('code');
  // const isEmpty = (obj: Object) => Object.keys(obj).length === 0;
  const onSubmit = useCallback(
    async (data: CodeFormValues) => {
      try {
        if (!load) {
          setLoad(true);
          await authVerifyMutation({
            variables: { code: data.code, phone: phone },
            onCompleted: onSuccess,
            onError: () => setLoad(false),
          });
        }
      } catch (er) {
        // eslint-disable-next-line no-console
        console.log('error', er);
      }
    },
    [authVerifyMutation, onSuccess, profile, onClearData, fillProfileDataFromGuestMutation, address, products, phone]
  );

  useEffect(() => {
    if (code?.length === VERIFY_CODE_LENGTH) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, onSubmit, code]);

  const handleResetError = () => {
    if (code?.length === VERIFY_CODE_LENGTH) {
      reset();
    }
  };

  return (
    <>
      <form>
        <div className={s.formItem}>
          <Controller
            control={control}
            name="code"
            render={({ field: { value, onChange } }) => (
              <OtpInput
                value={value}
                onChange={callAll(onChange, handleResetError)}
                isDisabled={loading}
                hasErrored={!!error}
                isInputNum
              />
            )}
          />

          {error ? (
            <Text className={s.errorText} size="small" fontgraphik>
              Неверный код. Повторите
            </Text>
          ) : null}
        </div>

        <Timer phone={phone} />
      </form>
    </>
  );
};

export default CodeVerificationForm;
