import { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';

import { YMInitializer } from 'react-yandex-metrika';
import { TrackingHeadScript } from '@phntms/next-gtm';

import { useApollo } from '@lib/apolloClient';
import { AppPropsWithLayout } from '@customTypes/page';
import { GuestProfileProvider } from '@modules/profile/GuestProfileContext';
import { TOAST_SHOW_TIME } from '@constants/index';
import { CheckoutWizardProvider } from '@modules/checkout/CheckoutWizardContext';
import AuthModal from '@modules/auth/components/AuthModal/AuthModal';
import { AuthModalContextProvider } from '@modules/auth/AuthContext';

import { HistoryManagerProvider, useHistoryManager } from '@hooks/useHistoryManager';

import 'react-toastify/dist/ReactToastify.css';
import '../styles/reset.css';
import '../styles/globals.scss';

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps);
  const getLayout = Component.getLayout || ((page: ReactNode) => page);
  const historyManager = useHistoryManager();

  return (
    <ApolloProvider client={apolloClient}>
      <TrackingHeadScript id={'GTM-TD7JB63'} />

      <YMInitializer accounts={[88151531]} options={{ webvisor: true }} version="2" />

      {/* TODO SZ: any options to escape wrapping the whole app with AuthModalContextProvider? */}
      <AuthModalContextProvider>
        <GuestProfileProvider>
          <HistoryManagerProvider value={historyManager}>
            <CheckoutWizardProvider>{getLayout(<Component {...pageProps} />)}</CheckoutWizardProvider>
          </HistoryManagerProvider>

          <AuthModal />
        </GuestProfileProvider>
      </AuthModalContextProvider>

      <ToastContainer
        position="top-center"
        autoClose={TOAST_SHOW_TIME}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ApolloProvider>
  );
}

export default MyApp;
