import { createContext, useContext } from 'react';

import { useGuest } from '@modules/profile/hooks/useGuest';

type GuestProfileContextType = ReturnType<typeof useGuest>;

export const GuestProfileContext = createContext<GuestProfileContextType>({} as GuestProfileContextType);

interface Props {
  children: React.ReactNode;
}

export function GuestProfileProvider({ children }: Props) {
  const guest = useGuest();

  return <GuestProfileContext.Provider value={guest}>{children}</GuestProfileContext.Provider>;
}

export function useGuestProfile() {
  return useContext(GuestProfileContext);
}
