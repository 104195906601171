import React from 'react';

import cn from 'classnames';

import { CONTACTS_PHONE } from '@constants/index';
import Text from '@components/Text';

import s from './PhoneNumber.module.scss';

interface Props {
  className?: string;
}

const PhoneNumber: React.FC<Props> = ({ className }) => {
  const classes = cn(s.root, className);

  return (
    <div className={s.wrapper}>
      <a className={classes} href={`tel:${CONTACTS_PHONE}`}>
        <Text inline className={s.text} size={'large'} fontgraphik>
          {CONTACTS_PHONE}
        </Text>
      </a>
    </div>
  );
};

export default PhoneNumber;
