import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserFields on User {
    id
    username
    birthday
    gender
    email
    emailIsConfirmed
    avatar
    phone
    phoneIsVerified
    nameFirst
    nameLast
    nameMiddle
    passport {
      id
      passportNumber
    }
  }
`;
