import { useState } from 'react';
import { add, setHours, setMinutes } from 'date-fns';
import classNames from 'classnames';

import Text from '@components/Text';
import { Button, Tabs, Tab } from '@components/index';
import DatePicker from '@components/DatePicker';
import Loader from '@components/Loader';

import { TODAY, TODAY_RESETED_TIME } from './constants';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import TimeButtons from './TimeButtons/TimeButtons';
import s from './DateChooserContent.module.scss';

interface Props {
  className?: string;
  selectedSlotId?: string;
  selectedDate?: Date;
  onGetSlots?: (date: Date) => void;
  onSubmit: (date: Date, time: string) => void;
  isLoadingSlots?: boolean;
  // TODO SZ: create interface
  slots: {
    __typename?: 'slots' | undefined;
    id: string;
    date: any;
    label: string;
    type: string;
    slotDayId: string;
    isActive: boolean;
    isValid: boolean;
  }[];
  submitOnChange?: boolean;
}

const DateChooserContent: React.FC<Props> = ({
  className,
  slots,
  isLoadingSlots,
  selectedDate,
  selectedSlotId = '',
  onGetSlots,
  onSubmit,
  submitOnChange,
}) => {
  const [dateValue, setDateValue] = useState<Date>(selectedDate || TODAY_RESETED_TIME);
  const [timeValue, setTimeValue] = useState<string>(selectedSlotId);

  const handleSetDate = (date: Date) => {
    if (!date) {
      return;
    }

    const resetedTimeDate = setHours(new Date(date), 0);
    setDateValue(resetedTimeDate);
    onGetSlots?.(resetedTimeDate);
    setTimeValue('');
  };

  const handleAddTime = (hours: number, minutes: number, slotIdParam: string) => {
    const changedHourDate = dateValue && setHours(dateValue, hours);

    const changedMinutesDate = changedHourDate ? setMinutes(changedHourDate, !!minutes ? minutes : 0) : null;

    if (!!changedMinutesDate) {
      setDateValue(changedMinutesDate);
      setTimeValue(slotIdParam);

      if (submitOnChange) {
        handleSubmit(changedMinutesDate, slotIdParam);
      }
    }
  };

  const handleSubmit = (date = dateValue, time = timeValue) => {
    onSubmit(date, time);
  };

  return (
    <div className={classNames(s.root, className)}>
      <div className={s.content}>
        <DatePicker
          selected={dateValue}
          // @ts-ignore
          minDate={TODAY}
          maxDate={add(TODAY, { months: 1 })}
          onChange={handleSetDate}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className={s.calendarHeaderWrapper}>
              <CalendarHeader
                date={date}
                decrementIsDisabled={prevMonthButtonDisabled}
                incrementIsDisabled={nextMonthButtonDisabled}
                onDecrementMonth={decreaseMonth}
                onIncrementMonth={increaseMonth}
              />
            </div>
          )}
          calendarClassName={s.calendarBody}
          inline
        />

        {isLoadingSlots ? (
          <Loader className={s.loader} />
        ) : (
          <Tabs isFullWidth className={s.timeSlots}>
            <Tab title="УТРО">
              <TimeButtons slots={slots} handleClick={handleAddTime} timeChoosedId={timeValue} type="MORNING" />
            </Tab>
            <Tab title="ДЕНЬ">
              <TimeButtons slots={slots} handleClick={handleAddTime} timeChoosedId={timeValue} type="DAY" />
            </Tab>
          </Tabs>
        )}

        {!submitOnChange && (
          <Button
            theme="primary"
            className={s.chooseDateButton}
            loading={isLoadingSlots}
            disabled={!dateValue || !timeValue}
            onClick={() => handleSubmit()}
          >
            <Text inline>Выбрать</Text>
          </Button>
        )}
      </div>
    </div>
  );
};

export default DateChooserContent;
