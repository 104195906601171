import { ReactNode } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import Text from '@components/Text';

import styles from './Quote.module.scss';

interface QuoteProps {
  // author: { name: string; post: string; avatarSrc: string };
  author: any;
  className?: string;
  children: ReactNode;
  size?: string; // TODO: change on more concrete strings
}

const Quote: React.FC<QuoteProps> = ({ author, children, className }) => (
  <div className={classNames(styles.root, className)}>
    <div className={styles.quotesWrapper}>
      <Image alt="quotes" src={'/images/quotes.svg'} width={66} height={53} layout="fill" />
    </div>
    <div className={styles.textWrapper}>
      <blockquote>{children}</blockquote>
      <figcaption className={styles.author}>
        <Image
          className={styles.authorAvatar}
          alt="avatar"
          src={author.avatarSrc}
          width={86}
          height={86}
          layout="fixed"
        />
        <div className={styles.authorDescription}>
          <Text className={styles.authorName} size={'large-plus'}>
            {author.name}
          </Text>
          <Text className={styles.authorPost} size={'large'} muted>
            {author.post}
          </Text>
        </div>
      </figcaption>
    </div>
  </div>
);

export default Quote;
