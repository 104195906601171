import * as yup from 'yup';

import { Gender } from '@graphql/generated';

import { differenceInYears } from 'date-fns';

import { TODAY } from '@modules/checkout/components/DateChooser/constants';
import { USER_NAME_ERROR, USER_NAME_REGEX, USER_SURNAME_REGEX } from '@constants/validation';
import { isValidPhone } from '@utils/isValidPhone';

const ALLOW_FIFTEEN_DIGITS_PHONE = false;

export const stepPersonalDataFormSchema = yup
  .object({
    nameLast: yup.string().trim().matches(USER_SURNAME_REGEX, USER_NAME_ERROR).required('Обязательное поле'),
    nameFirst: yup.string().trim().matches(USER_NAME_REGEX, USER_NAME_ERROR).required('Обязательное поле'),
    nameMiddle: yup.string().trim().matches(USER_NAME_REGEX, USER_NAME_ERROR).required('Обязательное поле'),
    birthday: yup
      .date()
      .test('birthday', 'Введите правильную дату', function (value) {
        if (value) {
          const isValidBirthdayNotFeature = differenceInYears(TODAY, value) > 0;
          const isValidBirthdayOld = differenceInYears(TODAY, value) < 120;
          return isValidBirthdayNotFeature && isValidBirthdayOld;
        }
        return false;
      })
      .required(),
    gender: yup.mixed<Gender>().required('Обязательное поле'),
    passportNumber: yup.string().required('Обязательное поле'),
    phone: yup
      .string()
      .trim()
      .test('is-phone', 'Обязательное поле', (value) => isValidPhone(value, ALLOW_FIFTEEN_DIGITS_PHONE))
      .required('Обязательное поле'),
    email: yup.string().trim().email().required('Обязательное поле').typeError('Обязательное поле'),
    agreeOffer: yup.bool().test('is-true', '', Boolean),
    agreePersonalData: yup.bool().test('is-true', '', Boolean),
  })
  .required();

export type StepPersonalDataFormValues = yup.InferType<typeof stepPersonalDataFormSchema>;
