import React, { useCallback, useContext, useState } from 'react';

const initialContext = {
  authModalIsOpen: false,
  onModalOpen: () => {},
  onModalClose: () => {},
};

const AuthModalContext = React.createContext(initialContext);

export const AuthModalContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authModalIsOpen, setAuthModalIsOpen] = useState(false);

  const handleOnOpen = useCallback(() => {
    setAuthModalIsOpen(true);
  }, []);

  const handleOnClose = useCallback(() => {
    setAuthModalIsOpen(false);
  }, []);

  const value = {
    authModalIsOpen,
    onModalOpen: handleOnOpen,
    onModalClose: handleOnClose,
  };

  return <AuthModalContext.Provider value={value}>{children}</AuthModalContext.Provider>;
};

export function useAuthModal() {
  return useContext(AuthModalContext);
}
