import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const ERROR_MESSAGES = {
  ADDRESS: 'Неточный адрес, требуется уточнение',
  STREET: 'Неполный адрес, требуется уточнение',
  NOT_FOUND: 'Адрес не найден',
};

const ERROR_HINTS = {
  HOUSE: 'Уточните номер дома',
  ADDRESS: 'Уточните адрес',
};

export type Suggestion = {
  displayName: string;
  hl: Array<any>;
  type: string | 'geo';
  value: string;
};

const DEBOUNCE_MS = 500;

export function useAddressAutocomplete() {
  const [suggestions, setSuggestions] = useState<Array<Suggestion>>([]);

  const fetchSuggestions = useDebouncedCallback(async (searchValue: string | undefined) => {
    try {
      if (searchValue && ymaps) {
        let newSuggestions = await ymaps.suggest('Москва, ' + searchValue, {
          results: 10,
        });

        newSuggestions = newSuggestions.map((suggest) => ({
          ...suggest,
          displayName: suggest.displayName.replace(', Москва, Россия', ''),
        }));

        // console.log('newSuggestions', newSuggestions);

        setSuggestions(newSuggestions);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  }, DEBOUNCE_MS);

  const validateAddress = async (suggestionValue: string | undefined) => {
    try {
      if (!suggestionValue || !ymaps) {
        return { result: false };
      }

      const res = await ymaps.geocode(suggestionValue);

      const obj = res.geoObjects.get(0);

      let error, hint;

      if (obj) {
        switch (obj.properties.get('metaDataProperty.GeocoderMetaData.precision')) {
          case 'exact':
            break;
          case 'number':
          case 'near':
          case 'range':
            error = ERROR_MESSAGES.ADDRESS;
            hint = ERROR_HINTS.HOUSE;
            break;
          case 'street':
            error = ERROR_MESSAGES.STREET;
            hint = ERROR_HINTS.HOUSE;
            break;
          case 'other':
          default:
            error = ERROR_MESSAGES.ADDRESS;
            hint = ERROR_HINTS.ADDRESS;
        }
      } else {
        error = ERROR_MESSAGES.NOT_FOUND;
        hint = ERROR_HINTS.ADDRESS;
      }

      if (error) {
        return { result: false, error, hint };
      } else {
        return { result: true };
      }
    } catch (error) {
      return { result: false, error };
    }
  };

  const getCoordinates = async (suggestionValue: string | undefined) => {
    try {
      if (!suggestionValue || !ymaps) {
        return { coordinates: null };
      }

      const res = await ymaps.geocode(suggestionValue);

      const obj = res.geoObjects.get(0);

      return {
        // eslint-disable-next-line no-underscore-dangle
        coordinates: obj?.geometry._coordinates || null,
      };
    } catch (error) {
      return { coordinates: null };
    }
  };

  return { suggestions, fetchSuggestions, validateAddress, getCoordinates };
}
