// THIS IS A GENERATED FILE, use `yarn codegen` to regenerate
/* tslint:disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AddAddressInput = {
  address: Scalars['String'];
  apartment?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  entrance?: InputMaybe<Scalars['Int']>;
  floor?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AddOrderInput = {
  address: Scalars['String'];
  apartment?: InputMaybe<Scalars['Int']>;
  birthday: Scalars['DateTime'];
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  email: Scalars['String'];
  entrance?: InputMaybe<Scalars['Int']>;
  floor?: InputMaybe<Scalars['Int']>;
  forChild?: InputMaybe<Scalars['Boolean']>;
  gender: Gender;
  laboratory?: InputMaybe<LaboratoryEnum>;
  nameFirst: Scalars['String'];
  nameLast: Scalars['String'];
  nameMiddle: Scalars['String'];
  partner?: InputMaybe<Scalars['String']>;
  passportNumber: Scalars['String'];
  phone: Scalars['String'];
  products: Array<OrderProductInput>;
  slotId: Scalars['ID'];
};

export type Address = {
  __typename?: 'Address';
  address: Scalars['String'];
  apartment?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deleted: Scalars['DateTime'];
  entrance?: Maybe<Scalars['Int']>;
  floor?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String'];
  phone: Scalars['String'];
};

export type Basket = {
  __typename?: 'Basket';
  count?: Maybe<Scalars['ID']>;
  created: Scalars['DateTime'];
  deleted: Scalars['DateTime'];
  id: Scalars['ID'];
  product: Product;
  productId: Scalars['ID'];
  updated: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export type BasketDataInput = {
  __typename?: 'BasketDataInput';
  nurseVisit: Scalars['Float'];
  products: Array<Basket>;
  sum: Scalars['Float'];
  total: Scalars['Float'];
};

export type BasketItemInput = {
  __typename?: 'BasketItemInput';
  productId: Scalars['ID'];
};

export type BasketProductInput = {
  productId: Scalars['ID'];
};

export type Cards = {
  __typename?: 'Cards';
  card_type: Scalars['String'];
  expiry_month: Scalars['String'];
  expiry_year: Scalars['String'];
  first6: Scalars['String'];
  id: Scalars['ID'];
  issuer_country: Scalars['String'];
  last4: Scalars['String'];
  userId: Scalars['ID'];
};

export type ConfirmEmailInput = {
  token: Scalars['String'];
};

export enum DeadlineUnit {
  Day = 'DAY',
  Hour = 'HOUR',
}

export type EditAddressInput = {
  address?: InputMaybe<Scalars['String']>;
  apartment?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  entrance?: InputMaybe<Scalars['Int']>;
  floor?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EditProfileInput = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  content?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  nameFirst?: InputMaybe<Scalars['String']>;
  nameLast?: InputMaybe<Scalars['String']>;
  nameMiddle?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type FillBasketDataInput = {
  address?: InputMaybe<AddAddressInput>;
  passport?: InputMaybe<PassportInput>;
  products?: InputMaybe<Array<BasketProductInput>>;
  profile?: InputMaybe<EditProfileInput>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
}

export type Label = {
  __typename?: 'Label';
  category?: Maybe<Label>;
  categoryId?: Maybe<Scalars['Float']>;
  created: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  forMainPage: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<Image>;
  isBanner: Scalars['Boolean'];
  parentLabels?: Maybe<Array<Label>>;
  position?: Maybe<Scalars['Float']>;
  products?: Maybe<Array<Product>>;
  productsCount?: Maybe<Scalars['Float']>;
  productsMinPrice?: Maybe<Scalars['Float']>;
  subCategory?: Maybe<Array<Label>>;
  sublabels?: Maybe<Array<Label>>;
  textColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated: Scalars['DateTime'];
};

export enum LaboratoryEnum {
  Dialab = 'DIALAB',
  Gemotest = 'GEMOTEST',
  Labquest = 'LABQUEST',
}

export type Mutation = {
  __typename?: 'Mutation';
  addAddress: Address;
  addOrder: Order;
  addProductsToBasket: BasketDataInput;
  auth: Scalars['Boolean'];
  checkEmailIsEmpty: Scalars['Boolean'];
  confirmEmail: Scalars['Boolean'];
  editAddress: Address;
  editEmail: User;
  editMyPrimaryAddress: Address;
  editOrderSlot: Order;
  editPassport: Passport;
  editProfile: User;
  emptyBasket: BasketDataInput;
  fillBasketData: BasketDataInput;
  payHome: Order;
  primaryAddress: Address;
  removeAddress: Array<Address>;
  removeMyPrimaryAddress: Address;
  removeProductFromBasket: BasketDataInput;
  removeTestUsers: Scalars['Boolean'];
  resendConfirmationLink: Scalars['Boolean'];
  sendEmailConfirmToken: Scalars['Boolean'];
  verify: AuthPayload;
};

export type MutationAddAddressArgs = {
  input: AddAddressInput;
};

export type MutationAddOrderArgs = {
  input: AddOrderInput;
};

export type MutationAddProductsToBasketArgs = {
  products: Array<BasketProductInput>;
};

export type MutationAuthArgs = {
  phone: Scalars['String'];
};

export type MutationCheckEmailIsEmptyArgs = {
  email: Scalars['String'];
};

export type MutationConfirmEmailArgs = {
  confirmationData: ConfirmEmailInput;
};

export type MutationEditAddressArgs = {
  addressId: Scalars['ID'];
  input: EditAddressInput;
};

export type MutationEditEmailArgs = {
  email: Scalars['String'];
};

export type MutationEditMyPrimaryAddressArgs = {
  addressId: Scalars['ID'];
};

export type MutationEditOrderSlotArgs = {
  orderId: Scalars['String'];
  slotId: Scalars['String'];
};

export type MutationEditPassportArgs = {
  input: PassportInput;
};

export type MutationEditProfileArgs = {
  input: EditProfileInput;
};

export type MutationFillBasketDataArgs = {
  input: FillBasketDataInput;
};

export type MutationPayHomeArgs = {
  input: PayHomeInput;
};

export type MutationRemoveAddressArgs = {
  addressId: Scalars['ID'];
};

export type MutationRemoveProductFromBasketArgs = {
  productId: Scalars['ID'];
};

export type MutationRemoveTestUsersArgs = {
  password: Scalars['String'];
};

export type MutationVerifyArgs = {
  code: Scalars['String'];
  phone: Scalars['String'];
};

export type OffsetPageInfoType = {
  __typename?: 'OffsetPageInfoType';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export type OffsetPaging = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Order = {
  __typename?: 'Order';
  address: Scalars['String'];
  apartment?: Maybe<Scalars['Int']>;
  birthday: Scalars['String'];
  code: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  date: Scalars['String'];
  email: Scalars['String'];
  entrance?: Maybe<Scalars['Int']>;
  floor?: Maybe<Scalars['Int']>;
  forChild: Scalars['Boolean'];
  gender: Gender;
  home_pay: Scalars['Boolean'];
  id: Scalars['ID'];
  nameFirst: Scalars['String'];
  nameLast: Scalars['String'];
  nameMiddle: Scalars['String'];
  nurseId?: Maybe<Scalars['Int']>;
  orderItems?: Maybe<Array<OrderItem>>;
  paid: Scalars['Boolean'];
  passportNumber: Scalars['String'];
  payment?: Maybe<Payment>;
  phone: Scalars['String'];
  referrals?: Maybe<Array<Referral>>;
  result: Scalars['Boolean'];
  results?: Maybe<Array<Result>>;
  slot: Slots;
  slotId?: Maybe<Scalars['ID']>;
  sum: Scalars['Int'];
  type: Scalars['String'];
  updated: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  labId?: Maybe<Scalars['Int']>;
  laboratory?: Maybe<Laboratory>;
  order: Order;
  orderId: Scalars['ID'];
  price: Scalars['Int'];
  product: Product;
  productId: Scalars['ID'];
  refId?: Maybe<Scalars['Int']>;
  referral: Referral;
};

export type OrderItemInput = {
  __typename?: 'OrderItemInput';
  laboratory?: Maybe<LaboratoryEnum>;
  productId: Scalars['ID'];
};

export type OrderProductInput = {
  laboratory?: InputMaybe<LaboratoryEnum>;
  productId: Scalars['ID'];
};

export type PartnerBasket = {
  __typename?: 'PartnerBasket';
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  partnerOrder?: Maybe<Array<PartnerOrder>>;
  partnerOrderId: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
};

export type PartnerOrder = {
  __typename?: 'PartnerOrder';
  deletedAt?: Maybe<Scalars['String']>;
  doneDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  partnerBasket?: Maybe<Array<PartnerBasket>>;
  partnerId?: Maybe<Scalars['ID']>;
  partnerUserId?: Maybe<Scalars['ID']>;
  percent?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  url: Scalars['String'];
  userId: Scalars['ID'];
};

export type Passport = {
  __typename?: 'Passport';
  dmcCompanyName?: Maybe<Scalars['String']>;
  dms?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  oms?: Maybe<Scalars['String']>;
  passportNumber: Scalars['String'];
  snils?: Maybe<Scalars['String']>;
};

export type PassportInput = {
  passportNumber?: InputMaybe<Scalars['String']>;
};

export type PayHomeInput = {
  orderId: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['String'];
  card?: Maybe<Cards>;
  cardId?: Maybe<Scalars['ID']>;
  confirmationToken: Scalars['String'];
  confirmationType: Scalars['String'];
  currency: Scalars['String'];
  external: Scalars['Boolean'];
  id: Scalars['ID'];
  order?: Maybe<Order>;
  orderId: Scalars['ID'];
  paid: Scalars['Boolean'];
  paymentId: Scalars['String'];
  paymentMethodTitle?: Maybe<Scalars['String']>;
  recipientAccountId: Scalars['String'];
  recipientGatewayId: Scalars['String'];
  refundId?: Maybe<Scalars['String']>;
  refundable: Scalars['Boolean'];
  status: Scalars['String'];
  test: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type Product = {
  __typename?: 'Product';
  befTime?: Maybe<Scalars['Float']>;
  biomaterial?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contraindications?: Maybe<Scalars['String']>;
  deadline: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  descriptionShort?: Maybe<Scalars['String']>;
  dialabAnalysis?: Maybe<DialabAnalysis>;
  dialabCode?: Maybe<Scalars['String']>;
  dialabTitle?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  gemotestAnalysis?: Maybe<GemotestAnalysis>;
  gemotestCode?: Maybe<Scalars['String']>;
  gemotestTitle?: Maybe<Scalars['String']>;
  gemotestUrl?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  interpretation?: Maybe<Scalars['String']>;
  isPackage: Scalars['Boolean'];
  labels?: Maybe<Array<Label>>;
  labquestAnalysis?: Maybe<LabquestAnalysis>;
  labquestCode?: Maybe<Scalars['String']>;
  labquestTitle?: Maybe<Scalars['String']>;
  preparation?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  productContainers?: Maybe<Array<ProductContainer>>;
  subProducts?: Maybe<Array<Product>>;
  title: Scalars['String'];
  unavailable: Scalars['Boolean'];
};

export type ProductsConnectionType = {
  __typename?: 'ProductsConnectionType';
  nodes: Array<Product>;
  pageInfo: OffsetPageInfoType;
  totalCount: Scalars['Float'];
};

export type PromoBanner = {
  __typename?: 'PromoBanner';
  active?: Maybe<Scalars['Boolean']>;
  blank?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  images?: Maybe<Array<Image>>;
  link?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  findProductByDescription: Array<Product>;
  findProductByTitle: Array<Product>;
  findProducts: ProductsConnectionType;
  getLabelById: Label;
  getLabels: Array<Label>;
  getMyBasket: BasketDataInput;
  getMyOrders: Array<Order>;
  getMyResults: Array<Result>;
  getOrderById: Order;
  getPartnerBasket: Array<PartnerBasket>;
  getResultById: Result;
  getServiceByKey: Services;
  getSlots: SoltsConnectionType;
  getSlotsByDate: Slots;
  getSlotsByDays: Array<Slots>;
  getSlotsById: Slots;
  getSubCategoryLabels?: Maybe<Label>;
  myAddresses: Array<Address>;
  myProfile: User;
  product: Product;
  products: ProductsConnectionType;
  productsByIds: Array<Product>;
  promoBanners: Array<PromoBanner>;
  questionAnswers: Array<QuestionAnswer>;
  staticBlock: StaticBlock;
  staticBlocks: Array<StaticBlock>;
  user: User;
  userFieldsSuggestions: Array<Scalars['String']>;
};

export type QueryFindProductByDescriptionArgs = {
  description: Scalars['String'];
  offsetPaging?: InputMaybe<OffsetPaging>;
};

export type QueryFindProductByTitleArgs = {
  offsetPaging?: InputMaybe<OffsetPaging>;
  title: Scalars['String'];
};

export type QueryFindProductsArgs = {
  labels?: InputMaybe<Array<Scalars['ID']>>;
  offsetPaging?: InputMaybe<OffsetPaging>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryGetLabelByIdArgs = {
  labelId: Scalars['ID'];
};

export type QueryGetLabelsArgs = {
  forMainPage?: InputMaybe<Scalars['Boolean']>;
  isBanner?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetOrderByIdArgs = {
  orderId: Scalars['ID'];
};

export type QueryGetPartnerBasketArgs = {
  partnerOrderId: Scalars['ID'];
};

export type QueryGetResultByIdArgs = {
  resultId: Scalars['ID'];
};

export type QueryGetServiceByKeyArgs = {
  key: Scalars['String'];
};

export type QueryGetSlotsArgs = {
  date?: InputMaybe<Scalars['DateTime']>;
  days?: InputMaybe<Scalars['Int']>;
  slotsOffsetPaging?: InputMaybe<OffsetPaging>;
};

export type QueryGetSlotsByDateArgs = {
  date: Scalars['DateTime'];
};

export type QueryGetSlotsByDaysArgs = {
  date: Scalars['DateTime'];
  days?: InputMaybe<Scalars['Int']>;
};

export type QueryGetSlotsByIdArgs = {
  slotsId: Scalars['ID'];
};

export type QueryGetSubCategoryLabelsArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductsArgs = {
  offsetPaging?: InputMaybe<OffsetPaging>;
};

export type QueryProductsByIdsArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryStaticBlockArgs = {
  code: Scalars['String'];
};

export type QueryUserArgs = {
  id: Scalars['String'];
};

export type QueryUserFieldsSuggestionsArgs = {
  parts?: InputMaybe<Array<Scalars['String']>>;
  query?: InputMaybe<Scalars['String']>;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  answer?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  question: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum ReferralStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Warning = 'WARNING',
}

export type Services = {
  __typename?: 'Services';
  id: Scalars['ID'];
  key: Scalars['String'];
  title: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type SoltsConnectionType = {
  __typename?: 'SoltsConnectionType';
  nodes: Array<Slots>;
  pageInfo: OffsetPageInfoType;
  totalCount: Scalars['Float'];
};

export type StaticBlock = {
  __typename?: 'StaticBlock';
  code?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailIsConfirmed: Scalars['Boolean'];
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  lastLogin: Scalars['DateTime'];
  nameFirst?: Maybe<Scalars['String']>;
  nameLast?: Maybe<Scalars['String']>;
  nameMiddle?: Maybe<Scalars['String']>;
  passport?: Maybe<Passport>;
  passportId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['String']>;
  phoneIsVerified: Scalars['Boolean'];
  updated: Scalars['DateTime'];
  username?: Maybe<Scalars['String']>;
};

export type Container = {
  __typename?: 'container';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  labId: Scalars['String'];
  laboratory: Scalars['String'];
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
};

export type DialabAnalysis = {
  __typename?: 'dialabAnalysis';
  code?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deadline?: Maybe<Scalars['Int']>;
  deadlineUnit?: Maybe<DeadlineUnit>;
  id: Scalars['ID'];
  labId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Float']>;
  unavailable: Scalars['Boolean'];
  updated: Scalars['DateTime'];
};

export type GemotestAnalysis = {
  __typename?: 'gemotestAnalysis';
  code?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deadline?: Maybe<Scalars['Int']>;
  deadlineUnit?: Maybe<DeadlineUnit>;
  id: Scalars['ID'];
  labId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Float']>;
  unavailable: Scalars['Boolean'];
  updated: Scalars['DateTime'];
};

export type Image = {
  __typename?: 'image';
  contentType: Scalars['String'];
  created: Scalars['DateTime'];
  entityId: Scalars['ID'];
  entityType?: Maybe<Scalars['String']>;
  fileId: Scalars['String'];
  height: Scalars['Float'];
  id: Scalars['ID'];
  position: Scalars['Float'];
  size: Scalars['Float'];
  src: Scalars['String'];
  updated: Scalars['DateTime'];
  width: Scalars['Float'];
};

export type Laboratory = {
  __typename?: 'laboratory';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  referrals?: Maybe<Array<Referral>>;
  title?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
};

export type LabquestAnalysis = {
  __typename?: 'labquestAnalysis';
  code?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deadline?: Maybe<Scalars['Int']>;
  deadlineUnit?: Maybe<DeadlineUnit>;
  id: Scalars['ID'];
  labId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Float']>;
  unavailable: Scalars['Boolean'];
  updated: Scalars['DateTime'];
};

export type ProductContainer = {
  __typename?: 'productContainer';
  biomaterialCode?: Maybe<Scalars['String']>;
  containerId: Scalars['Int'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  maxTestsCount?: Maybe<Scalars['String']>;
  preanalyticInfoId?: Maybe<Scalars['String']>;
  productId: Scalars['Int'];
  samplingGroupId?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
};

export type Referral = {
  __typename?: 'referral';
  created: Scalars['DateTime'];
  externalLisId: Scalars['String'];
  externalRefId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  labId?: Maybe<Scalars['Int']>;
  laboratory?: Maybe<Laboratory>;
  orderId: Scalars['String'];
  result: Scalars['Boolean'];
  status: ReferralStatus;
  statusMessage?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
};

export type Result = {
  __typename?: 'result';
  blankGuid?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order: Order;
  orderId: Scalars['ID'];
  referralId?: Maybe<Scalars['ID']>;
  updated: Scalars['DateTime'];
};

export type SlotDay = {
  __typename?: 'slotDay';
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  slots: Slots;
};

export type Slots = {
  __typename?: 'slots';
  capacity: Scalars['Float'];
  comment?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  endActivity: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isValid: Scalars['Boolean'];
  label: Scalars['String'];
  orders: Order;
  slotDay: SlotDay;
  slotDayId: Scalars['ID'];
  startActivity: Scalars['DateTime'];
  type: Scalars['String'];
};

export type AddressFieldsFragment = {
  __typename?: 'Address';
  id: string;
  name?: string | null | undefined;
  country?: string | null | undefined;
  city?: string | null | undefined;
  address: string;
  entrance?: number | null | undefined;
  floor?: number | null | undefined;
  apartment?: number | null | undefined;
  isPrimary: boolean;
};

export type BasketProductsFieldsFragment = {
  __typename?: 'Basket';
  id: string;
  product: {
    __typename?: 'Product';
    id: string;
    title: string;
    description?: string | null | undefined;
    price: number;
    deadline: number;
    isPackage: boolean;
    descriptionShort?: string | null | undefined;
    biomaterial?: string | null | undefined;
    preparation?: string | null | undefined;
    contraindications?: string | null | undefined;
    gemotestCode?: string | null | undefined;
    dialabCode?: string | null | undefined;
    labquestCode?: string | null | undefined;
    code?: string | null | undefined;
    disabled: boolean;
    unavailable: boolean;
    hidden: boolean;
    gemotestAnalysis?:
      | {
          __typename?: 'gemotestAnalysis';
          unavailable: boolean;
          created: any;
          id: string;
          updated: any;
          deadline?: number | null | undefined;
          deadlineUnit?: DeadlineUnit | null | undefined;
        }
      | null
      | undefined;
    dialabAnalysis?:
      | {
          __typename?: 'dialabAnalysis';
          unavailable: boolean;
          created: any;
          id: string;
          updated: any;
          deadline?: number | null | undefined;
          deadlineUnit?: DeadlineUnit | null | undefined;
        }
      | null
      | undefined;
    labquestAnalysis?:
      | {
          __typename?: 'labquestAnalysis';
          unavailable: boolean;
          created: any;
          id: string;
          updated: any;
          deadline?: number | null | undefined;
          deadlineUnit?: DeadlineUnit | null | undefined;
        }
      | null
      | undefined;
    labels?:
      | Array<{
          __typename?: 'Label';
          id: string;
          title: string;
          isBanner: boolean;
          forMainPage: boolean;
          created: any;
          updated: any;
          hidden: boolean;
        }>
      | null
      | undefined;
    subProducts?:
      | Array<{
          __typename?: 'Product';
          id: string;
          title: string;
          description?: string | null | undefined;
          price: number;
          deadline: number;
          isPackage: boolean;
          descriptionShort?: string | null | undefined;
          biomaterial?: string | null | undefined;
          preparation?: string | null | undefined;
          contraindications?: string | null | undefined;
          gemotestCode?: string | null | undefined;
          dialabCode?: string | null | undefined;
          labquestCode?: string | null | undefined;
          code?: string | null | undefined;
          disabled: boolean;
          unavailable: boolean;
          hidden: boolean;
          gemotestAnalysis?:
            | {
                __typename?: 'gemotestAnalysis';
                unavailable: boolean;
                created: any;
                id: string;
                updated: any;
                deadline?: number | null | undefined;
                deadlineUnit?: DeadlineUnit | null | undefined;
              }
            | null
            | undefined;
          dialabAnalysis?:
            | {
                __typename?: 'dialabAnalysis';
                unavailable: boolean;
                created: any;
                id: string;
                updated: any;
                deadline?: number | null | undefined;
                deadlineUnit?: DeadlineUnit | null | undefined;
              }
            | null
            | undefined;
          labquestAnalysis?:
            | {
                __typename?: 'labquestAnalysis';
                unavailable: boolean;
                created: any;
                id: string;
                updated: any;
                deadline?: number | null | undefined;
                deadlineUnit?: DeadlineUnit | null | undefined;
              }
            | null
            | undefined;
          labels?:
            | Array<{
                __typename?: 'Label';
                id: string;
                title: string;
                isBanner: boolean;
                forMainPage: boolean;
                created: any;
                updated: any;
                hidden: boolean;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type ProductFieldsFragment = {
  __typename?: 'Product';
  id: string;
  title: string;
  description?: string | null | undefined;
  price: number;
  deadline: number;
  isPackage: boolean;
  descriptionShort?: string | null | undefined;
  biomaterial?: string | null | undefined;
  preparation?: string | null | undefined;
  contraindications?: string | null | undefined;
  gemotestCode?: string | null | undefined;
  dialabCode?: string | null | undefined;
  labquestCode?: string | null | undefined;
  code?: string | null | undefined;
  disabled: boolean;
  unavailable: boolean;
  hidden: boolean;
  gemotestAnalysis?:
    | {
        __typename?: 'gemotestAnalysis';
        unavailable: boolean;
        created: any;
        id: string;
        updated: any;
        deadline?: number | null | undefined;
        deadlineUnit?: DeadlineUnit | null | undefined;
      }
    | null
    | undefined;
  dialabAnalysis?:
    | {
        __typename?: 'dialabAnalysis';
        unavailable: boolean;
        created: any;
        id: string;
        updated: any;
        deadline?: number | null | undefined;
        deadlineUnit?: DeadlineUnit | null | undefined;
      }
    | null
    | undefined;
  labquestAnalysis?:
    | {
        __typename?: 'labquestAnalysis';
        unavailable: boolean;
        created: any;
        id: string;
        updated: any;
        deadline?: number | null | undefined;
        deadlineUnit?: DeadlineUnit | null | undefined;
      }
    | null
    | undefined;
  labels?:
    | Array<{
        __typename?: 'Label';
        id: string;
        title: string;
        isBanner: boolean;
        forMainPage: boolean;
        created: any;
        updated: any;
        hidden: boolean;
      }>
    | null
    | undefined;
  subProducts?:
    | Array<{
        __typename?: 'Product';
        id: string;
        title: string;
        description?: string | null | undefined;
        price: number;
        deadline: number;
        isPackage: boolean;
        descriptionShort?: string | null | undefined;
        biomaterial?: string | null | undefined;
        preparation?: string | null | undefined;
        contraindications?: string | null | undefined;
        gemotestCode?: string | null | undefined;
        dialabCode?: string | null | undefined;
        labquestCode?: string | null | undefined;
        code?: string | null | undefined;
        disabled: boolean;
        unavailable: boolean;
        hidden: boolean;
        gemotestAnalysis?:
          | {
              __typename?: 'gemotestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        dialabAnalysis?:
          | {
              __typename?: 'dialabAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labquestAnalysis?:
          | {
              __typename?: 'labquestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labels?:
          | Array<{
              __typename?: 'Label';
              id: string;
              title: string;
              isBanner: boolean;
              forMainPage: boolean;
              created: any;
              updated: any;
              hidden: boolean;
            }>
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type UserFieldsFragment = {
  __typename?: 'User';
  id: string;
  username?: string | null | undefined;
  birthday?: string | null | undefined;
  gender?: Gender | null | undefined;
  email?: string | null | undefined;
  emailIsConfirmed: boolean;
  avatar?: string | null | undefined;
  phone?: string | null | undefined;
  phoneIsVerified: boolean;
  nameFirst?: string | null | undefined;
  nameLast?: string | null | undefined;
  nameMiddle?: string | null | undefined;
  passport?: { __typename?: 'Passport'; id: string; passportNumber: string } | null | undefined;
};

export type EditAddressMutationVariables = Exact<{
  input: EditAddressInput;
  addressId: Scalars['ID'];
}>;

export type EditAddressMutation = {
  __typename?: 'Mutation';
  editAddress: {
    __typename?: 'Address';
    id: string;
    name?: string | null | undefined;
    country?: string | null | undefined;
    city?: string | null | undefined;
    address: string;
    entrance?: number | null | undefined;
    floor?: number | null | undefined;
    apartment?: number | null | undefined;
    isPrimary: boolean;
  };
};

export type AddAddressMutationVariables = Exact<{
  input: AddAddressInput;
}>;

export type AddAddressMutation = {
  __typename?: 'Mutation';
  addAddress: {
    __typename?: 'Address';
    id: string;
    name?: string | null | undefined;
    country?: string | null | undefined;
    city?: string | null | undefined;
    address: string;
    entrance?: number | null | undefined;
    floor?: number | null | undefined;
    apartment?: number | null | undefined;
    isPrimary: boolean;
  };
};

export type RemoveAddressMutationVariables = Exact<{
  addressId: Scalars['ID'];
}>;

export type RemoveAddressMutation = {
  __typename?: 'Mutation';
  removeAddress: Array<{
    __typename?: 'Address';
    id: string;
    name?: string | null | undefined;
    country?: string | null | undefined;
    city?: string | null | undefined;
    address: string;
    entrance?: number | null | undefined;
    floor?: number | null | undefined;
    apartment?: number | null | undefined;
    isPrimary: boolean;
  }>;
};

export type AuthMutationVariables = Exact<{
  phone: Scalars['String'];
}>;

export type AuthMutation = { __typename?: 'Mutation'; auth: boolean };

export type AuthVerifyMutationVariables = Exact<{
  code: Scalars['String'];
  phone: Scalars['String'];
}>;

export type AuthVerifyMutation = {
  __typename?: 'Mutation';
  verify: { __typename?: 'AuthPayload'; accessToken: string; phone: string };
};

export type AddProductsToBasketMutationVariables = Exact<{
  products: Array<BasketProductInput> | BasketProductInput;
}>;

export type AddProductsToBasketMutation = {
  __typename?: 'Mutation';
  addProductsToBasket: {
    __typename?: 'BasketDataInput';
    sum: number;
    total: number;
    nurseVisit: number;
    products: Array<{
      __typename?: 'Basket';
      id: string;
      product: {
        __typename?: 'Product';
        id: string;
        title: string;
        description?: string | null | undefined;
        price: number;
        deadline: number;
        isPackage: boolean;
        descriptionShort?: string | null | undefined;
        biomaterial?: string | null | undefined;
        preparation?: string | null | undefined;
        contraindications?: string | null | undefined;
        gemotestCode?: string | null | undefined;
        dialabCode?: string | null | undefined;
        labquestCode?: string | null | undefined;
        code?: string | null | undefined;
        disabled: boolean;
        unavailable: boolean;
        hidden: boolean;
        gemotestAnalysis?:
          | {
              __typename?: 'gemotestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        dialabAnalysis?:
          | {
              __typename?: 'dialabAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labquestAnalysis?:
          | {
              __typename?: 'labquestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labels?:
          | Array<{
              __typename?: 'Label';
              id: string;
              title: string;
              isBanner: boolean;
              forMainPage: boolean;
              created: any;
              updated: any;
              hidden: boolean;
            }>
          | null
          | undefined;
        subProducts?:
          | Array<{
              __typename?: 'Product';
              id: string;
              title: string;
              description?: string | null | undefined;
              price: number;
              deadline: number;
              isPackage: boolean;
              descriptionShort?: string | null | undefined;
              biomaterial?: string | null | undefined;
              preparation?: string | null | undefined;
              contraindications?: string | null | undefined;
              gemotestCode?: string | null | undefined;
              dialabCode?: string | null | undefined;
              labquestCode?: string | null | undefined;
              code?: string | null | undefined;
              disabled: boolean;
              unavailable: boolean;
              hidden: boolean;
              gemotestAnalysis?:
                | {
                    __typename?: 'gemotestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              dialabAnalysis?:
                | {
                    __typename?: 'dialabAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labquestAnalysis?:
                | {
                    __typename?: 'labquestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labels?:
                | Array<{
                    __typename?: 'Label';
                    id: string;
                    title: string;
                    isBanner: boolean;
                    forMainPage: boolean;
                    created: any;
                    updated: any;
                    hidden: boolean;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      };
    }>;
  };
};

export type RemoveProductFromBasketMutationVariables = Exact<{
  productId: Scalars['ID'];
}>;

export type RemoveProductFromBasketMutation = {
  __typename?: 'Mutation';
  removeProductFromBasket: {
    __typename?: 'BasketDataInput';
    sum: number;
    total: number;
    nurseVisit: number;
    products: Array<{
      __typename?: 'Basket';
      id: string;
      product: {
        __typename?: 'Product';
        id: string;
        title: string;
        description?: string | null | undefined;
        price: number;
        deadline: number;
        isPackage: boolean;
        descriptionShort?: string | null | undefined;
        biomaterial?: string | null | undefined;
        preparation?: string | null | undefined;
        contraindications?: string | null | undefined;
        gemotestCode?: string | null | undefined;
        dialabCode?: string | null | undefined;
        labquestCode?: string | null | undefined;
        code?: string | null | undefined;
        disabled: boolean;
        unavailable: boolean;
        hidden: boolean;
        gemotestAnalysis?:
          | {
              __typename?: 'gemotestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        dialabAnalysis?:
          | {
              __typename?: 'dialabAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labquestAnalysis?:
          | {
              __typename?: 'labquestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labels?:
          | Array<{
              __typename?: 'Label';
              id: string;
              title: string;
              isBanner: boolean;
              forMainPage: boolean;
              created: any;
              updated: any;
              hidden: boolean;
            }>
          | null
          | undefined;
        subProducts?:
          | Array<{
              __typename?: 'Product';
              id: string;
              title: string;
              description?: string | null | undefined;
              price: number;
              deadline: number;
              isPackage: boolean;
              descriptionShort?: string | null | undefined;
              biomaterial?: string | null | undefined;
              preparation?: string | null | undefined;
              contraindications?: string | null | undefined;
              gemotestCode?: string | null | undefined;
              dialabCode?: string | null | undefined;
              labquestCode?: string | null | undefined;
              code?: string | null | undefined;
              disabled: boolean;
              unavailable: boolean;
              hidden: boolean;
              gemotestAnalysis?:
                | {
                    __typename?: 'gemotestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              dialabAnalysis?:
                | {
                    __typename?: 'dialabAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labquestAnalysis?:
                | {
                    __typename?: 'labquestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labels?:
                | Array<{
                    __typename?: 'Label';
                    id: string;
                    title: string;
                    isBanner: boolean;
                    forMainPage: boolean;
                    created: any;
                    updated: any;
                    hidden: boolean;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      };
    }>;
  };
};

export type EmptyBasketMutationVariables = Exact<{ [key: string]: never }>;

export type EmptyBasketMutation = {
  __typename?: 'Mutation';
  emptyBasket: {
    __typename?: 'BasketDataInput';
    sum: number;
    total: number;
    nurseVisit: number;
    products: Array<{
      __typename?: 'Basket';
      id: string;
      product: {
        __typename?: 'Product';
        id: string;
        title: string;
        description?: string | null | undefined;
        price: number;
        deadline: number;
        isPackage: boolean;
        descriptionShort?: string | null | undefined;
        biomaterial?: string | null | undefined;
        preparation?: string | null | undefined;
        contraindications?: string | null | undefined;
        gemotestCode?: string | null | undefined;
        dialabCode?: string | null | undefined;
        labquestCode?: string | null | undefined;
        code?: string | null | undefined;
        disabled: boolean;
        unavailable: boolean;
        hidden: boolean;
        gemotestAnalysis?:
          | {
              __typename?: 'gemotestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        dialabAnalysis?:
          | {
              __typename?: 'dialabAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labquestAnalysis?:
          | {
              __typename?: 'labquestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labels?:
          | Array<{
              __typename?: 'Label';
              id: string;
              title: string;
              isBanner: boolean;
              forMainPage: boolean;
              created: any;
              updated: any;
              hidden: boolean;
            }>
          | null
          | undefined;
        subProducts?:
          | Array<{
              __typename?: 'Product';
              id: string;
              title: string;
              description?: string | null | undefined;
              price: number;
              deadline: number;
              isPackage: boolean;
              descriptionShort?: string | null | undefined;
              biomaterial?: string | null | undefined;
              preparation?: string | null | undefined;
              contraindications?: string | null | undefined;
              gemotestCode?: string | null | undefined;
              dialabCode?: string | null | undefined;
              labquestCode?: string | null | undefined;
              code?: string | null | undefined;
              disabled: boolean;
              unavailable: boolean;
              hidden: boolean;
              gemotestAnalysis?:
                | {
                    __typename?: 'gemotestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              dialabAnalysis?:
                | {
                    __typename?: 'dialabAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labquestAnalysis?:
                | {
                    __typename?: 'labquestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labels?:
                | Array<{
                    __typename?: 'Label';
                    id: string;
                    title: string;
                    isBanner: boolean;
                    forMainPage: boolean;
                    created: any;
                    updated: any;
                    hidden: boolean;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      };
    }>;
  };
};

export type PayHomeMutationVariables = Exact<{
  input: PayHomeInput;
}>;

export type PayHomeMutation = {
  __typename?: 'Mutation';
  payHome: {
    __typename?: 'Order';
    id: string;
    userId: string;
    payment?:
      | {
          __typename?: 'Payment';
          paymentId: string;
          confirmationToken: string;
          card?: { __typename?: 'Cards'; first6: string } | null | undefined;
        }
      | null
      | undefined;
    orderItems?:
      | Array<{ __typename?: 'OrderItem'; count?: number | null | undefined; price: number }>
      | null
      | undefined;
  };
};

export type AddOrderMutationVariables = Exact<{
  input: AddOrderInput;
}>;

export type AddOrderMutation = {
  __typename?: 'Mutation';
  addOrder: {
    __typename?: 'Order';
    id: string;
    userId: string;
    payment?:
      | {
          __typename?: 'Payment';
          paymentId: string;
          confirmationToken: string;
          card?: { __typename?: 'Cards'; first6: string } | null | undefined;
        }
      | null
      | undefined;
    orderItems?:
      | Array<{ __typename?: 'OrderItem'; count?: number | null | undefined; price: number }>
      | null
      | undefined;
  };
};

export type EditProfileMutationVariables = Exact<{
  input: EditProfileInput;
}>;

export type EditProfileMutation = {
  __typename?: 'Mutation';
  editProfile: {
    __typename?: 'User';
    id: string;
    username?: string | null | undefined;
    birthday?: string | null | undefined;
    gender?: Gender | null | undefined;
    email?: string | null | undefined;
    emailIsConfirmed: boolean;
    avatar?: string | null | undefined;
    phone?: string | null | undefined;
    phoneIsVerified: boolean;
    nameFirst?: string | null | undefined;
    nameLast?: string | null | undefined;
    nameMiddle?: string | null | undefined;
    passport?: { __typename?: 'Passport'; id: string; passportNumber: string } | null | undefined;
  };
};

export type EditEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type EditEmailMutation = {
  __typename?: 'Mutation';
  editEmail: {
    __typename?: 'User';
    id: string;
    username?: string | null | undefined;
    birthday?: string | null | undefined;
    gender?: Gender | null | undefined;
    email?: string | null | undefined;
    emailIsConfirmed: boolean;
    avatar?: string | null | undefined;
    phone?: string | null | undefined;
    phoneIsVerified: boolean;
    nameFirst?: string | null | undefined;
    nameLast?: string | null | undefined;
    nameMiddle?: string | null | undefined;
    passport?: { __typename?: 'Passport'; id: string; passportNumber: string } | null | undefined;
  };
};

export type EditPassportMutationVariables = Exact<{
  input: PassportInput;
}>;

export type EditPassportMutation = {
  __typename?: 'Mutation';
  editPassport: { __typename?: 'Passport'; id: string; passportNumber: string };
};

export type FillBasketDataMutationVariables = Exact<{
  input: FillBasketDataInput;
}>;

export type FillBasketDataMutation = {
  __typename?: 'Mutation';
  fillBasketData: {
    __typename?: 'BasketDataInput';
    sum: number;
    total: number;
    nurseVisit: number;
    products: Array<{
      __typename?: 'Basket';
      id: string;
      product: {
        __typename?: 'Product';
        id: string;
        title: string;
        description?: string | null | undefined;
        price: number;
        deadline: number;
        isPackage: boolean;
        descriptionShort?: string | null | undefined;
        biomaterial?: string | null | undefined;
        preparation?: string | null | undefined;
        contraindications?: string | null | undefined;
        gemotestCode?: string | null | undefined;
        dialabCode?: string | null | undefined;
        labquestCode?: string | null | undefined;
        code?: string | null | undefined;
        disabled: boolean;
        unavailable: boolean;
        hidden: boolean;
        gemotestAnalysis?:
          | {
              __typename?: 'gemotestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        dialabAnalysis?:
          | {
              __typename?: 'dialabAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labquestAnalysis?:
          | {
              __typename?: 'labquestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labels?:
          | Array<{
              __typename?: 'Label';
              id: string;
              title: string;
              isBanner: boolean;
              forMainPage: boolean;
              created: any;
              updated: any;
              hidden: boolean;
            }>
          | null
          | undefined;
        subProducts?:
          | Array<{
              __typename?: 'Product';
              id: string;
              title: string;
              description?: string | null | undefined;
              price: number;
              deadline: number;
              isPackage: boolean;
              descriptionShort?: string | null | undefined;
              biomaterial?: string | null | undefined;
              preparation?: string | null | undefined;
              contraindications?: string | null | undefined;
              gemotestCode?: string | null | undefined;
              dialabCode?: string | null | undefined;
              labquestCode?: string | null | undefined;
              code?: string | null | undefined;
              disabled: boolean;
              unavailable: boolean;
              hidden: boolean;
              gemotestAnalysis?:
                | {
                    __typename?: 'gemotestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              dialabAnalysis?:
                | {
                    __typename?: 'dialabAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labquestAnalysis?:
                | {
                    __typename?: 'labquestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labels?:
                | Array<{
                    __typename?: 'Label';
                    id: string;
                    title: string;
                    isBanner: boolean;
                    forMainPage: boolean;
                    created: any;
                    updated: any;
                    hidden: boolean;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      };
    }>;
  };
};

export type SendEmailConfirmTokenMutationVariables = Exact<{ [key: string]: never }>;

export type SendEmailConfirmTokenMutation = { __typename?: 'Mutation'; sendEmailConfirmToken: boolean };

export type MyAddressesQueryVariables = Exact<{ [key: string]: never }>;

export type MyAddressesQuery = {
  __typename?: 'Query';
  myAddresses: Array<{
    __typename?: 'Address';
    id: string;
    name?: string | null | undefined;
    country?: string | null | undefined;
    city?: string | null | undefined;
    address: string;
    entrance?: number | null | undefined;
    floor?: number | null | undefined;
    apartment?: number | null | undefined;
    isPrimary: boolean;
  }>;
};

export type GetMyBasketQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyBasketQuery = {
  __typename?: 'Query';
  getMyBasket: {
    __typename?: 'BasketDataInput';
    sum: number;
    total: number;
    nurseVisit: number;
    products: Array<{
      __typename?: 'Basket';
      id: string;
      product: {
        __typename?: 'Product';
        id: string;
        title: string;
        description?: string | null | undefined;
        price: number;
        deadline: number;
        isPackage: boolean;
        descriptionShort?: string | null | undefined;
        biomaterial?: string | null | undefined;
        preparation?: string | null | undefined;
        contraindications?: string | null | undefined;
        gemotestCode?: string | null | undefined;
        dialabCode?: string | null | undefined;
        labquestCode?: string | null | undefined;
        code?: string | null | undefined;
        disabled: boolean;
        unavailable: boolean;
        hidden: boolean;
        gemotestAnalysis?:
          | {
              __typename?: 'gemotestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        dialabAnalysis?:
          | {
              __typename?: 'dialabAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labquestAnalysis?:
          | {
              __typename?: 'labquestAnalysis';
              unavailable: boolean;
              created: any;
              id: string;
              updated: any;
              deadline?: number | null | undefined;
              deadlineUnit?: DeadlineUnit | null | undefined;
            }
          | null
          | undefined;
        labels?:
          | Array<{
              __typename?: 'Label';
              id: string;
              title: string;
              isBanner: boolean;
              forMainPage: boolean;
              created: any;
              updated: any;
              hidden: boolean;
            }>
          | null
          | undefined;
        subProducts?:
          | Array<{
              __typename?: 'Product';
              id: string;
              title: string;
              description?: string | null | undefined;
              price: number;
              deadline: number;
              isPackage: boolean;
              descriptionShort?: string | null | undefined;
              biomaterial?: string | null | undefined;
              preparation?: string | null | undefined;
              contraindications?: string | null | undefined;
              gemotestCode?: string | null | undefined;
              dialabCode?: string | null | undefined;
              labquestCode?: string | null | undefined;
              code?: string | null | undefined;
              disabled: boolean;
              unavailable: boolean;
              hidden: boolean;
              gemotestAnalysis?:
                | {
                    __typename?: 'gemotestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              dialabAnalysis?:
                | {
                    __typename?: 'dialabAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labquestAnalysis?:
                | {
                    __typename?: 'labquestAnalysis';
                    unavailable: boolean;
                    created: any;
                    id: string;
                    updated: any;
                    deadline?: number | null | undefined;
                    deadlineUnit?: DeadlineUnit | null | undefined;
                  }
                | null
                | undefined;
              labels?:
                | Array<{
                    __typename?: 'Label';
                    id: string;
                    title: string;
                    isBanner: boolean;
                    forMainPage: boolean;
                    created: any;
                    updated: any;
                    hidden: boolean;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      };
    }>;
  };
};

export type GetServiceByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type GetServiceByKeyQuery = {
  __typename?: 'Query';
  getServiceByKey: {
    __typename?: 'Services';
    id: string;
    key: string;
    title: string;
    value?: string | null | undefined;
  };
};

export type QueryGetCategoryLabelsQueryVariables = Exact<{ [key: string]: never }>;

export type QueryGetCategoryLabelsQuery = {
  __typename?: 'Query';
  getLabels: Array<{
    __typename?: 'Label';
    id: string;
    title: string;
    description?: string | null | undefined;
    textColor?: string | null | undefined;
    productsCount?: number | null | undefined;
    image?: { __typename?: 'image'; src: string; id: string; width: number; height: number } | null | undefined;
  }>;
};

export type QueryGetTagsLabelsQueryVariables = Exact<{ [key: string]: never }>;

export type QueryGetTagsLabelsQuery = {
  __typename?: 'Query';
  getLabels: Array<{
    __typename?: 'Label';
    id: string;
    title: string;
    description?: string | null | undefined;
    image?: { __typename?: 'image'; src: string; id: string; width: number; height: number } | null | undefined;
  }>;
};

export type QueryGetMainLabelsQueryVariables = Exact<{ [key: string]: never }>;

export type QueryGetMainLabelsQuery = {
  __typename?: 'Query';
  getLabels: Array<{
    __typename?: 'Label';
    id: string;
    title: string;
    description?: string | null | undefined;
    image?: { __typename?: 'image'; src: string; id: string; width: number; height: number } | null | undefined;
  }>;
};

export type QueryGetLabelByIdQueryVariables = Exact<{
  labelId: Scalars['ID'];
}>;

export type QueryGetLabelByIdQuery = {
  __typename?: 'Query';
  getLabelById: {
    __typename?: 'Label';
    id: string;
    title: string;
    description?: string | null | undefined;
    sublabels?: Array<{ __typename?: 'Label'; id: string; title: string }> | null | undefined;
  };
};

export type QueryGetSubCategoryLabelsQueryVariables = Exact<{
  categoryId: Scalars['Int'];
}>;

export type QueryGetSubCategoryLabelsQuery = {
  __typename?: 'Query';
  getSubCategoryLabels?:
    | {
        __typename?: 'Label';
        id: string;
        title: string;
        productsMinPrice?: number | null | undefined;
        image?:
          | {
              __typename?: 'image';
              id: string;
              fileId: string;
              src: string;
              contentType: string;
              size: number;
              width: number;
              height: number;
              entityId: string;
              entityType?: string | null | undefined;
              position: number;
            }
          | null
          | undefined;
        subCategory?:
          | Array<{
              __typename?: 'Label';
              id: string;
              title: string;
              productsMinPrice?: number | null | undefined;
              image?:
                | { __typename?: 'image'; src: string; id: string; width: number; height: number }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMyOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyOrdersQuery = {
  __typename?: 'Query';
  getMyOrders: Array<{
    __typename?: 'Order';
    id: string;
    type: string;
    address: string;
    code: string;
    sum: number;
    created: any;
    updated: any;
    result: boolean;
    payment?: { __typename?: 'Payment'; id: string; amount: string } | null | undefined;
    orderItems?:
      | Array<{
          __typename?: 'OrderItem';
          orderId: string;
          productId: string;
          count?: number | null | undefined;
          laboratory?:
            | { __typename?: 'laboratory'; id: string; name: string; title?: string | null | undefined }
            | null
            | undefined;
          product: {
            __typename?: 'Product';
            id: string;
            title: string;
            description?: string | null | undefined;
            price: number;
            deadline: number;
            isPackage: boolean;
            descriptionShort?: string | null | undefined;
            biomaterial?: string | null | undefined;
            preparation?: string | null | undefined;
            contraindications?: string | null | undefined;
            gemotestCode?: string | null | undefined;
            dialabCode?: string | null | undefined;
            labquestCode?: string | null | undefined;
            code?: string | null | undefined;
            disabled: boolean;
            unavailable: boolean;
            hidden: boolean;
            gemotestAnalysis?:
              | {
                  __typename?: 'gemotestAnalysis';
                  unavailable: boolean;
                  created: any;
                  id: string;
                  updated: any;
                  deadline?: number | null | undefined;
                  deadlineUnit?: DeadlineUnit | null | undefined;
                }
              | null
              | undefined;
            dialabAnalysis?:
              | {
                  __typename?: 'dialabAnalysis';
                  unavailable: boolean;
                  created: any;
                  id: string;
                  updated: any;
                  deadline?: number | null | undefined;
                  deadlineUnit?: DeadlineUnit | null | undefined;
                }
              | null
              | undefined;
            labquestAnalysis?:
              | {
                  __typename?: 'labquestAnalysis';
                  unavailable: boolean;
                  created: any;
                  id: string;
                  updated: any;
                  deadline?: number | null | undefined;
                  deadlineUnit?: DeadlineUnit | null | undefined;
                }
              | null
              | undefined;
            labels?:
              | Array<{
                  __typename?: 'Label';
                  id: string;
                  title: string;
                  isBanner: boolean;
                  forMainPage: boolean;
                  created: any;
                  updated: any;
                  hidden: boolean;
                }>
              | null
              | undefined;
            subProducts?:
              | Array<{
                  __typename?: 'Product';
                  id: string;
                  title: string;
                  description?: string | null | undefined;
                  price: number;
                  deadline: number;
                  isPackage: boolean;
                  descriptionShort?: string | null | undefined;
                  biomaterial?: string | null | undefined;
                  preparation?: string | null | undefined;
                  contraindications?: string | null | undefined;
                  gemotestCode?: string | null | undefined;
                  dialabCode?: string | null | undefined;
                  labquestCode?: string | null | undefined;
                  code?: string | null | undefined;
                  disabled: boolean;
                  unavailable: boolean;
                  hidden: boolean;
                  gemotestAnalysis?:
                    | {
                        __typename?: 'gemotestAnalysis';
                        unavailable: boolean;
                        created: any;
                        id: string;
                        updated: any;
                        deadline?: number | null | undefined;
                        deadlineUnit?: DeadlineUnit | null | undefined;
                      }
                    | null
                    | undefined;
                  dialabAnalysis?:
                    | {
                        __typename?: 'dialabAnalysis';
                        unavailable: boolean;
                        created: any;
                        id: string;
                        updated: any;
                        deadline?: number | null | undefined;
                        deadlineUnit?: DeadlineUnit | null | undefined;
                      }
                    | null
                    | undefined;
                  labquestAnalysis?:
                    | {
                        __typename?: 'labquestAnalysis';
                        unavailable: boolean;
                        created: any;
                        id: string;
                        updated: any;
                        deadline?: number | null | undefined;
                        deadlineUnit?: DeadlineUnit | null | undefined;
                      }
                    | null
                    | undefined;
                  labels?:
                    | Array<{
                        __typename?: 'Label';
                        id: string;
                        title: string;
                        isBanner: boolean;
                        forMainPage: boolean;
                        created: any;
                        updated: any;
                        hidden: boolean;
                      }>
                    | null
                    | undefined;
                }>
              | null
              | undefined;
          };
        }>
      | null
      | undefined;
    results?: Array<{ __typename?: 'result'; id: string; fileUrl?: string | null | undefined }> | null | undefined;
  }>;
};

export type GetPartnerBasketQueryVariables = Exact<{
  PartnerOrderId: Scalars['ID'];
}>;

export type GetPartnerBasketQuery = {
  __typename?: 'Query';
  getPartnerBasket: Array<{ __typename?: 'PartnerBasket'; id: string; productId?: string | null | undefined }>;
};

export type ProductsQueryVariables = Exact<{
  offsetPaging?: InputMaybe<OffsetPaging>;
}>;

export type ProductsQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'ProductsConnectionType';
    totalCount: number;
    pageInfo: { __typename?: 'OffsetPageInfoType'; hasNextPage: boolean; hasPreviousPage: boolean };
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      title: string;
      description?: string | null | undefined;
      price: number;
      deadline: number;
      isPackage: boolean;
      descriptionShort?: string | null | undefined;
      biomaterial?: string | null | undefined;
      preparation?: string | null | undefined;
      contraindications?: string | null | undefined;
      gemotestCode?: string | null | undefined;
      dialabCode?: string | null | undefined;
      labquestCode?: string | null | undefined;
      code?: string | null | undefined;
      disabled: boolean;
      unavailable: boolean;
      hidden: boolean;
      gemotestAnalysis?:
        | {
            __typename?: 'gemotestAnalysis';
            unavailable: boolean;
            created: any;
            id: string;
            updated: any;
            deadline?: number | null | undefined;
            deadlineUnit?: DeadlineUnit | null | undefined;
          }
        | null
        | undefined;
      dialabAnalysis?:
        | {
            __typename?: 'dialabAnalysis';
            unavailable: boolean;
            created: any;
            id: string;
            updated: any;
            deadline?: number | null | undefined;
            deadlineUnit?: DeadlineUnit | null | undefined;
          }
        | null
        | undefined;
      labquestAnalysis?:
        | {
            __typename?: 'labquestAnalysis';
            unavailable: boolean;
            created: any;
            id: string;
            updated: any;
            deadline?: number | null | undefined;
            deadlineUnit?: DeadlineUnit | null | undefined;
          }
        | null
        | undefined;
      labels?:
        | Array<{
            __typename?: 'Label';
            id: string;
            title: string;
            isBanner: boolean;
            forMainPage: boolean;
            created: any;
            updated: any;
            hidden: boolean;
          }>
        | null
        | undefined;
      subProducts?:
        | Array<{
            __typename?: 'Product';
            id: string;
            title: string;
            description?: string | null | undefined;
            price: number;
            deadline: number;
            isPackage: boolean;
            descriptionShort?: string | null | undefined;
            biomaterial?: string | null | undefined;
            preparation?: string | null | undefined;
            contraindications?: string | null | undefined;
            gemotestCode?: string | null | undefined;
            dialabCode?: string | null | undefined;
            labquestCode?: string | null | undefined;
            code?: string | null | undefined;
            disabled: boolean;
            unavailable: boolean;
            hidden: boolean;
            gemotestAnalysis?:
              | {
                  __typename?: 'gemotestAnalysis';
                  unavailable: boolean;
                  created: any;
                  id: string;
                  updated: any;
                  deadline?: number | null | undefined;
                  deadlineUnit?: DeadlineUnit | null | undefined;
                }
              | null
              | undefined;
            dialabAnalysis?:
              | {
                  __typename?: 'dialabAnalysis';
                  unavailable: boolean;
                  created: any;
                  id: string;
                  updated: any;
                  deadline?: number | null | undefined;
                  deadlineUnit?: DeadlineUnit | null | undefined;
                }
              | null
              | undefined;
            labquestAnalysis?:
              | {
                  __typename?: 'labquestAnalysis';
                  unavailable: boolean;
                  created: any;
                  id: string;
                  updated: any;
                  deadline?: number | null | undefined;
                  deadlineUnit?: DeadlineUnit | null | undefined;
                }
              | null
              | undefined;
            labels?:
              | Array<{
                  __typename?: 'Label';
                  id: string;
                  title: string;
                  isBanner: boolean;
                  forMainPage: boolean;
                  created: any;
                  updated: any;
                  hidden: boolean;
                }>
              | null
              | undefined;
          }>
        | null
        | undefined;
    }>;
  };
};

export type ProductsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type ProductsByIdsQuery = {
  __typename?: 'Query';
  productsByIds: Array<{
    __typename?: 'Product';
    id: string;
    title: string;
    description?: string | null | undefined;
    price: number;
    deadline: number;
    isPackage: boolean;
    descriptionShort?: string | null | undefined;
    biomaterial?: string | null | undefined;
    preparation?: string | null | undefined;
    contraindications?: string | null | undefined;
    gemotestCode?: string | null | undefined;
    dialabCode?: string | null | undefined;
    labquestCode?: string | null | undefined;
    code?: string | null | undefined;
    disabled: boolean;
    unavailable: boolean;
    hidden: boolean;
    subProducts?:
      | Array<{
          __typename?: 'Product';
          id: string;
          title: string;
          description?: string | null | undefined;
          price: number;
          deadline: number;
          isPackage: boolean;
          descriptionShort?: string | null | undefined;
          biomaterial?: string | null | undefined;
          preparation?: string | null | undefined;
          contraindications?: string | null | undefined;
          gemotestCode?: string | null | undefined;
          dialabCode?: string | null | undefined;
          labquestCode?: string | null | undefined;
          code?: string | null | undefined;
          disabled: boolean;
          unavailable: boolean;
          hidden: boolean;
          gemotestAnalysis?:
            | {
                __typename?: 'gemotestAnalysis';
                unavailable: boolean;
                created: any;
                id: string;
                updated: any;
                deadline?: number | null | undefined;
                deadlineUnit?: DeadlineUnit | null | undefined;
              }
            | null
            | undefined;
          dialabAnalysis?:
            | {
                __typename?: 'dialabAnalysis';
                unavailable: boolean;
                created: any;
                id: string;
                updated: any;
                deadline?: number | null | undefined;
                deadlineUnit?: DeadlineUnit | null | undefined;
              }
            | null
            | undefined;
          labquestAnalysis?:
            | {
                __typename?: 'labquestAnalysis';
                unavailable: boolean;
                created: any;
                id: string;
                updated: any;
                deadline?: number | null | undefined;
                deadlineUnit?: DeadlineUnit | null | undefined;
              }
            | null
            | undefined;
          labels?:
            | Array<{
                __typename?: 'Label';
                id: string;
                title: string;
                isBanner: boolean;
                forMainPage: boolean;
                created: any;
                updated: any;
                hidden: boolean;
              }>
            | null
            | undefined;
          subProducts?:
            | Array<{
                __typename?: 'Product';
                id: string;
                title: string;
                description?: string | null | undefined;
                price: number;
                deadline: number;
                isPackage: boolean;
                descriptionShort?: string | null | undefined;
                biomaterial?: string | null | undefined;
                preparation?: string | null | undefined;
                contraindications?: string | null | undefined;
                gemotestCode?: string | null | undefined;
                dialabCode?: string | null | undefined;
                labquestCode?: string | null | undefined;
                code?: string | null | undefined;
                disabled: boolean;
                unavailable: boolean;
                hidden: boolean;
                gemotestAnalysis?:
                  | {
                      __typename?: 'gemotestAnalysis';
                      unavailable: boolean;
                      created: any;
                      id: string;
                      updated: any;
                      deadline?: number | null | undefined;
                      deadlineUnit?: DeadlineUnit | null | undefined;
                    }
                  | null
                  | undefined;
                dialabAnalysis?:
                  | {
                      __typename?: 'dialabAnalysis';
                      unavailable: boolean;
                      created: any;
                      id: string;
                      updated: any;
                      deadline?: number | null | undefined;
                      deadlineUnit?: DeadlineUnit | null | undefined;
                    }
                  | null
                  | undefined;
                labquestAnalysis?:
                  | {
                      __typename?: 'labquestAnalysis';
                      unavailable: boolean;
                      created: any;
                      id: string;
                      updated: any;
                      deadline?: number | null | undefined;
                      deadlineUnit?: DeadlineUnit | null | undefined;
                    }
                  | null
                  | undefined;
                labels?:
                  | Array<{
                      __typename?: 'Label';
                      id: string;
                      title: string;
                      isBanner: boolean;
                      forMainPage: boolean;
                      created: any;
                      updated: any;
                      hidden: boolean;
                    }>
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    gemotestAnalysis?:
      | {
          __typename?: 'gemotestAnalysis';
          unavailable: boolean;
          created: any;
          id: string;
          updated: any;
          deadline?: number | null | undefined;
          deadlineUnit?: DeadlineUnit | null | undefined;
        }
      | null
      | undefined;
    dialabAnalysis?:
      | {
          __typename?: 'dialabAnalysis';
          unavailable: boolean;
          created: any;
          id: string;
          updated: any;
          deadline?: number | null | undefined;
          deadlineUnit?: DeadlineUnit | null | undefined;
        }
      | null
      | undefined;
    labquestAnalysis?:
      | {
          __typename?: 'labquestAnalysis';
          unavailable: boolean;
          created: any;
          id: string;
          updated: any;
          deadline?: number | null | undefined;
          deadlineUnit?: DeadlineUnit | null | undefined;
        }
      | null
      | undefined;
    labels?:
      | Array<{
          __typename?: 'Label';
          id: string;
          title: string;
          isBanner: boolean;
          forMainPage: boolean;
          created: any;
          updated: any;
          hidden: boolean;
        }>
      | null
      | undefined;
  }>;
};

export type MyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type MyProfileQuery = {
  __typename?: 'Query';
  myProfile: {
    __typename?: 'User';
    id: string;
    username?: string | null | undefined;
    birthday?: string | null | undefined;
    gender?: Gender | null | undefined;
    email?: string | null | undefined;
    emailIsConfirmed: boolean;
    avatar?: string | null | undefined;
    phone?: string | null | undefined;
    phoneIsVerified: boolean;
    nameFirst?: string | null | undefined;
    nameLast?: string | null | undefined;
    nameMiddle?: string | null | undefined;
    passport?: { __typename?: 'Passport'; id: string; passportNumber: string } | null | undefined;
  };
};

export type GetPromoBannersQueryVariables = Exact<{ [key: string]: never }>;

export type GetPromoBannersQuery = {
  __typename?: 'Query';
  promoBanners: Array<{
    __typename?: 'PromoBanner';
    active?: boolean | null | undefined;
    blank?: boolean | null | undefined;
    description?: string | null | undefined;
    id: number;
    link?: string | null | undefined;
    sortOrder?: boolean | null | undefined;
    title: string;
    images?:
      | Array<{
          __typename?: 'image';
          id: string;
          height: number;
          fileId: string;
          entityType?: string | null | undefined;
          entityId: string;
          created: any;
          contentType: string;
          position: number;
          size: number;
          src: string;
          updated: any;
          width: number;
        }>
      | null
      | undefined;
  }>;
};

export type GetQuestionAnswerQueryVariables = Exact<{ [key: string]: never }>;

export type GetQuestionAnswerQuery = {
  __typename?: 'Query';
  questionAnswers: Array<{
    __typename?: 'QuestionAnswer';
    id: number;
    question: string;
    answer?: string | null | undefined;
    sortOrder?: number | null | undefined;
  }>;
};

export type FindProductsQueryVariables = Exact<{
  offsetPaging?: InputMaybe<OffsetPaging>;
  search?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type FindProductsQuery = {
  __typename?: 'Query';
  findProducts: {
    __typename?: 'ProductsConnectionType';
    totalCount: number;
    pageInfo: { __typename?: 'OffsetPageInfoType'; hasNextPage: boolean; hasPreviousPage: boolean };
    nodes: Array<{
      __typename?: 'Product';
      id: string;
      title: string;
      description?: string | null | undefined;
      price: number;
      deadline: number;
      isPackage: boolean;
      descriptionShort?: string | null | undefined;
      biomaterial?: string | null | undefined;
      preparation?: string | null | undefined;
      contraindications?: string | null | undefined;
      gemotestCode?: string | null | undefined;
      dialabCode?: string | null | undefined;
      labquestCode?: string | null | undefined;
      code?: string | null | undefined;
      disabled: boolean;
      unavailable: boolean;
      hidden: boolean;
      gemotestAnalysis?:
        | {
            __typename?: 'gemotestAnalysis';
            unavailable: boolean;
            created: any;
            id: string;
            updated: any;
            deadline?: number | null | undefined;
            deadlineUnit?: DeadlineUnit | null | undefined;
          }
        | null
        | undefined;
      dialabAnalysis?:
        | {
            __typename?: 'dialabAnalysis';
            unavailable: boolean;
            created: any;
            id: string;
            updated: any;
            deadline?: number | null | undefined;
            deadlineUnit?: DeadlineUnit | null | undefined;
          }
        | null
        | undefined;
      labquestAnalysis?:
        | {
            __typename?: 'labquestAnalysis';
            unavailable: boolean;
            created: any;
            id: string;
            updated: any;
            deadline?: number | null | undefined;
            deadlineUnit?: DeadlineUnit | null | undefined;
          }
        | null
        | undefined;
      labels?:
        | Array<{
            __typename?: 'Label';
            id: string;
            title: string;
            isBanner: boolean;
            forMainPage: boolean;
            created: any;
            updated: any;
            hidden: boolean;
          }>
        | null
        | undefined;
      subProducts?:
        | Array<{
            __typename?: 'Product';
            id: string;
            title: string;
            description?: string | null | undefined;
            price: number;
            deadline: number;
            isPackage: boolean;
            descriptionShort?: string | null | undefined;
            biomaterial?: string | null | undefined;
            preparation?: string | null | undefined;
            contraindications?: string | null | undefined;
            gemotestCode?: string | null | undefined;
            dialabCode?: string | null | undefined;
            labquestCode?: string | null | undefined;
            code?: string | null | undefined;
            disabled: boolean;
            unavailable: boolean;
            hidden: boolean;
            gemotestAnalysis?:
              | {
                  __typename?: 'gemotestAnalysis';
                  unavailable: boolean;
                  created: any;
                  id: string;
                  updated: any;
                  deadline?: number | null | undefined;
                  deadlineUnit?: DeadlineUnit | null | undefined;
                }
              | null
              | undefined;
            dialabAnalysis?:
              | {
                  __typename?: 'dialabAnalysis';
                  unavailable: boolean;
                  created: any;
                  id: string;
                  updated: any;
                  deadline?: number | null | undefined;
                  deadlineUnit?: DeadlineUnit | null | undefined;
                }
              | null
              | undefined;
            labquestAnalysis?:
              | {
                  __typename?: 'labquestAnalysis';
                  unavailable: boolean;
                  created: any;
                  id: string;
                  updated: any;
                  deadline?: number | null | undefined;
                  deadlineUnit?: DeadlineUnit | null | undefined;
                }
              | null
              | undefined;
            labels?:
              | Array<{
                  __typename?: 'Label';
                  id: string;
                  title: string;
                  isBanner: boolean;
                  forMainPage: boolean;
                  created: any;
                  updated: any;
                  hidden: boolean;
                }>
              | null
              | undefined;
          }>
        | null
        | undefined;
    }>;
  };
};

export type GetSlotsQueryVariables = Exact<{
  date: Scalars['DateTime'];
}>;

export type GetSlotsQuery = {
  __typename?: 'Query';
  getSlots: {
    __typename?: 'SoltsConnectionType';
    nodes: Array<{
      __typename?: 'slots';
      id: string;
      date: any;
      label: string;
      type: string;
      slotDayId: string;
      isActive: boolean;
      isValid: boolean;
    }>;
  };
};

export type StaticBlocksQueryVariables = Exact<{ [key: string]: never }>;

export type StaticBlocksQuery = {
  __typename?: 'Query';
  staticBlocks: Array<{
    __typename?: 'StaticBlock';
    id: string;
    code?: string | null | undefined;
    content: string;
    created: any;
    updated: any;
  }>;
};

export type GetUserFieldsSuggestionsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  parts?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetUserFieldsSuggestionsQuery = { __typename?: 'Query'; userFieldsSuggestions: Array<string> };

export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    id
    name
    country
    city
    address
    entrance
    floor
    apartment
    isPrimary
  }
`;
export const ProductFieldsFragmentDoc = gql`
  fragment ProductFields on Product {
    id
    title
    description
    price
    deadline
    isPackage
    descriptionShort
    biomaterial
    preparation
    contraindications
    gemotestCode
    dialabCode
    labquestCode
    code
    disabled
    unavailable
    gemotestAnalysis {
      unavailable
      created
      id
      updated
      deadline
      deadlineUnit
    }
    dialabAnalysis {
      unavailable
      created
      id
      updated
      deadline
      deadlineUnit
    }
    labquestAnalysis {
      unavailable
      created
      id
      updated
      deadline
      deadlineUnit
    }
    labels {
      id
      title
      isBanner
      forMainPage
      created
      updated
      hidden
    }
    hidden
    subProducts {
      id
      title
      description
      price
      deadline
      isPackage
      descriptionShort
      biomaterial
      preparation
      contraindications
      gemotestCode
      dialabCode
      labquestCode
      code
      disabled
      unavailable
      gemotestAnalysis {
        unavailable
        created
        id
        updated
        deadline
        deadlineUnit
      }
      dialabAnalysis {
        unavailable
        created
        id
        updated
        deadline
        deadlineUnit
      }
      labquestAnalysis {
        unavailable
        created
        id
        updated
        deadline
        deadlineUnit
      }
      labels {
        id
        title
        isBanner
        forMainPage
        created
        updated
        hidden
      }
      hidden
    }
  }
`;
export const BasketProductsFieldsFragmentDoc = gql`
  fragment BasketProductsFields on Basket {
    id
    product {
      ...ProductFields
    }
  }
  ${ProductFieldsFragmentDoc}
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    username
    birthday
    gender
    email
    emailIsConfirmed
    avatar
    phone
    phoneIsVerified
    nameFirst
    nameLast
    nameMiddle
    passport {
      id
      passportNumber
    }
  }
`;
export const EditAddressDocument = gql`
  mutation EditAddress($input: EditAddressInput!, $addressId: ID!) {
    editAddress(input: $input, addressId: $addressId) {
      ...AddressFields
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type EditAddressMutationFn = Apollo.MutationFunction<EditAddressMutation, EditAddressMutationVariables>;

/**
 * __useEditAddressMutation__
 *
 * To run a mutation, you first call `useEditAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAddressMutation, { data, loading, error }] = useEditAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useEditAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<EditAddressMutation, EditAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAddressMutation, EditAddressMutationVariables>(EditAddressDocument, options);
}
export type EditAddressMutationHookResult = ReturnType<typeof useEditAddressMutation>;
export type EditAddressMutationResult = Apollo.MutationResult<EditAddressMutation>;
export type EditAddressMutationOptions = Apollo.BaseMutationOptions<EditAddressMutation, EditAddressMutationVariables>;
export const AddAddressDocument = gql`
  mutation AddAddress($input: AddAddressInput!) {
    addAddress(input: $input) {
      ...AddressFields
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type AddAddressMutationFn = Apollo.MutationFunction<AddAddressMutation, AddAddressMutationVariables>;

/**
 * __useAddAddressMutation__
 *
 * To run a mutation, you first call `useAddAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressMutation, { data, loading, error }] = useAddAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAddressMutation, AddAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAddressMutation, AddAddressMutationVariables>(AddAddressDocument, options);
}
export type AddAddressMutationHookResult = ReturnType<typeof useAddAddressMutation>;
export type AddAddressMutationResult = Apollo.MutationResult<AddAddressMutation>;
export type AddAddressMutationOptions = Apollo.BaseMutationOptions<AddAddressMutation, AddAddressMutationVariables>;
export const RemoveAddressDocument = gql`
  mutation RemoveAddress($addressId: ID!) {
    removeAddress(addressId: $addressId) {
      ...AddressFields
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type RemoveAddressMutationFn = Apollo.MutationFunction<RemoveAddressMutation, RemoveAddressMutationVariables>;

/**
 * __useRemoveAddressMutation__
 *
 * To run a mutation, you first call `useRemoveAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAddressMutation, { data, loading, error }] = useRemoveAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useRemoveAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveAddressMutation, RemoveAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAddressMutation, RemoveAddressMutationVariables>(RemoveAddressDocument, options);
}
export type RemoveAddressMutationHookResult = ReturnType<typeof useRemoveAddressMutation>;
export type RemoveAddressMutationResult = Apollo.MutationResult<RemoveAddressMutation>;
export type RemoveAddressMutationOptions = Apollo.BaseMutationOptions<
  RemoveAddressMutation,
  RemoveAddressMutationVariables
>;
export const AuthDocument = gql`
  mutation Auth($phone: String!) {
    auth(phone: $phone)
  }
`;
export type AuthMutationFn = Apollo.MutationFunction<AuthMutation, AuthMutationVariables>;

/**
 * __useAuthMutation__
 *
 * To run a mutation, you first call `useAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authMutation, { data, loading, error }] = useAuthMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useAuthMutation(baseOptions?: Apollo.MutationHookOptions<AuthMutation, AuthMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuthMutation, AuthMutationVariables>(AuthDocument, options);
}
export type AuthMutationHookResult = ReturnType<typeof useAuthMutation>;
export type AuthMutationResult = Apollo.MutationResult<AuthMutation>;
export type AuthMutationOptions = Apollo.BaseMutationOptions<AuthMutation, AuthMutationVariables>;
export const AuthVerifyDocument = gql`
  mutation AuthVerify($code: String!, $phone: String!) {
    verify(code: $code, phone: $phone) {
      accessToken
      phone
    }
  }
`;
export type AuthVerifyMutationFn = Apollo.MutationFunction<AuthVerifyMutation, AuthVerifyMutationVariables>;

/**
 * __useAuthVerifyMutation__
 *
 * To run a mutation, you first call `useAuthVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authVerifyMutation, { data, loading, error }] = useAuthVerifyMutation({
 *   variables: {
 *      code: // value for 'code'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useAuthVerifyMutation(
  baseOptions?: Apollo.MutationHookOptions<AuthVerifyMutation, AuthVerifyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuthVerifyMutation, AuthVerifyMutationVariables>(AuthVerifyDocument, options);
}
export type AuthVerifyMutationHookResult = ReturnType<typeof useAuthVerifyMutation>;
export type AuthVerifyMutationResult = Apollo.MutationResult<AuthVerifyMutation>;
export type AuthVerifyMutationOptions = Apollo.BaseMutationOptions<AuthVerifyMutation, AuthVerifyMutationVariables>;
export const AddProductsToBasketDocument = gql`
  mutation AddProductsToBasket($products: [BasketProductInput!]!) {
    addProductsToBasket(products: $products) {
      sum
      total
      nurseVisit
      products {
        ...BasketProductsFields
      }
    }
  }
  ${BasketProductsFieldsFragmentDoc}
`;
export type AddProductsToBasketMutationFn = Apollo.MutationFunction<
  AddProductsToBasketMutation,
  AddProductsToBasketMutationVariables
>;

/**
 * __useAddProductsToBasketMutation__
 *
 * To run a mutation, you first call `useAddProductsToBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductsToBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductsToBasketMutation, { data, loading, error }] = useAddProductsToBasketMutation({
 *   variables: {
 *      products: // value for 'products'
 *   },
 * });
 */
export function useAddProductsToBasketMutation(
  baseOptions?: Apollo.MutationHookOptions<AddProductsToBasketMutation, AddProductsToBasketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProductsToBasketMutation, AddProductsToBasketMutationVariables>(
    AddProductsToBasketDocument,
    options
  );
}
export type AddProductsToBasketMutationHookResult = ReturnType<typeof useAddProductsToBasketMutation>;
export type AddProductsToBasketMutationResult = Apollo.MutationResult<AddProductsToBasketMutation>;
export type AddProductsToBasketMutationOptions = Apollo.BaseMutationOptions<
  AddProductsToBasketMutation,
  AddProductsToBasketMutationVariables
>;
export const RemoveProductFromBasketDocument = gql`
  mutation RemoveProductFromBasket($productId: ID!) {
    removeProductFromBasket(productId: $productId) {
      sum
      total
      nurseVisit
      products {
        ...BasketProductsFields
      }
    }
  }
  ${BasketProductsFieldsFragmentDoc}
`;
export type RemoveProductFromBasketMutationFn = Apollo.MutationFunction<
  RemoveProductFromBasketMutation,
  RemoveProductFromBasketMutationVariables
>;

/**
 * __useRemoveProductFromBasketMutation__
 *
 * To run a mutation, you first call `useRemoveProductFromBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductFromBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductFromBasketMutation, { data, loading, error }] = useRemoveProductFromBasketMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveProductFromBasketMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveProductFromBasketMutation, RemoveProductFromBasketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveProductFromBasketMutation, RemoveProductFromBasketMutationVariables>(
    RemoveProductFromBasketDocument,
    options
  );
}
export type RemoveProductFromBasketMutationHookResult = ReturnType<typeof useRemoveProductFromBasketMutation>;
export type RemoveProductFromBasketMutationResult = Apollo.MutationResult<RemoveProductFromBasketMutation>;
export type RemoveProductFromBasketMutationOptions = Apollo.BaseMutationOptions<
  RemoveProductFromBasketMutation,
  RemoveProductFromBasketMutationVariables
>;
export const EmptyBasketDocument = gql`
  mutation EmptyBasket {
    emptyBasket {
      sum
      total
      nurseVisit
      products {
        ...BasketProductsFields
      }
    }
  }
  ${BasketProductsFieldsFragmentDoc}
`;
export type EmptyBasketMutationFn = Apollo.MutationFunction<EmptyBasketMutation, EmptyBasketMutationVariables>;

/**
 * __useEmptyBasketMutation__
 *
 * To run a mutation, you first call `useEmptyBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyBasketMutation, { data, loading, error }] = useEmptyBasketMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyBasketMutation(
  baseOptions?: Apollo.MutationHookOptions<EmptyBasketMutation, EmptyBasketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EmptyBasketMutation, EmptyBasketMutationVariables>(EmptyBasketDocument, options);
}
export type EmptyBasketMutationHookResult = ReturnType<typeof useEmptyBasketMutation>;
export type EmptyBasketMutationResult = Apollo.MutationResult<EmptyBasketMutation>;
export type EmptyBasketMutationOptions = Apollo.BaseMutationOptions<EmptyBasketMutation, EmptyBasketMutationVariables>;
export const PayHomeDocument = gql`
  mutation payHome($input: PayHomeInput!) {
    payHome(input: $input) {
      id
      userId
      payment {
        paymentId
        confirmationToken
        card {
          first6
        }
      }
      orderItems {
        count
        price
      }
    }
  }
`;
export type PayHomeMutationFn = Apollo.MutationFunction<PayHomeMutation, PayHomeMutationVariables>;

/**
 * __usePayHomeMutation__
 *
 * To run a mutation, you first call `usePayHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payHomeMutation, { data, loading, error }] = usePayHomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayHomeMutation(
  baseOptions?: Apollo.MutationHookOptions<PayHomeMutation, PayHomeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayHomeMutation, PayHomeMutationVariables>(PayHomeDocument, options);
}
export type PayHomeMutationHookResult = ReturnType<typeof usePayHomeMutation>;
export type PayHomeMutationResult = Apollo.MutationResult<PayHomeMutation>;
export type PayHomeMutationOptions = Apollo.BaseMutationOptions<PayHomeMutation, PayHomeMutationVariables>;
export const AddOrderDocument = gql`
  mutation addOrder($input: AddOrderInput!) {
    addOrder(input: $input) {
      id
      userId
      payment {
        paymentId
        confirmationToken
        card {
          first6
        }
      }
      orderItems {
        count
        price
      }
    }
  }
`;
export type AddOrderMutationFn = Apollo.MutationFunction<AddOrderMutation, AddOrderMutationVariables>;

/**
 * __useAddOrderMutation__
 *
 * To run a mutation, you first call `useAddOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderMutation, { data, loading, error }] = useAddOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<AddOrderMutation, AddOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOrderMutation, AddOrderMutationVariables>(AddOrderDocument, options);
}
export type AddOrderMutationHookResult = ReturnType<typeof useAddOrderMutation>;
export type AddOrderMutationResult = Apollo.MutationResult<AddOrderMutation>;
export type AddOrderMutationOptions = Apollo.BaseMutationOptions<AddOrderMutation, AddOrderMutationVariables>;
export const EditProfileDocument = gql`
  mutation EditProfile($input: EditProfileInput!) {
    editProfile(input: $input) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type EditProfileMutationFn = Apollo.MutationFunction<EditProfileMutation, EditProfileMutationVariables>;

/**
 * __useEditProfileMutation__
 *
 * To run a mutation, you first call `useEditProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileMutation, { data, loading, error }] = useEditProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<EditProfileMutation, EditProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditProfileMutation, EditProfileMutationVariables>(EditProfileDocument, options);
}
export type EditProfileMutationHookResult = ReturnType<typeof useEditProfileMutation>;
export type EditProfileMutationResult = Apollo.MutationResult<EditProfileMutation>;
export type EditProfileMutationOptions = Apollo.BaseMutationOptions<EditProfileMutation, EditProfileMutationVariables>;
export const EditEmailDocument = gql`
  mutation EditEmail($email: String!) {
    editEmail(email: $email) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type EditEmailMutationFn = Apollo.MutationFunction<EditEmailMutation, EditEmailMutationVariables>;

/**
 * __useEditEmailMutation__
 *
 * To run a mutation, you first call `useEditEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmailMutation, { data, loading, error }] = useEditEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEditEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<EditEmailMutation, EditEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditEmailMutation, EditEmailMutationVariables>(EditEmailDocument, options);
}
export type EditEmailMutationHookResult = ReturnType<typeof useEditEmailMutation>;
export type EditEmailMutationResult = Apollo.MutationResult<EditEmailMutation>;
export type EditEmailMutationOptions = Apollo.BaseMutationOptions<EditEmailMutation, EditEmailMutationVariables>;
export const EditPassportDocument = gql`
  mutation EditPassport($input: PassportInput!) {
    editPassport(input: $input) {
      id
      passportNumber
    }
  }
`;
export type EditPassportMutationFn = Apollo.MutationFunction<EditPassportMutation, EditPassportMutationVariables>;

/**
 * __useEditPassportMutation__
 *
 * To run a mutation, you first call `useEditPassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPassportMutation, { data, loading, error }] = useEditPassportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPassportMutation(
  baseOptions?: Apollo.MutationHookOptions<EditPassportMutation, EditPassportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPassportMutation, EditPassportMutationVariables>(EditPassportDocument, options);
}
export type EditPassportMutationHookResult = ReturnType<typeof useEditPassportMutation>;
export type EditPassportMutationResult = Apollo.MutationResult<EditPassportMutation>;
export type EditPassportMutationOptions = Apollo.BaseMutationOptions<
  EditPassportMutation,
  EditPassportMutationVariables
>;
export const FillBasketDataDocument = gql`
  mutation FillBasketData($input: FillBasketDataInput!) {
    fillBasketData(input: $input) {
      sum
      total
      nurseVisit
      products {
        ...BasketProductsFields
      }
    }
  }
  ${BasketProductsFieldsFragmentDoc}
`;
export type FillBasketDataMutationFn = Apollo.MutationFunction<FillBasketDataMutation, FillBasketDataMutationVariables>;

/**
 * __useFillBasketDataMutation__
 *
 * To run a mutation, you first call `useFillBasketDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFillBasketDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fillBasketDataMutation, { data, loading, error }] = useFillBasketDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFillBasketDataMutation(
  baseOptions?: Apollo.MutationHookOptions<FillBasketDataMutation, FillBasketDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FillBasketDataMutation, FillBasketDataMutationVariables>(FillBasketDataDocument, options);
}
export type FillBasketDataMutationHookResult = ReturnType<typeof useFillBasketDataMutation>;
export type FillBasketDataMutationResult = Apollo.MutationResult<FillBasketDataMutation>;
export type FillBasketDataMutationOptions = Apollo.BaseMutationOptions<
  FillBasketDataMutation,
  FillBasketDataMutationVariables
>;
export const SendEmailConfirmTokenDocument = gql`
  mutation sendEmailConfirmToken {
    sendEmailConfirmToken
  }
`;
export type SendEmailConfirmTokenMutationFn = Apollo.MutationFunction<
  SendEmailConfirmTokenMutation,
  SendEmailConfirmTokenMutationVariables
>;

/**
 * __useSendEmailConfirmTokenMutation__
 *
 * To run a mutation, you first call `useSendEmailConfirmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailConfirmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailConfirmTokenMutation, { data, loading, error }] = useSendEmailConfirmTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendEmailConfirmTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<SendEmailConfirmTokenMutation, SendEmailConfirmTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendEmailConfirmTokenMutation, SendEmailConfirmTokenMutationVariables>(
    SendEmailConfirmTokenDocument,
    options
  );
}
export type SendEmailConfirmTokenMutationHookResult = ReturnType<typeof useSendEmailConfirmTokenMutation>;
export type SendEmailConfirmTokenMutationResult = Apollo.MutationResult<SendEmailConfirmTokenMutation>;
export type SendEmailConfirmTokenMutationOptions = Apollo.BaseMutationOptions<
  SendEmailConfirmTokenMutation,
  SendEmailConfirmTokenMutationVariables
>;
export const MyAddressesDocument = gql`
  query MyAddresses {
    myAddresses {
      ...AddressFields
    }
  }
  ${AddressFieldsFragmentDoc}
`;

/**
 * __useMyAddressesQuery__
 *
 * To run a query within a React component, call `useMyAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAddressesQuery(
  baseOptions?: Apollo.QueryHookOptions<MyAddressesQuery, MyAddressesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyAddressesQuery, MyAddressesQueryVariables>(MyAddressesDocument, options);
}
export function useMyAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyAddressesQuery, MyAddressesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyAddressesQuery, MyAddressesQueryVariables>(MyAddressesDocument, options);
}
export type MyAddressesQueryHookResult = ReturnType<typeof useMyAddressesQuery>;
export type MyAddressesLazyQueryHookResult = ReturnType<typeof useMyAddressesLazyQuery>;
export type MyAddressesQueryResult = Apollo.QueryResult<MyAddressesQuery, MyAddressesQueryVariables>;
export const GetMyBasketDocument = gql`
  query GetMyBasket {
    getMyBasket {
      sum
      total
      nurseVisit
      products {
        ...BasketProductsFields
      }
    }
  }
  ${BasketProductsFieldsFragmentDoc}
`;

/**
 * __useGetMyBasketQuery__
 *
 * To run a query within a React component, call `useGetMyBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBasketQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyBasketQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyBasketQuery, GetMyBasketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyBasketQuery, GetMyBasketQueryVariables>(GetMyBasketDocument, options);
}
export function useGetMyBasketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyBasketQuery, GetMyBasketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyBasketQuery, GetMyBasketQueryVariables>(GetMyBasketDocument, options);
}
export type GetMyBasketQueryHookResult = ReturnType<typeof useGetMyBasketQuery>;
export type GetMyBasketLazyQueryHookResult = ReturnType<typeof useGetMyBasketLazyQuery>;
export type GetMyBasketQueryResult = Apollo.QueryResult<GetMyBasketQuery, GetMyBasketQueryVariables>;
export const GetServiceByKeyDocument = gql`
  query GetServiceByKey($key: String!) {
    getServiceByKey(key: $key) {
      id
      key
      title
      value
    }
  }
`;

/**
 * __useGetServiceByKeyQuery__
 *
 * To run a query within a React component, call `useGetServiceByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetServiceByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<GetServiceByKeyQuery, GetServiceByKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServiceByKeyQuery, GetServiceByKeyQueryVariables>(GetServiceByKeyDocument, options);
}
export function useGetServiceByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetServiceByKeyQuery, GetServiceByKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServiceByKeyQuery, GetServiceByKeyQueryVariables>(GetServiceByKeyDocument, options);
}
export type GetServiceByKeyQueryHookResult = ReturnType<typeof useGetServiceByKeyQuery>;
export type GetServiceByKeyLazyQueryHookResult = ReturnType<typeof useGetServiceByKeyLazyQuery>;
export type GetServiceByKeyQueryResult = Apollo.QueryResult<GetServiceByKeyQuery, GetServiceByKeyQueryVariables>;
export const QueryGetCategoryLabelsDocument = gql`
  query QueryGetCategoryLabels {
    getLabels(isBanner: true) {
      id
      title
      description
      textColor
      image {
        src
        id
        width
        height
      }
      productsCount
    }
  }
`;

/**
 * __useQueryGetCategoryLabelsQuery__
 *
 * To run a query within a React component, call `useQueryGetCategoryLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetCategoryLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetCategoryLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryGetCategoryLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryGetCategoryLabelsQuery, QueryGetCategoryLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryGetCategoryLabelsQuery, QueryGetCategoryLabelsQueryVariables>(
    QueryGetCategoryLabelsDocument,
    options
  );
}
export function useQueryGetCategoryLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryGetCategoryLabelsQuery, QueryGetCategoryLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryGetCategoryLabelsQuery, QueryGetCategoryLabelsQueryVariables>(
    QueryGetCategoryLabelsDocument,
    options
  );
}
export type QueryGetCategoryLabelsQueryHookResult = ReturnType<typeof useQueryGetCategoryLabelsQuery>;
export type QueryGetCategoryLabelsLazyQueryHookResult = ReturnType<typeof useQueryGetCategoryLabelsLazyQuery>;
export type QueryGetCategoryLabelsQueryResult = Apollo.QueryResult<
  QueryGetCategoryLabelsQuery,
  QueryGetCategoryLabelsQueryVariables
>;
export const QueryGetTagsLabelsDocument = gql`
  query QueryGetTagsLabels {
    getLabels(forMainPage: false, isBanner: false) {
      id
      title
      description
      image {
        src
        id
        width
        height
      }
    }
  }
`;

/**
 * __useQueryGetTagsLabelsQuery__
 *
 * To run a query within a React component, call `useQueryGetTagsLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetTagsLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetTagsLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryGetTagsLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryGetTagsLabelsQuery, QueryGetTagsLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryGetTagsLabelsQuery, QueryGetTagsLabelsQueryVariables>(
    QueryGetTagsLabelsDocument,
    options
  );
}
export function useQueryGetTagsLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryGetTagsLabelsQuery, QueryGetTagsLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryGetTagsLabelsQuery, QueryGetTagsLabelsQueryVariables>(
    QueryGetTagsLabelsDocument,
    options
  );
}
export type QueryGetTagsLabelsQueryHookResult = ReturnType<typeof useQueryGetTagsLabelsQuery>;
export type QueryGetTagsLabelsLazyQueryHookResult = ReturnType<typeof useQueryGetTagsLabelsLazyQuery>;
export type QueryGetTagsLabelsQueryResult = Apollo.QueryResult<
  QueryGetTagsLabelsQuery,
  QueryGetTagsLabelsQueryVariables
>;
export const QueryGetMainLabelsDocument = gql`
  query QueryGetMainLabels {
    getLabels(forMainPage: true) {
      id
      title
      description
      image {
        src
        id
        width
        height
      }
    }
  }
`;

/**
 * __useQueryGetMainLabelsQuery__
 *
 * To run a query within a React component, call `useQueryGetMainLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetMainLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetMainLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryGetMainLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryGetMainLabelsQuery, QueryGetMainLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryGetMainLabelsQuery, QueryGetMainLabelsQueryVariables>(
    QueryGetMainLabelsDocument,
    options
  );
}
export function useQueryGetMainLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryGetMainLabelsQuery, QueryGetMainLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryGetMainLabelsQuery, QueryGetMainLabelsQueryVariables>(
    QueryGetMainLabelsDocument,
    options
  );
}
export type QueryGetMainLabelsQueryHookResult = ReturnType<typeof useQueryGetMainLabelsQuery>;
export type QueryGetMainLabelsLazyQueryHookResult = ReturnType<typeof useQueryGetMainLabelsLazyQuery>;
export type QueryGetMainLabelsQueryResult = Apollo.QueryResult<
  QueryGetMainLabelsQuery,
  QueryGetMainLabelsQueryVariables
>;
export const QueryGetLabelByIdDocument = gql`
  query QueryGetLabelById($labelId: ID!) {
    getLabelById(labelId: $labelId) {
      id
      title
      description
      sublabels {
        id
        title
      }
    }
  }
`;

/**
 * __useQueryGetLabelByIdQuery__
 *
 * To run a query within a React component, call `useQueryGetLabelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetLabelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetLabelByIdQuery({
 *   variables: {
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useQueryGetLabelByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryGetLabelByIdQuery, QueryGetLabelByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryGetLabelByIdQuery, QueryGetLabelByIdQueryVariables>(QueryGetLabelByIdDocument, options);
}
export function useQueryGetLabelByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryGetLabelByIdQuery, QueryGetLabelByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryGetLabelByIdQuery, QueryGetLabelByIdQueryVariables>(
    QueryGetLabelByIdDocument,
    options
  );
}
export type QueryGetLabelByIdQueryHookResult = ReturnType<typeof useQueryGetLabelByIdQuery>;
export type QueryGetLabelByIdLazyQueryHookResult = ReturnType<typeof useQueryGetLabelByIdLazyQuery>;
export type QueryGetLabelByIdQueryResult = Apollo.QueryResult<QueryGetLabelByIdQuery, QueryGetLabelByIdQueryVariables>;
export const QueryGetSubCategoryLabelsDocument = gql`
  query QueryGetSubCategoryLabels($categoryId: Int!) {
    getSubCategoryLabels(categoryId: $categoryId) {
      id
      title
      image {
        id
        fileId
        src
        contentType
        size
        width
        height
        entityId
        entityType
        position
      }
      productsMinPrice
      subCategory {
        id
        title
        productsMinPrice
        image {
          src
          id
          width
          height
        }
      }
    }
  }
`;

/**
 * __useQueryGetSubCategoryLabelsQuery__
 *
 * To run a query within a React component, call `useQueryGetSubCategoryLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetSubCategoryLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetSubCategoryLabelsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useQueryGetSubCategoryLabelsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryGetSubCategoryLabelsQuery, QueryGetSubCategoryLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryGetSubCategoryLabelsQuery, QueryGetSubCategoryLabelsQueryVariables>(
    QueryGetSubCategoryLabelsDocument,
    options
  );
}
export function useQueryGetSubCategoryLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryGetSubCategoryLabelsQuery, QueryGetSubCategoryLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryGetSubCategoryLabelsQuery, QueryGetSubCategoryLabelsQueryVariables>(
    QueryGetSubCategoryLabelsDocument,
    options
  );
}
export type QueryGetSubCategoryLabelsQueryHookResult = ReturnType<typeof useQueryGetSubCategoryLabelsQuery>;
export type QueryGetSubCategoryLabelsLazyQueryHookResult = ReturnType<typeof useQueryGetSubCategoryLabelsLazyQuery>;
export type QueryGetSubCategoryLabelsQueryResult = Apollo.QueryResult<
  QueryGetSubCategoryLabelsQuery,
  QueryGetSubCategoryLabelsQueryVariables
>;
export const GetMyOrdersDocument = gql`
  query GetMyOrders {
    getMyOrders {
      id
      type
      address
      code
      sum
      created
      updated
      payment {
        id
        amount
      }
      orderItems {
        laboratory {
          id
          name
          title
        }
        orderId
        productId
        count
        product {
          ...ProductFields
        }
      }
      result
      results {
        id
        fileUrl
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useGetMyOrdersQuery__
 *
 * To run a query within a React component, call `useGetMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyOrdersQuery, GetMyOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(GetMyOrdersDocument, options);
}
export function useGetMyOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyOrdersQuery, GetMyOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(GetMyOrdersDocument, options);
}
export type GetMyOrdersQueryHookResult = ReturnType<typeof useGetMyOrdersQuery>;
export type GetMyOrdersLazyQueryHookResult = ReturnType<typeof useGetMyOrdersLazyQuery>;
export type GetMyOrdersQueryResult = Apollo.QueryResult<GetMyOrdersQuery, GetMyOrdersQueryVariables>;
export const GetPartnerBasketDocument = gql`
  query GetPartnerBasket($PartnerOrderId: ID!) {
    getPartnerBasket(partnerOrderId: $PartnerOrderId) {
      id
      productId
    }
  }
`;

/**
 * __useGetPartnerBasketQuery__
 *
 * To run a query within a React component, call `useGetPartnerBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerBasketQuery({
 *   variables: {
 *      PartnerOrderId: // value for 'PartnerOrderId'
 *   },
 * });
 */
export function useGetPartnerBasketQuery(
  baseOptions: Apollo.QueryHookOptions<GetPartnerBasketQuery, GetPartnerBasketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerBasketQuery, GetPartnerBasketQueryVariables>(GetPartnerBasketDocument, options);
}
export function useGetPartnerBasketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerBasketQuery, GetPartnerBasketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerBasketQuery, GetPartnerBasketQueryVariables>(GetPartnerBasketDocument, options);
}
export type GetPartnerBasketQueryHookResult = ReturnType<typeof useGetPartnerBasketQuery>;
export type GetPartnerBasketLazyQueryHookResult = ReturnType<typeof useGetPartnerBasketLazyQuery>;
export type GetPartnerBasketQueryResult = Apollo.QueryResult<GetPartnerBasketQuery, GetPartnerBasketQueryVariables>;
export const ProductsDocument = gql`
  query Products($offsetPaging: OffsetPaging) {
    products(offsetPaging: $offsetPaging) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        ...ProductFields
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      offsetPaging: // value for 'offsetPaging'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductsByIdsDocument = gql`
  query ProductsByIds($ids: [String!]!) {
    productsByIds(ids: $ids) {
      ...ProductFields
      subProducts {
        ...ProductFields
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useProductsByIdsQuery__
 *
 * To run a query within a React component, call `useProductsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProductsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<ProductsByIdsQuery, ProductsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsByIdsQuery, ProductsByIdsQueryVariables>(ProductsByIdsDocument, options);
}
export function useProductsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductsByIdsQuery, ProductsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsByIdsQuery, ProductsByIdsQueryVariables>(ProductsByIdsDocument, options);
}
export type ProductsByIdsQueryHookResult = ReturnType<typeof useProductsByIdsQuery>;
export type ProductsByIdsLazyQueryHookResult = ReturnType<typeof useProductsByIdsLazyQuery>;
export type ProductsByIdsQueryResult = Apollo.QueryResult<ProductsByIdsQuery, ProductsByIdsQueryVariables>;
export const MyProfileDocument = gql`
  query MyProfile {
    myProfile {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
}
export function useMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
}
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileQueryResult = Apollo.QueryResult<MyProfileQuery, MyProfileQueryVariables>;
export const GetPromoBannersDocument = gql`
  query GetPromoBanners {
    promoBanners {
      active
      blank
      description
      id
      link
      sortOrder
      title
      images {
        id
        height
        fileId
        entityType
        entityId
        created
        contentType
        position
        size
        src
        updated
        width
      }
    }
  }
`;

/**
 * __useGetPromoBannersQuery__
 *
 * To run a query within a React component, call `useGetPromoBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPromoBannersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPromoBannersQuery, GetPromoBannersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPromoBannersQuery, GetPromoBannersQueryVariables>(GetPromoBannersDocument, options);
}
export function useGetPromoBannersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPromoBannersQuery, GetPromoBannersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPromoBannersQuery, GetPromoBannersQueryVariables>(GetPromoBannersDocument, options);
}
export type GetPromoBannersQueryHookResult = ReturnType<typeof useGetPromoBannersQuery>;
export type GetPromoBannersLazyQueryHookResult = ReturnType<typeof useGetPromoBannersLazyQuery>;
export type GetPromoBannersQueryResult = Apollo.QueryResult<GetPromoBannersQuery, GetPromoBannersQueryVariables>;
export const GetQuestionAnswerDocument = gql`
  query GetQuestionAnswer {
    questionAnswers {
      id
      question
      answer
      sortOrder
    }
  }
`;

/**
 * __useGetQuestionAnswerQuery__
 *
 * To run a query within a React component, call `useGetQuestionAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionAnswerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionAnswerQuery(
  baseOptions?: Apollo.QueryHookOptions<GetQuestionAnswerQuery, GetQuestionAnswerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQuestionAnswerQuery, GetQuestionAnswerQueryVariables>(GetQuestionAnswerDocument, options);
}
export function useGetQuestionAnswerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionAnswerQuery, GetQuestionAnswerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQuestionAnswerQuery, GetQuestionAnswerQueryVariables>(
    GetQuestionAnswerDocument,
    options
  );
}
export type GetQuestionAnswerQueryHookResult = ReturnType<typeof useGetQuestionAnswerQuery>;
export type GetQuestionAnswerLazyQueryHookResult = ReturnType<typeof useGetQuestionAnswerLazyQuery>;
export type GetQuestionAnswerQueryResult = Apollo.QueryResult<GetQuestionAnswerQuery, GetQuestionAnswerQueryVariables>;
export const FindProductsDocument = gql`
  query FindProducts($offsetPaging: OffsetPaging, $search: String, $labels: [ID!]) {
    findProducts(offsetPaging: $offsetPaging, search: $search, labels: $labels) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        ...ProductFields
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useFindProductsQuery__
 *
 * To run a query within a React component, call `useFindProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductsQuery({
 *   variables: {
 *      offsetPaging: // value for 'offsetPaging'
 *      search: // value for 'search'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function useFindProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<FindProductsQuery, FindProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindProductsQuery, FindProductsQueryVariables>(FindProductsDocument, options);
}
export function useFindProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindProductsQuery, FindProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindProductsQuery, FindProductsQueryVariables>(FindProductsDocument, options);
}
export type FindProductsQueryHookResult = ReturnType<typeof useFindProductsQuery>;
export type FindProductsLazyQueryHookResult = ReturnType<typeof useFindProductsLazyQuery>;
export type FindProductsQueryResult = Apollo.QueryResult<FindProductsQuery, FindProductsQueryVariables>;
export const GetSlotsDocument = gql`
  query GetSlots($date: DateTime!) {
    getSlots(date: $date, days: 1, slotsOffsetPaging: { limit: 80, offset: 0 }) {
      nodes {
        id
        date
        label
        type
        slotDayId
        isActive
        isValid
      }
    }
  }
`;

/**
 * __useGetSlotsQuery__
 *
 * To run a query within a React component, call `useGetSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlotsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetSlotsQuery, GetSlotsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSlotsQuery, GetSlotsQueryVariables>(GetSlotsDocument, options);
}
export function useGetSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlotsQuery, GetSlotsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSlotsQuery, GetSlotsQueryVariables>(GetSlotsDocument, options);
}
export type GetSlotsQueryHookResult = ReturnType<typeof useGetSlotsQuery>;
export type GetSlotsLazyQueryHookResult = ReturnType<typeof useGetSlotsLazyQuery>;
export type GetSlotsQueryResult = Apollo.QueryResult<GetSlotsQuery, GetSlotsQueryVariables>;
export const StaticBlocksDocument = gql`
  query StaticBlocks {
    staticBlocks {
      id
      code
      content
      created
      updated
    }
  }
`;

/**
 * __useStaticBlocksQuery__
 *
 * To run a query within a React component, call `useStaticBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticBlocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaticBlocksQuery(
  baseOptions?: Apollo.QueryHookOptions<StaticBlocksQuery, StaticBlocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaticBlocksQuery, StaticBlocksQueryVariables>(StaticBlocksDocument, options);
}
export function useStaticBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StaticBlocksQuery, StaticBlocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaticBlocksQuery, StaticBlocksQueryVariables>(StaticBlocksDocument, options);
}
export type StaticBlocksQueryHookResult = ReturnType<typeof useStaticBlocksQuery>;
export type StaticBlocksLazyQueryHookResult = ReturnType<typeof useStaticBlocksLazyQuery>;
export type StaticBlocksQueryResult = Apollo.QueryResult<StaticBlocksQuery, StaticBlocksQueryVariables>;
export const GetUserFieldsSuggestionsDocument = gql`
  query GetUserFieldsSuggestions($query: String, $parts: [String!]) {
    userFieldsSuggestions(query: $query, parts: $parts)
  }
`;

/**
 * __useGetUserFieldsSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetUserFieldsSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldsSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldsSuggestionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useGetUserFieldsSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserFieldsSuggestionsQuery, GetUserFieldsSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserFieldsSuggestionsQuery, GetUserFieldsSuggestionsQueryVariables>(
    GetUserFieldsSuggestionsDocument,
    options
  );
}
export function useGetUserFieldsSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldsSuggestionsQuery, GetUserFieldsSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserFieldsSuggestionsQuery, GetUserFieldsSuggestionsQueryVariables>(
    GetUserFieldsSuggestionsDocument,
    options
  );
}
export type GetUserFieldsSuggestionsQueryHookResult = ReturnType<typeof useGetUserFieldsSuggestionsQuery>;
export type GetUserFieldsSuggestionsLazyQueryHookResult = ReturnType<typeof useGetUserFieldsSuggestionsLazyQuery>;
export type GetUserFieldsSuggestionsQueryResult = Apollo.QueryResult<
  GetUserFieldsSuggestionsQuery,
  GetUserFieldsSuggestionsQueryVariables
>;
