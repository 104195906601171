import {
  // DialabAnalysis,
  // GemotestAnalysis,
  // LabquestAnalysis,
  DeadlineUnit,
  LaboratoryEnum,
  Product,
} from '@graphql/generated';

interface IGetDeadline {
  laboratory: LaboratoryEnum | null;
  deadline: number | undefined;
}

interface ILaboratories {
  [key: LaboratoryEnum | string]: Object | null;
}

interface ILaboratoriesGroupDeadline {
  labquest: number[];
  gemotest: number[];
  dialab: number[];
}

/**
 * min from max
 * @param product
 * @param current
 * если параметр current присутствует, значит надо вернуть день по выбранной лаборатории,
 * противном случае функция вернет день лаборатории по приоритету.
 * Приоритет 1-Labquest, 2-Gemotest, 3-Dialab
 *
 * если продукт является пакетом, функция возвращает максимальный день(deadline) анализов внутри пакета по приоритету
 * если deadline указан в часах, то округляем значение до одного дня
 */
export const useDeadline = (product: Product, current?: LaboratoryEnum) => {
  let deadline = Infinity;

  if (!product.isPackage) {
    const productDeadline = getDeadline(product, current);

    const deadlines = productDeadline
      .map((item) => item.deadline)
      .filter((it) => it)
      // @ts-ignore
      .sort((aa, bb) => aa - bb);

    // @ts-ignore
    deadline = deadlines[0];
    // deadline = productDeadline?.deadline || 0;
  } else {
    const packageDeadline: ILaboratoriesGroupDeadline | undefined = product.subProducts
      ?.map((item) => getDeadline(item, current))
      .reduce(
        (res, item) => {
          for (const key in item) {
            if (item[key].laboratory === LaboratoryEnum.Dialab) {
              res.dialab.push(item[key].deadline || -1);
            }

            if (item[key].laboratory === LaboratoryEnum.Gemotest) {
              res.gemotest.push(item[key].deadline || -1);
            }

            if (item[key].laboratory === LaboratoryEnum.Labquest) {
              res.labquest.push(item[key].deadline || -1);
            }
          }

          res.labquest = res.labquest.sort((aa, bb) => bb - aa);
          res.gemotest = res.gemotest.sort((aa, bb) => bb - aa);
          res.dialab = res.dialab.sort((aa, bb) => bb - aa);

          return res;
        },
        { labquest: [], gemotest: [], dialab: [] } as { labquest: number[]; gemotest: number[]; dialab: number[] }
      );

    // @ts-ignore
    const { labquest, gemotest, dialab } = packageDeadline;

    // eslint-disable-next-line no-console
    // console.log('packageDeadline', packageDeadline);
    const deadlines = [labquest[0], gemotest[0], dialab[0]].filter((it) => it).sort((aa, bb) => aa - bb);

    deadline = deadlines[0];
  }

  return {
    deadline,
  };
};

const getDeadline = (product: Product, current?: LaboratoryEnum): IGetDeadline[] => {
  let labs: ILaboratories = {
    [LaboratoryEnum.Labquest]:
      product?.labquestAnalysis && !product?.labquestAnalysis.unavailable ? product?.labquestAnalysis || null : null,
    [LaboratoryEnum.Gemotest]:
      product?.gemotestAnalysis && !product?.gemotestAnalysis.unavailable ? product?.gemotestAnalysis || null : null,
    [LaboratoryEnum.Dialab]:
      product?.dialabAnalysis && !product?.dialabAnalysis.unavailable ? product?.dialabAnalysis || null : null,
  };

  if (current) {
    const objToArray = Object.entries(labs);
    const filtered = objToArray.find(([key, _]) => key === current) as Array<any>;
    labs = {
      [filtered[0]]: filtered[1],
    };
  }

  // let labItem: LabquestAnalysis | DialabAnalysis | GemotestAnalysis | null = null;
  // let labType: LaboratoryEnum | null = null;
  let key: LaboratoryEnum | string;

  const labDeadLine: IGetDeadline[] = [];

  for (key in labs) {
    if (labs[key]) {
      labDeadLine.push({
        // @ts-ignore
        deadline: labs[key]?.deadlineUnit === DeadlineUnit.Hour ? 1 : labs[key].deadline,
        // @ts-ignore
        laboratory: key,
      });
    }
  }

  // console.log('labDeadLine', labDeadLine);

  return labDeadLine;
};

// /**
//  * Тотал мин
//  * @param product
//  * @param current
//  * если параметр current присутствует, значит надо вернуть день по выбранной лаборатории,
//  * противном случае функция вернет день лаборатории по приоритету.
//  * Приоритет 1-Labquest, 2-Gemotest, 3-Dialab
//  *
//  * если продукт является пакетом, функция возвращает максимальный день(deadline) анализов внутри пакета по приоритету
//  * если deadline указан в часах, то округляем значение до одного дня
//  */
// export const useDeadline = (product: Product, current?: LaboratoryEnum) => {
//   let deadline = Infinity;
//
//   if (!product.isPackage) {
//     const productDeadline = getDeadline(product, current);
//     deadline = productDeadline?.deadline || 0;
//   } else {
//     const packageDeadline: IGetDeadline[] | undefined = product.subProducts
//       ?.map((item) => getDeadline(item, current))
//       // @ts-ignore
//       .sort((al, bl) => al.deadline - bl.deadline);
//
//     if (packageDeadline) {
//       // @ts-ignore
//       deadline = packageDeadline[0]?.deadline || 0;
//     }
//   }
//
//   return {
//     deadline,
//   };
// };
//
// const getDeadline = (product: Product, current?: LaboratoryEnum): IGetDeadline => {
//   let labs: ILaboratories = {
//     [LaboratoryEnum.Labquest]:
//       product?.labquestAnalysis && !product?.labquestAnalysis.unavailable ? product?.labquestAnalysis || null : null,
//     [LaboratoryEnum.Gemotest]:
//       product?.gemotestAnalysis && !product?.gemotestAnalysis.unavailable ? product?.gemotestAnalysis || null : null,
//     [LaboratoryEnum.Dialab]:
//       product?.dialabAnalysis && !product?.dialabAnalysis.unavailable ? product?.dialabAnalysis || null : null,
//   };
//
//   if (current) {
//     const objToArray = Object.entries(labs);
//     // eslint-disable-next-line @typescript-eslint/no-unused-vars,id-length
//     const filtered = objToArray.find(([key, _]) => key === current) as Array<any>;
//     labs = {
//       [filtered[0]]: filtered[1],
//     };
//   }
//
//   let key: LaboratoryEnum | string;
//   const dedlines: IGetDeadline[] = [];
//   for (key in labs) {
//     if (labs[key]) {
//       // @ts-ignore
//       dedlines.push({
//         // @ts-ignore
//         laboratory: key,
//         // @ts-ignore
//         deadline: labs[key]?.deadlineUnit === DeadlineUnit.Hour ? 1 : labs[key].deadline,
//       });
//     }
//   }
//
//   // @ts-ignore
//   const sortMIn = dedlines.sort((al, bl) => al.deadline - bl.deadline);
//
//   return sortMIn[0];
// };

// /**
//  *
//  * @param product
//  * @param current
//  * если параметр current присутствует, значит надо вернуть день по выбранной лаборатории,
//  * противном случае функция вернет день лаборатории по приоритету.
//  * Приоритет 1-Labquest, 2-Gemotest, 3-Dialab
//  *
//  * если продукт является пакетом, функция возвращает максимальный день(deadline) анализов внутри пакета по приоритету
//  * если deadline указан в часах, то округляем значение до одного дня
//  */
// export const useDeadline = (product: Product, current?: LaboratoryEnum) => {
//   let deadline = Infinity;
//
//   if (!product.isPackage) {
//     const productDeadline = getDeadline(product, current);
//     deadline = productDeadline?.deadline || 0;
//   } else {
//     const packageDeadline: IGetDeadline | undefined = product.subProducts
//       ?.map((item) => getDeadline(item, current))
//       .filter((item) => item.deadline && item.laboratory)
//       // @ts-ignore
//       .reduce((res, item) => (res.deadline > item.deadline ? res : item));
//
//     deadline = packageDeadline?.deadline || 0;
//   }
//
//   return {
//     deadline,
//   };
// };
//
// const getDeadline = (product: Product, current?: LaboratoryEnum): IGetDeadline => {
//   let labs: ILaboratories = {
//     [LaboratoryEnum.Labquest]:
//       product?.labquestAnalysis && !product?.labquestAnalysis.unavailable ? product?.labquestAnalysis || null : null,
//     [LaboratoryEnum.Gemotest]:
//       product?.gemotestAnalysis && !product?.gemotestAnalysis.unavailable ? product?.gemotestAnalysis || null : null,
//     [LaboratoryEnum.Dialab]:
//       product?.dialabAnalysis && !product?.dialabAnalysis.unavailable ? product?.dialabAnalysis || null : null,
//   };
//
//   if (current) {
//     const objToArray = Object.entries(labs);
//     const filtered = objToArray.find(([key, _]) => key === current) as Array<any>;
//     labs = {
//       [filtered[0]]: filtered[1],
//     };
//   }
//
//   let labItem: LabquestAnalysis | DialabAnalysis | GemotestAnalysis | null = null;
//   let labType: LaboratoryEnum | null = null;
//   let key: LaboratoryEnum | string;
//   for (key in labs) {
//     if (labs[key]) {
//       labItem = <LabquestAnalysis | DialabAnalysis | GemotestAnalysis | null>labs[key];
//       labType = key as LaboratoryEnum;
//       break;
//     }
//   }
//
//   let deadline = labItem?.deadline || 0;
//   if (labItem && labItem?.deadlineUnit === DeadlineUnit.Hour) {
//     deadline = 1;
//   }
//
//   return {
//     deadline,
//     laboratory: labType,
//   };
// };
