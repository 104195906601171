import { isPossiblePhoneNumber } from 'react-phone-number-input';

export function isValidPhone(value: string | undefined, allowFifteenDigits: boolean | undefined) {
  return value
    ? (isPossiblePhoneNumber(value) || isPossiblePhoneNumber(value, 'RU')) &&
        insureDigitsAmount(value, allowFifteenDigits)
    : false;
}

function insureDigitsAmount(value: string | undefined, allowFifteenDigits: boolean | undefined) {
  if (!value) {
    return false;
  }

  // notice: phone value includes '+' before country code, so check if length != 16
  return allowFifteenDigits ? true : value.length !== 16;
}
