import { useRef } from 'react';
import Input, { PropsWithoutSmartCaret } from 'react-phone-number-input/input';
import InputCustom from '@components/Input';
import { InputPropsTypes } from '@components/InputWrapper/InputWrapper';
const PhoneInputComponent: typeof Input = (props: PropsWithoutSmartCaret<InputPropsTypes>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // Insures current input position goes after country code
  // that logic might block attempts to change country code so delete if needed
  const insureCursorPosition = () => {
    const cursorPosition = inputRef.current?.selectionStart;
    const lastDigitPosition = inputRef.current!.value.length;
    if (cursorPosition === 0 || cursorPosition === 1) {
      inputRef.current!.selectionStart = lastDigitPosition;
    }
  };
  return (
    <Input
      ref={inputRef}
      country="RU"
      international
      withCountryCallingCode
      // @ts-ignore
      inputComponent={InputCustom}
      {...props}
      onBeforeInput={insureCursorPosition}
    />
  );
};

export default PhoneInputComponent;
