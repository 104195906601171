import React from 'react';

import OtpInput from 'react-otp-input';

import cn from 'classnames';

import { VERIFY_CODE_LENGTH } from '@constants/index';

import s from './OtpInput.module.scss';

export interface PropsTypes {
  className?: string;
  isError?: boolean;
  value?: string;
  onChange?: () => void;
  isDisabled?: boolean;
  hasErrored?: boolean;
  isInputNum?: boolean;
}

const Input = ({ hasErrored = false, isInputNum, className, ...props }: PropsTypes) => (
  <OtpInput
    {...props}
    numInputs={VERIFY_CODE_LENGTH}
    containerStyle={s.root}
    inputStyle={cn(s.input, hasErrored && s.error)}
    focusStyle={s.focusStyle}
    placeholder="000000"
    shouldAutoFocus
    isInputNum={isInputNum}
  />
);

export default Input;
