import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask';

import Input from '@components/Input';
import { isServer } from '@constants/index';
import { InputPropsTypes } from '@components/InputWrapper/InputWrapper';

const InputMask = ({ mask, maskPlaceholder, ...rest }: InputPropsTypes & ReactInputMaskProps) => {
  if (isServer) {
    // @ts-ignore
    return <Input {...rest} />;
  }

  return (
    <ReactInputMask mask={mask} maskPlaceholder={maskPlaceholder} {...rest}>
      <Input />
    </ReactInputMask>
  );
};

export default InputMask;
