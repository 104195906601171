import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import Title from '@components/Title';
import Text from '@components/Text';

import styles from './CategoryCard.module.scss';

interface CardProps {
  title: string;
  description?: string;
  minPrice?: string | number | null | undefined;
  link: string;
  image?: { src: string; id?: string; width?: number; height?: number };
}

const CategoryCard: React.FC<CardProps> = ({ title, minPrice, link, image }) => (
  <article className={styles.root}>
    <Link href={link} passHref>
      <div className={styles.content}>
        <div className={styles.image}>
          {image && <Image alt="alt" src={image.src || '/'} layout="fill" objectFit="cover" />}
        </div>

        <Title level={3} className={styles.title}>
          {title}
        </Title>
        <Text size={'large'} className={styles.price}>
          {`От ${minPrice} Руб`}
        </Text>
      </div>
    </Link>
  </article>
);

export default CategoryCard;
