import { ReactElement, useState } from 'react';
import cn from 'classnames';

import TabTitle from './TabTitle';

import s from './Tabs.module.scss';

type Props = {
  children: ReactElement[] | null;
  className?: string;
  isFullWidth?: boolean;
  theme?: 'primary' | 'secondary';
};

const Tabs: React.FC<Props> = ({ children = null, className, isFullWidth }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className={cn(s.root, className)}>
      <ul className={cn(s.list, isFullWidth && s.full)}>
        {children
          ? children.map((item, index) => (
              <TabTitle
                key={index}
                active={selectedTab === index}
                title={item.props.title}
                index={index}
                setSelectedTab={setSelectedTab}
                isFullWidth={isFullWidth}
                // theme={theme}
              />
            ))
          : null}
      </ul>
      {children ? children[selectedTab] : null}
    </div>
  );
};

export default Tabs;
