import { ApolloError } from '@apollo/client';

export function getApolloErrorMessage(error: unknown) {
  if (error instanceof ApolloError) {
    // eslint-disable-next-line no-console
    console.log({ error });
    if (error.networkError) {
      return 'Ошибка. Проверьте соединение с интернетом';
    } else {
      return error.message;
    }
  }

  return null;
}
