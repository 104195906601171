import { gql } from '@apollo/client';

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFields on Product {
    id
    title
    description
    price
    deadline
    isPackage
    descriptionShort
    biomaterial
    preparation
    contraindications
    gemotestCode
    dialabCode
    labquestCode
    code
    disabled
    unavailable
    gemotestAnalysis {
      unavailable
      created
      id
      updated
      deadline
      deadlineUnit
    }
    dialabAnalysis {
      unavailable
      created
      id
      updated
      deadline
      deadlineUnit
    }
    labquestAnalysis {
      unavailable
      created
      id
      updated
      deadline
      deadlineUnit
    }
    labels {
      id
      title
      isBanner
      forMainPage
      created
      updated
      hidden
    }
    hidden
    subProducts {
      id
      title
      description
      price
      deadline
      isPackage
      descriptionShort
      biomaterial
      preparation
      contraindications
      gemotestCode
      dialabCode
      labquestCode
      code
      disabled
      unavailable
      gemotestAnalysis {
        unavailable
        created
        id
        updated
        deadline
        deadlineUnit
      }
      dialabAnalysis {
        unavailable
        created
        id
        updated
        deadline
        deadlineUnit
      }
      labquestAnalysis {
        unavailable
        created
        id
        updated
        deadline
        deadlineUnit
      }
      labels {
        id
        title
        isBanner
        forMainPage
        created
        updated
        hidden
      }
      hidden
    }
  }
`;
