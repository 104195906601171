import React from 'react';

import cn from 'classnames';

import { CONTACTS_EMAIL } from '@constants/index';
import Text from '@components/Text';

import s from './MailLink.module.scss';

interface Props {
  className?: string;
}

const MailLink: React.FC<Props> = ({ className }) => {
  const classes = cn(s.root, className);

  return (
    <div className={s.wrapper}>
      <a className={classes} href={`mailto:${CONTACTS_EMAIL}`} target="_blank" rel="noopener noreferrer">
        <Text className={s.text} size={'large'} inline fontgraphik>
          {CONTACTS_EMAIL}
        </Text>
      </a>
    </div>
  );
};

export default MailLink;
