import { add, setHours } from 'date-fns';

export const TODAY = new Date();

export const TODAY_RESETED_TIME = setHours(TODAY, 0);

export const TOMMOROW = add(TODAY, {
  days: 1,
});

export const YESTERDAY = add(TODAY, {
  days: -1,
});

export const THIRTY_DAYS = add(TODAY, {
  days: 30,
});

export const DAY_AFTER_TOMORROW = add(TODAY, {
  days: 2,
});
