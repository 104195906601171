import * as yup from 'yup';

import { getHours } from 'date-fns';

import { transformNaNToUndefined } from '@utils/transformNaNToUndefined';

export const stepAddressFormSchema = yup
  .object({
    address: yup.string().trim().required('Обязательное поле'),
    entrance: yup.number().positive().integer().nullable().transform(transformNaNToUndefined),
    floor: yup.number().positive().integer().nullable().transform(transformNaNToUndefined),
    apartment: yup.number().positive().integer().nullable().transform(transformNaNToUndefined),
    comment: yup.string().trim().nullable(),
    date: yup
      .date()
      .test('Время', 'Укажите время', (value) => (value ? getHours(value) !== 0 : false))
      .required('Обязательное поле')
      .typeError('Обязательное поле'),
    slotId: yup.string(),
  })
  .required();

export type StepAddressFormValues = yup.InferType<typeof stepAddressFormSchema>;
