import { useQueryGetMainLabelsQuery } from '@graphql/generated';

const useMainLabels = () => {
  const { data } = useQueryGetMainLabelsQuery();

  return {
    mainLabels: data?.getLabels || [],

    mainLabelsForSearch: [...(data?.getLabels || [])],
  };
};

export default useMainLabels;
