import { MinimalBasketProps } from '@hooks/useBasket';
import { MinimalProductProps } from '@hooks/useProducts';

export const getIsInBasketProducts = <T extends MinimalProductProps>(
  products: Array<T>,
  basket?: MinimalBasketProps
) => {
  const productsCheckedInBasket = products.map((product) => {
    const isInBasket = basket ? basket.products.findIndex((item) => item.product.id === product.id) > -1 : false;

    return {
      ...product,
      isInBasket,
    };
  });

  return productsCheckedInBasket;
};
