import { AnalysisLabels, Checkbox, Text, Button } from '@components/index';
import { Product } from '@graphql/generated';

import { CheckedIcon, ShoppingCartIcon } from '@components/Icon';
import styles from './Suggestion.module.scss';
import cn from 'classnames';
import { useMemo } from 'react';
import { labIsAvailable } from '@utils/isValidLabs';
import { formatPrice } from '@utils/formatPrice';
import Link from 'next/link';

interface Props extends Partial<Product> {
  isSelected?: boolean;
  product: Product;
  noCheckbox?: Boolean;
  onSuggestionClick: () => void;
}

const Suggestion: React.FC<Props> = ({
  product,
  code,
  title,
  biomaterial,
  deadline,
  price,
  subProducts,
  isSelected,
  noCheckbox,
  onSuggestionClick,
}) => {
  const labs = useMemo(() => labIsAvailable(product), [product]);

  const formatedPrice = formatPrice(price || 0);

  const handleClickCheckbox = (event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onSuggestionClick();
  };

  return (
    <div className={styles.root}>
      {!noCheckbox && <Checkbox className={styles.checkBox} checked={isSelected} onClick={handleClickCheckbox} />}
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={cn(styles.row, styles.spaceBetween, styles.alignCenter)}>
            <Link href={`/catalog/${product.id}`} passHref>
              <span>
                <Text inline>{title}</Text>
              </span>
            </Link>
            {!noCheckbox ? (
              <Text inline>{price}₽</Text>
            ) : (
              <>
                {isSelected ? (
                  <Button
                    theme="secondary"
                    onClick={onSuggestionClick}
                    size="large"
                    className={cn(styles.iconButton, styles.booked)}
                    icon={<CheckedIcon />}
                  />
                ) : (
                  <Button
                    theme="secondary"
                    size="large"
                    onClick={onSuggestionClick}
                    className={styles.iconButton}
                    icon={<ShoppingCartIcon />}
                  >
                    {formatedPrice}
                  </Button>
                )}
              </>
            )}
          </div>

          <Link href={`/catalog/${product.id}`} passHref>
            <div>
              <AnalysisLabels
                code={code}
                subProductsNumber={subProducts?.length}
                deadline={deadline}
                biomaterial={biomaterial}
                isPackage={product?.isPackage}
                subProducts={product?.subProducts}
                textSize="extra-small"
                labs={labs}
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Suggestion;
