import Button from '@components/Button';

import styles from './StepsFooter.module.scss';

interface Props {
  showBackButton?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  onBackClick?: () => void;
  onSubmitClick?: () => void;
}

const StepsFooter: React.FC<Props> = ({
  showBackButton,
  isLoading,
  disabled,
  onBackClick = () => {},
  onSubmitClick,
}) => (
  <section className={styles.root}>
    <Button
      type={!onSubmitClick ? 'submit' : 'button'}
      fullWidth
      size="large"
      loading={isLoading}
      disabled={disabled}
      onClick={onSubmitClick}
    >
      Продолжить
    </Button>
    {showBackButton && (
      <Button size="large" theme="secondary" fullWidth onClick={onBackClick}>
        Назад
      </Button>
    )}
  </section>
);

export default StepsFooter;
