import React from 'react';
import { CategoryTable } from '@components/index';

const PopularAnalysis = () => (
  <div>
    <CategoryTable tableCategoryId={0} />
  </div>
);

export default PopularAnalysis;
