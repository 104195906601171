import React from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { Button, Logo } from '@components/index';
import { CrossIcon } from '@components/Icon';

import styles from './HeaderSmall.module.scss';

const HeaderSmall: React.FC<{ className?: string }> = ({ className }) => {
  const router = useRouter();

  const handleCloseClick = () => router.push('/');

  return (
    <header className={cn(styles.root, className)}>
      <nav className={cn(styles.navBar, 'container-small')}>
        <Logo isWithTitle={true} />
        <Button theme="ghost" onClick={handleCloseClick} className={styles.closeButton}>
          <CrossIcon />
        </Button>
      </nav>
    </header>
  );
};

export default HeaderSmall;
