import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Script from 'next/script';
import classNames from 'classnames';
import { Slots } from '@graphql/generated';
import { YANDEX_MAPS_SCRIPT_URL } from '@constants/index';
import { stepAddressFormSchema, StepAddressFormValues } from '@modules/checkout/stepAddressFormSchema';
import { useCheckoutWizard } from '@modules/checkout/CheckoutWizardContext';
import { useAddress } from '@modules/profile/hooks/useAddress';
import { useGuestProfile } from '@modules/profile/GuestProfileContext';
import { useProfile } from '@modules/profile/hooks/useProfile';
import { renderAddressSuggestionMap } from '@lib/ymaps';
import AddressAutocompleteInput from '@modules/profile/components/AddressAutocompleteInput';

import Input from '@components/Input';
import TextArea from '@components/TextArea';
import Text from '@components/Text';

import StepHeader from '@modules/checkout/components/StepHeader';
import InputError from '@modules/checkout/components/InputError';
import DateChooser from '@modules/checkout/components/DateChooser';

import { ADDRESS_TIME, handleSendAnalytics } from '@constants/yandexMetrikaEvents';

import s from '@modules/checkout/components/CheckoutWizard/CheckoutWizard.module.scss';

import StepsFooter from '../StepsFooter/StepsFooter';

const ADDRESS_MAP_ELEMENT_ID = 'address-map';

const StepAddress = () => {
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(null);

  const { isUnauthorized } = useProfile();
  const { onAddressChange: onGuestAddressChange } = useGuestProfile();
  const { activeStepIndex, formValues, handleStepSubmit, goToNextStep } = useCheckoutWizard();
  const { mainAddress, addresses, onAddAddress } = useAddress();

  const {
    register,
    control,
    reset,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<StepAddressFormValues>({
    defaultValues: { ...formValues },
    resolver: yupResolver(stepAddressFormSchema),
  });

  const handleAddressMapChanged = useCallback(
    (value: string) => {
      setValue('address', value, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
    },
    [setValue]
  );

  const initYmaps = useCallback(() => {
    ymaps.ready(() => renderAddressSuggestionMap(ADDRESS_MAP_ELEMENT_ID, handleAddressMapChanged, coordinates));
  }, [handleAddressMapChanged, coordinates]);

  useEffect(() => {
    const map = document.getElementById(ADDRESS_MAP_ELEMENT_ID);
    if (map) {
      map.innerHTML = '';
    }
    if (typeof ymaps !== 'undefined') {
      initYmaps();
    }
  }, [initYmaps, handleAddressMapChanged, coordinates]);

  useEffect(() => {
    if (mainAddress) {
      reset({
        address: mainAddress.address ?? undefined,
        entrance: mainAddress.entrance ?? undefined,
        floor: mainAddress.floor ?? undefined,
        apartment: mainAddress.apartment ?? undefined,
      });
    }
  }, [mainAddress, reset]);

  const handleFormSubmit = async (values: StepAddressFormValues) => {
    try {
      if (loading) {
        return;
      }

      setLoading(true);

      handleStepSubmit(values);

      if (isUnauthorized) {
        const { address, floor, entrance, apartment, comment } = values;
        onGuestAddressChange({ address, floor, entrance, apartment, comment });
      } else {
        const { address, floor, entrance, apartment, comment } = values;

        const indexOfAddress = addresses?.myAddresses.findIndex(
          (item: any) => item.address === address && item.floor === floor
        );

        if (indexOfAddress === -1) {
          await onAddAddress({ address, floor, entrance, apartment, comment });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
    } finally {
      setLoading(false);

      goToNextStep();

      handleSendAnalytics(ADDRESS_TIME);
    }
  };

  return (
    <>
      <Script src={YANDEX_MAPS_SCRIPT_URL} strategy="beforeInteractive" onLoad={initYmaps} />

      <form onSubmit={handleSubmit(handleFormSubmit)} id="form-address">
        <section className={s.partWrapper}>
          <StepHeader title="Адрес" notice="Адрес необходим для назначения выезда" />
          <div className={s.inputRow}>
            <Controller
              control={control}
              name="address"
              render={({ field: { name, value, onChange } }) => (
                <AddressAutocompleteInput
                  value={value}
                  onChange={(addressValue) => {
                    onChange(addressValue);
                    setValue(name, addressValue || '', { shouldValidate: true });
                  }}
                  setCoordinates={setCoordinates}
                  label="Введите ваш адрес"
                  isError={Boolean(errors.address)}
                  inputClassName={s.addressInput}
                  suggestionsClassName={s.addressSuggestions}
                />
              )}
            />

            {errors.address?.message ? <InputError message={errors.address.message} /> : null}

            <Text className={s.mapTitle} size="large" muted>
              Или укажите на карте
            </Text>
            <div id={ADDRESS_MAP_ELEMENT_ID} className={s.map}></div>
          </div>

          <div className={classNames(s.inputRow, s.inputOneThirdRow)}>
            <Input {...register('entrance')} label="Подьезд" />

            <Input {...register('floor')} label="Этаж" />

            <Input {...register('apartment')} label="Квартира" />
          </div>

          <div className={s.inputRow}>
            <TextArea {...register('comment')} label="Комментарий к заказу" />
          </div>
        </section>

        <section className={classNames(s.partWrapper, s.last)}>
          <StepHeader title="Дата и время" notice="Выберите наиболее удобные для себя дату и время" />
          <div className={s.inputRow}>
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DateChooser
                  placeholderText="Дата и время"
                  value={field.value}
                  onChange={(value: Date, slotId?: Slots['id']) => {
                    field.onChange(value);

                    if (slotId) {
                      setValue('slotId', slotId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                    }
                  }}
                  slotId={formValues.slotId}
                  isError={Boolean(errors.date)}
                />
              )}
            />
            {errors.date?.message || errors.slotId?.message ? (
              <InputError message={errors.date?.message || errors.slotId?.message || ''} />
            ) : null}
          </div>
        </section>

        <StepsFooter showBackButton={activeStepIndex !== 0} isLoading={loading} disabled={loading || !isValid} />
      </form>
    </>
  );
};

export default StepAddress;
