import { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { isServer } from '@constants/index';
import useOnClickOutside from '@hooks/useOnClickOutside';

import styles from './Overlay.module.scss';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
  lockScroll?: boolean;
}

const Overlay: React.FC<Props> = ({ isOpen, children, onClose, lockScroll = true }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const mobileElementHeight = isServer ? 0 : window.innerHeight * 0.01;
  rootRef.current?.style.setProperty('--vh', `${mobileElementHeight}px`);

  useEffect(() => {
    if (isOpen && lockScroll) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isOpen, lockScroll]);

  useEffect(() => {
    const handleResize = () => {
      const updatedMobileHeight = window.innerHeight * 0.01;
      rootRef.current?.style.setProperty('--vh', `${updatedMobileHeight}px`);
    };

    if (isOpen) {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isOpen]);

  useOnClickOutside(contentRef, !isServer ? onClose : () => null);

  if (isServer) {
    return null;
  }

  const Component = (
    <div ref={rootRef} className={classNames(styles.root, isOpen && styles.open)}>
      <div ref={contentRef} className={styles.content}>
        {children}
      </div>
    </div>
  );

  return ReactDOM.createPortal(Component, document.querySelector('#__next') || document.body);
};

export default Overlay;
