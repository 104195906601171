import { useMemo } from 'react';
import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';

import Cookies from 'js-cookie';

import { DEFAULT_API_URI, TOKEN_STORAGE_KEY } from '@constants/index';
import { offsetLimitPagination } from '@utils/offsetLimitPagination';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient(serverToken: string) {
  let token = serverToken;

  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_APOLLO_SERVER_URI || DEFAULT_API_URI,
    credentials: 'same-origin',
  });

  const authLink = setContext((__, { headers }) => {
    if (typeof window !== 'undefined') {
      token = Cookies.get(TOKEN_STORAGE_KEY) || '';
    }

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            findProducts: {
              ...offsetLimitPagination(['search', 'labels']),
            },
            products: {
              ...offsetLimitPagination(),
            },
          },
        },
      },
    }),
    connectToDevTools: process.env.NODE_ENV === 'development',
  });
}

export function initializeApollo({ token = '', initialState = null }: { token?: string; initialState?: any }) {
  const getApolloClient = apolloClient ?? createApolloClient(token);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = getApolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((destination) => sourceArray.every((source) => !isEqual(destination, source))),
      ],
    });

    // Restore the cache with the merged data
    getApolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return getApolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = getApolloClient;

  return getApolloClient;
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo({ initialState: state }), [state]);
  return store;
}
