import { useFillBasketDataMutation } from '@graphql/generated';
import { GET_MY_BASKET } from '@graphql/queries/basket';
import { GET_PROFILE } from '@graphql/queries/profile';

export function useFillProfileDataFromGuest() {
  const fillDataMutation = useFillBasketDataMutation({
    awaitRefetchQueries: true,
    refetchQueries: [GET_MY_BASKET, GET_PROFILE],
  });

  return fillDataMutation;
}
