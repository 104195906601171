import { LaboratoryEnum } from '@graphql/generated';

const getLabNameByEnumValue = (lab: LaboratoryEnum | null): string => {
  switch (lab) {
    case LaboratoryEnum.Gemotest:
      return 'Гемотест';
    case LaboratoryEnum.Dialab:
      return 'Диалаб';
    case LaboratoryEnum.Labquest:
      return 'Лабквест';
    default:
      return '';
  }
};

export default getLabNameByEnumValue;
