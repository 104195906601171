import classNames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';

import styles from './LogoFooter.module.scss';

interface LogoProps {
  className?: string;
}

const LogoFooter = ({ className }: LogoProps) => (
  <Link href="/">
    <a className={classNames(styles.root, className)}>
      <Image alt="logo" src={'/images/LogoFooter.svg'} layout="fill" color="#fff" />
    </a>
  </Link>
);

export default LogoFooter;
