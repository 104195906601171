import React, { useRef, useState } from 'react';
import { useCombobox } from 'downshift';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import { Button, Divider, Text, Input, Loader, SuggestionsMenu } from '@components/index';
import { SearchIcon, ArrowRightSmallIcon, NewCrossSmallIcon } from '@components/Icon';
import { Product } from '@graphql/generated';

import Suggestion from './Suggestion/Suggestion';
import styles from './SearchBox.module.scss';
import { useDeadline } from '@hooks/useDeadline';

interface SearchBoxProps {
  className?: string;
  suggestionsMenuClass?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  selectedItemsNumber?: number;
  suggestions?: (Product & { isInBasket?: boolean })[];
  onSuggestionClick?: (id: string | undefined, isSelected: boolean) => void;
  onRemoveSelectedItems?: () => void;
  isLoading?: boolean;
  showAllResearchesBtn?: boolean;
}

const itemToString = (item: Partial<Product> | null) => item?.title ?? '';

const SearchBox: React.FC<SearchBoxProps> = ({
  className,
  suggestionsMenuClass,
  value = '',
  placeholder = 'Выберите исследования',
  onChange,
  suggestions = [],
  isLoading,
  selectedItemsNumber = 0,
  onSuggestionClick = () => {},
  onRemoveSelectedItems = () => {},
  showAllResearchesBtn,
}) => {
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { push } = useRouter();

  // TODO SZ: replace any
  const stateReducer = (_state: any, actionAndChanges: { changes: any; type: any }) => {
    const { changes, type } = actionAndChanges;
    const { InputKeyDownEnter, ItemClick, InputBlur } = useCombobox.stateChangeTypes;

    if (type === InputKeyDownEnter || type === ItemClick) {
      return {
        ...changes,
        isOpen: true,
      };
    }

    if (type === InputBlur) {
      onChange('');
    }

    return changes;
  };

  const { isOpen, getMenuProps, getInputProps, getComboboxProps, getItemProps } = useCombobox({
    items: suggestions,
    itemToString,
    stateReducer,
  });

  const handleChangeSearchInput = (elem: React.ChangeEvent<HTMLInputElement>) => {
    onChange(elem.target.value);
  };

  const handleInputFocus = () => {
    setInputIsFocused(true);
  };

  const handleInputBlur = () => {
    setInputIsFocused(false);
  };

  const handleRemoveSelectedItems = () => {
    onChange('');
    onRemoveSelectedItems();
  };

  const suggestionView = (item: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { deadline } = useDeadline(item);
    return (
      <Suggestion
        code={item?.code}
        title={item?.title}
        biomaterial={item?.biomaterial}
        deadline={deadline}
        price={item?.price}
        isSelected={item.isInBasket}
        product={item}
        onSuggestionClick={() => onSuggestionClick(item.id, Boolean(item.isInBasket))}
      />
    );
  };

  return (
    <>
      <div className={classNames(styles.search, className)} {...getComboboxProps()}>
        <SearchIcon />

        {selectedItemsNumber !== 0 && (
          <Text className={styles.selectedAmount} inline muted={inputIsFocused} size="large">
            Всего выбрано: {selectedItemsNumber}
          </Text>
        )}

        <Input
          className={styles.searchInput}
          {...getInputProps({
            ref: inputRef,
            defaultValue: value,
            onChange: handleChangeSearchInput,
            value,
            onFocus: handleInputFocus,
            onBlur: handleInputBlur,
            placeholder: inputIsFocused || selectedItemsNumber !== 0 ? '' : placeholder,
          })}
        />

        {Boolean(selectedItemsNumber) && (
          <Button theme="ghost" className={styles.clearBtn} onClick={handleRemoveSelectedItems}>
            <NewCrossSmallIcon />
          </Button>
        )}
      </div>

      <SuggestionsMenu
        isOpen={isOpen}
        className={classNames(styles.list, isOpen && styles.active, suggestionsMenuClass)}
        {...getMenuProps()}
      >
        {!isLoading ? (
          <>
            {suggestions.map((item, index) => (
              <li
                key={`${item}${index}`}
                className={styles.option}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                {suggestionView(item)}
                {/*<Suggestion*/}
                {/*  code={item?.code}*/}
                {/*  title={item?.title}*/}
                {/*  biomaterial={item?.biomaterial}*/}
                {/*  deadline={item?.deadline}*/}
                {/*  price={item?.price}*/}
                {/*  isSelected={item.isInBasket}*/}
                {/*  product={item}*/}
                {/*  onSuggestionClick={() => onSuggestionClick(item.id, Boolean(item.isInBasket))}*/}
                {/*/>*/}
                {index + 1 !== suggestions.length && <Divider className={styles.divider} />}
              </li>
            ))}
            {showAllResearchesBtn && (
              <>
                {suggestions.length !== 0 && <Divider className={styles.divider} />}
                <li
                  {...getItemProps({
                    index: suggestions.length,
                    // @ts-ignore
                    item: {},
                    onClick: () => push('/search'),
                  })}
                >
                  <Button className={styles.allResearches} theme="ghost">
                    Все исследования <ArrowRightSmallIcon />
                  </Button>
                </li>
              </>
            )}
          </>
        ) : (
          <Loader variant="logo" className={styles.loader} />
        )}
      </SuggestionsMenu>
    </>
  );
};

export default SearchBox;
