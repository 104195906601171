import { useCallback, useMemo } from 'react';

import {
  AddAddressInput,
  Address,
  EditAddressInput,
  useAddAddressMutation,
  useEditAddressMutation,
  useMyAddressesQuery,
  useRemoveAddressMutation,
} from '@graphql/generated';
import { ADDRESS_FRAGMENT } from '@graphql/fragments/addresses';
import { GET_MY_ADDRESSES } from '@graphql/queries/adresses';
import { removeEmpty } from '@utils/removeEmptyValuesFromObject';
import { useProfile } from '@modules/profile/hooks/useProfile';
import { useGuestProfile } from '@modules/profile/GuestProfileContext';

const DEFAULT_EMPTY_ADDRESS: Partial<Address> = {
  name: null,
  address: '',
  city: null,
  country: null,
  entrance: null,
  floor: null,
  apartment: null,
  comment: null,
};

export function useAddress() {
  const { isUnauthorized } = useProfile();
  const { address: guestAddress } = useGuestProfile();
  const { data: addresses, loading } = useMyAddressesQuery();
  const [addAddressMutation] = useAddAddressMutation();
  const [editAddressMutation] = useEditAddressMutation();
  const [removeAddressMutation] = useRemoveAddressMutation();

  const mainAddress = useMemo(
    () =>
      isUnauthorized
        ? guestAddress
        : addresses?.myAddresses.find((address) => address.isPrimary) ||
          addresses?.myAddresses[addresses?.myAddresses.length - 1],
    [isUnauthorized, guestAddress, addresses?.myAddresses]
  );

  const onAddAddress = useCallback(
    async (values: AddAddressInput) => {
      await addAddressMutation({
        variables: {
          input: removeEmpty(values),
        },
        refetchQueries: [GET_MY_ADDRESSES],
        optimisticResponse: ({ input }) => ({
          addAddress: {
            __typename: 'Address',
            id: input.address + input.apartment + input.floor,
            ...DEFAULT_EMPTY_ADDRESS,
            ...input,
            isPrimary: false,
          },
        }),
        update: (cache, { data }) => {
          const newAddress = data?.addAddress;

          cache.modify({
            fields: {
              myAddresses(existingAddresses = []) {
                const newAddressRef = cache.writeFragment({
                  data: newAddress,
                  fragment: ADDRESS_FRAGMENT,
                });
                return [...existingAddresses, newAddressRef];
              },
            },
          });
        },
      });
    },
    [addAddressMutation]
  );

  const onEditAddress = useCallback(
    async (addressId: Address['id'], values: EditAddressInput) => {
      await editAddressMutation({
        variables: {
          input: removeEmpty(values),
          addressId: addressId,
        },
        refetchQueries: [GET_MY_ADDRESSES],
        optimisticResponse: ({ input }) => {
          const address = addresses?.myAddresses.find((item) => item.id === addressId);

          return {
            editAddress: {
              __typename: 'Address',
              ...address,
              ...input,
              id: addressId,
              address: input.address || address?.address || '',
              isPrimary: address?.isPrimary || false,
            },
          };
        },
        update: (cache, { data }) => {
          const address = data?.editAddress;

          cache.writeFragment({
            id: address?.id,
            data: address,
            fragment: ADDRESS_FRAGMENT,
          });
        },
      });
    },
    [editAddressMutation, addresses?.myAddresses]
  );

  const onRemoveAddress = useCallback(
    async (addressId: Address['id']) => {
      await removeAddressMutation({
        variables: {
          addressId: addressId,
        },
        refetchQueries: [GET_MY_ADDRESSES],
      });
    },
    [removeAddressMutation]
  );

  return { addresses, mainAddress, onAddAddress, onEditAddress, onRemoveAddress, loading };
}
