import { useState } from 'react';

import cn from 'classnames';

import Link from 'next/link';

import Text from '@components/Text';
import Title from '@components/Title';

import { DropdownArrowIcon } from '@components/Icon';

import s from './FooterNavItem.module.scss';

interface Props {
  className?: string;
  title?: string;
  links?: Array<{ id: string; title: string; href: string; newTab?: boolean }>;
}

const FooterNavItem: React.FC<Props> = ({ className, title, links = [] }) => {
  const classes = cn(s.root, className);

  const [dropMenu, setDropMenu] = useState(false);

  const handleToggleDropMenu = () => {
    setDropMenu((prevState) => !prevState);
  };

  return (
    <div className={classes}>
      <div className={cn(s.header, dropMenu && s.active)} onClick={handleToggleDropMenu}>
        <Title level={4} className={s.navigationTitle}>
          {title}
        </Title>

        <span className={s.arrow}>
          <DropdownArrowIcon />
        </span>
      </div>

      <ul className={s.navigationList}>
        {links.map(({ id, title: linkTitle, href, newTab }) => {
          let additionalProps = {};

          if (newTab === true) {
            additionalProps = { target: '_blank', rel: 'noreferrer' };
          }

          return (
            <li key={id} className={s.navigationListItem}>
              <Link href={href}>
                <a className={s.navigationLink} {...additionalProps}>
                  <Text className={s.text} size={'large'} inline fontgraphik>
                    {linkTitle}
                  </Text>
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FooterNavItem;
