import React, { useRef, useEffect } from 'react';
import { useCombobox } from 'downshift';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import { Button, Divider, Input, Loader, SuggestionsMenu } from '@components/index';
import { ArrowRightSmallIcon, SearchWithBgIcon, SearchIcon } from '@components/Icon';
import { Product } from '@graphql/generated';

import Suggestion from '@components/SearchBox/Suggestion/Suggestion';
import styles from './SearchInput.module.scss';
import { useDeadline } from '@hooks/useDeadline';

interface SearchBoxProps {
  className?: string;
  suggestionsMenuClass?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  suggestions?: (Product & { isInBasket?: boolean })[];
  onSuggestionClick?: (id: string | undefined, isSelected: boolean) => void;
  isLoading?: boolean;
  showAllResearchesBtn?: boolean;
}

const itemToString = (item: Partial<Product> | null) => item?.title ?? '';

const SearchBox: React.FC<SearchBoxProps> = ({
  className,
  suggestionsMenuClass,
  value = '',
  placeholder = 'Поиск исследований',
  onChange,
  suggestions = [],
  isLoading,
  onSuggestionClick = () => {},
  showAllResearchesBtn,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();

  // TODO SZ: replace any
  const stateReducer = (_state: any, actionAndChanges: { changes: any; type: any }) => {
    const { changes, type } = actionAndChanges;
    const { InputKeyDownEnter, ItemClick, InputBlur } = useCombobox.stateChangeTypes;

    if (type === InputKeyDownEnter || type === ItemClick) {
      return {
        ...changes,
        isOpen: true,
      };
    }

    if (type === InputBlur) {
      onChange('');
    }

    return changes;
  };

  const { isOpen, getMenuProps, getInputProps, getComboboxProps, getItemProps } = useCombobox({
    items: suggestions,
    itemToString,
    stateReducer,
  });

  useEffect(() => {
    if (inputRef.current) inputRef.current.blur();
  }, [router]);

  const handleChangeSearchInput = (elem: React.ChangeEvent<HTMLInputElement>) => {
    onChange(elem.target.value);
  };

  const suggestionView = (item: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { deadline } = useDeadline(item);
    return (
      <Suggestion
        code={item?.code}
        title={item?.title}
        biomaterial={item?.biomaterial}
        deadline={deadline}
        price={item?.price}
        isSelected={item.isInBasket}
        product={item}
        onSuggestionClick={() => onSuggestionClick(item.id, Boolean(item.isInBasket))}
        noCheckbox
      />
    );
  };

  return (
    <div className={styles.root}>
      <div className={classNames(styles.search, className)} {...getComboboxProps()}>
        <Input
          className={styles.searchInput}
          {...getInputProps({
            ref: inputRef,
            defaultValue: value,
            onChange: handleChangeSearchInput,
            value,
            placeholder,
          })}
        />

        <span className={classNames(styles.searchIcon, styles.iconDesk)}>
          <SearchWithBgIcon />
        </span>
        <span className={classNames(styles.searchIcon, styles.iconMob)}>
          <SearchIcon />
        </span>
      </div>

      <SuggestionsMenu
        isOpen={isOpen}
        className={classNames(styles.list, isOpen && styles.active, suggestionsMenuClass)}
        {...getMenuProps()}
      >
        {!isLoading ? (
          <>
            {suggestions.map((item, index) => (
              <li
                key={`${item}${index}`}
                className={styles.option}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                {suggestionView(item)}
                {index + 1 !== suggestions.length && <Divider className={styles.divider} />}
              </li>
            ))}
            {showAllResearchesBtn && (
              <>
                {suggestions.length !== 0 && <Divider className={styles.divider} />}
                <li
                  {...getItemProps({
                    index: suggestions.length,
                    // @ts-ignore
                    item: {},
                    onClick: () => router.push('/search'),
                  })}
                >
                  <Button className={styles.allResearches} theme="ghost">
                    Все исследования <ArrowRightSmallIcon />
                  </Button>
                </li>
              </>
            )}
          </>
        ) : (
          <Loader variant="logo" className={styles.loader} />
        )}
      </SuggestionsMenu>
    </div>
  );
};

export default SearchBox;
