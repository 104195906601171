import React from 'react';

import Link from 'next/link';

import cn from 'classnames';

import { User } from '@graphql/generated';

import s from './ProfileName.module.scss';

interface ProfileNameProps {
  profile: Partial<User>;
  screenWidth: number;
}

const ProfileName: React.FC<ProfileNameProps> = ({ profile }) => (
  <Link href={'/profile'} passHref>
    {/*TODO not to remove, shows full name or short depends on screen resolution*/}
    {/*{screenWidth >= 1600 ? (*/}
    {/*  <a className={s.linkText}>*/}
    {/*    {profile?.nameLast} {profile?.nameFirst ? `${profile?.nameFirst.charAt(0)}.` : null}*/}
    {/*  </a>*/}
    {/*) : (*/}
    {/*  <a className={cn(s.linkText, s.shortName)}>*/}
    {/*    {profile?.nameLast ? profile?.nameLast.charAt(0) : null}*/}
    {/*    {profile?.nameFirst ? profile?.nameFirst.charAt(0) : null}*/}
    {/*  </a>*/}
    {/*)}*/}
    <a className={cn(s.linkText, s.shortName)}>
      {profile?.nameLast ? profile?.nameLast.charAt(0) : null}
      {profile?.nameFirst ? profile?.nameFirst.charAt(0) : null}
    </a>
  </Link>
);

export default ProfileName;
