import { ReactNode } from 'react';
import classNames from 'classnames';

import Button from '@components/Button';
import styles from './BottomBar.module.scss';

interface Props {
  className?: string;
  contentClassName?: string;
  isOpen?: boolean;
  children: ReactNode;
  onClose: () => void;
}

const BottomBar: React.FC<Props> = ({ className, contentClassName, isOpen = true, children, onClose }) => (
  <div className={classNames(styles.root, className, isOpen && styles.open)}>
    {/* TODO SZ: handle component drag using onPointerMove event */}
    <Button className={styles.closeButton} theme="ghost" size="small" onClick={onClose} />
    <div className={classNames(styles.content, contentClassName)}>{children}</div>
  </div>
);

export default BottomBar;
