import { UserWhiteIcon, BusketWhiteIcon } from '@components/Icon';

export const headerLinks = [
  {
    id: 'generalHealth',
    title: 'Общее здоровье',
    href: '/general-health',
  },
  {
    id: 'women',
    title: 'Женщинам',
    href: '/women',
  },
  {
    id: 'ment',
    title: 'Мужчинам',
    href: '/men',
  },
  {
    id: 'kids',
    title: 'Детям',
    href: '/kids',
  },
  {
    id: 'checkups',
    title: 'Чекапы',
    href: '/checkups',
  },
  {
    id: 'chronic',
    title: 'Хронические',
    href: '/chronic',
  },
];

export const headerIcons = [
  {
    id: 'shoppingCart',
    icon: BusketWhiteIcon,
    href: '/basket',
  },
  {
    id: 'user',
    icon: UserWhiteIcon,
    href: '/profile',
  },
];

export const footerNavList = [
  {
    id: '0',
    title: 'Исследования',
    links: [
      // TODO SZ: use mainLabelsForSearch instead of hardcoded href
      {
        title: 'Чекапы',
        alias: [],
        id: '01',
        href: '/search?category=7003',
      },
      {
        title: 'Женское здоровье',
        alias: ['женщинам'],
        id: '02',
        href: '/search?category=7002',
      },
      {
        id: '03',
        title: 'Мужское здоровье',
        alias: ['мужчинам'],
        href: '/search?category=7027',
      },
      {
        id: '04',
        title: 'Детям',
        alias: [],
        href: '/search?category=7005',
      },
      {
        id: '05',
        title: 'Диагностика и наблюдение',
        alias: [],
        href: '/search?category=7004',
      },
    ],
  },
  {
    id: '1',
    title: 'Основное',
    links: [
      {
        title: 'Вопрос-Ответ',
        id: '01',
        href: '/faq',
      },
      {
        id: '02',
        title: 'Контакты',
        href: '/contact',
      },
    ],
  },
  {
    id: '2',
    title: 'Документы',
    links: [
      {
        title: 'Политика конфиденциальности',
        id: '05',
        href: '/privacy-policy',
        newTab: true,
      },
      {
        title: 'Пользовательское соглашение',
        id: '06',
        href: '/terms',
        newTab: true,
      },
      {
        title: 'Публичная оферта',
        id: '07',
        href: '/oferta',
        newTab: true,
      },
      {
        title: 'Лицензии',
        id: '08',
        href: '/licenses',
        newTab: true,
      },
    ],
  },
];

export const labsList = [
  { id: 'labQuest', name: 'Лабквест', imageSrc: '/images/labQuestLogo.svg', width: 74, height: 74 },
  { id: 'gemotest', name: 'Гемотест', imageSrc: '/images/gemotestLogo.svg', width: 235, height: 25 },
  { id: 'dialab', name: 'Диалаб', imageSrc: '/images/dialabLogo.svg', width: 180, height: 58 },
];
