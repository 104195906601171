import { LaboratoryEnum, Product } from '@graphql/generated';

export const isAvailable = (
  {
    hidden,
    disabled,
    unavailable,
    gemotestCode,
    gemotestAnalysis,
    dialabCode,
    dialabAnalysis,
    labquestCode,
    labquestAnalysis,
  }: Product,
  lab: LaboratoryEnum
) => {
  const isActive = !hidden && !disabled && !unavailable;
  switch (lab) {
    case LaboratoryEnum.Gemotest: {
      return isActive && gemotestCode && gemotestAnalysis && !gemotestAnalysis?.unavailable;
    }
    case LaboratoryEnum.Dialab: {
      return isActive && dialabCode && dialabAnalysis && !dialabAnalysis?.unavailable;
    }
    case LaboratoryEnum.Labquest: {
      return isActive && labquestCode && labquestAnalysis && !labquestAnalysis?.unavailable;
    }
    default:
      return false;
  }
};

export const labIsAvailable = (product?: Product) => {
  const res: string[] = [];
  if (product) {
    if (product.isPackage && product.subProducts) {
      const labs = getPackageLabs(product.subProducts);
      if (labs.includes(LaboratoryEnum.Labquest)) res.push('Лабквест');
      if (labs.includes(LaboratoryEnum.Gemotest)) res.push('Гемотест');
      if (labs.includes(LaboratoryEnum.Dialab)) res.push('Диалаб');
    } else if (!product.unavailable && !product.disabled) {
      if (isAvailable(product, LaboratoryEnum.Labquest)) res.push('Лабквест');
      if (isAvailable(product, LaboratoryEnum.Gemotest)) res.push('Гемотест');
      if (isAvailable(product, LaboratoryEnum.Dialab)) res.push('Диалаб');
    }
  }
  return res;
};

export const isUnavailableInPackage = (
  {
    hidden,
    disabled,
    unavailable,
    gemotestCode,
    gemotestAnalysis,
    dialabCode,
    dialabAnalysis,
    labquestCode,
    labquestAnalysis,
  }: Product,
  lab: LaboratoryEnum
) => {
  const isUnavailable = hidden || disabled || unavailable;
  switch (lab) {
    case LaboratoryEnum.Gemotest: {
      return isUnavailable || !gemotestCode || gemotestAnalysis?.unavailable;
    }
    case LaboratoryEnum.Dialab: {
      return isUnavailable || !dialabCode || dialabAnalysis?.unavailable;
    }
    case LaboratoryEnum.Labquest: {
      return isUnavailable || !labquestCode || labquestAnalysis?.unavailable;
    }
    default:
      return false;
  }
};

export const getPackageLabs = (products: Product[]) => {
  let labsArray = [LaboratoryEnum.Gemotest, LaboratoryEnum.Dialab, LaboratoryEnum.Labquest];

  for (let index = 0; index < products.length; index++) {
    if (isUnavailableInPackage(products[index], LaboratoryEnum.Gemotest)) {
      labsArray = labsArray.filter((item) => item !== LaboratoryEnum.Gemotest);
    }

    if (isUnavailableInPackage(products[index], LaboratoryEnum.Dialab)) {
      labsArray = labsArray.filter((item) => item !== LaboratoryEnum.Dialab);
    }

    if (isUnavailableInPackage(products[index], LaboratoryEnum.Labquest)) {
      labsArray = labsArray.filter((item) => item !== LaboratoryEnum.Labquest);
    }

    if (!labsArray.length) break;
  }

  return labsArray;
};

const isValidLabs = (product: Product) => {
  const { hidden, disabled, unavailable } = product;
  const isDisabled = hidden || disabled || unavailable;

  if (product.isPackage && product.subProducts?.length) {
    if (isDisabled) {
      return false;
    } else {
      return !!getPackageLabs(product.subProducts).length;
    }
  } else {
    return (
      isAvailable(product, LaboratoryEnum.Gemotest) ||
      isAvailable(product, LaboratoryEnum.Dialab) ||
      isAvailable(product, LaboratoryEnum.Labquest)
    );
  }
};

export default isValidLabs;
