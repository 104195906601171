import { useState } from 'react';

import { AuthFormValues } from '@modules/auth/authFormSchema';
import AuthForm from '@modules/auth/components/AuthForm';
import CodeVerificationForm from '@modules/auth/components/CodeVerificationForm';
import { Title, Text, Modal } from '@components/index';
import { useFillProfileDataFromGuest } from '@modules/profile/hooks/useFillProfileDataFromGuest';

import { AUTH_SUCCESS, handleSendAnalytics } from '@constants/yandexMetrikaEvents';

import { FillBasketDataInput } from '@graphql/generated';
import { useGuestProfile } from '@modules/profile/GuestProfileContext';
import formatPhone from '@utils/formatPhone';
import { useAuthModal } from '@modules/auth/AuthContext';

import s from './AuthModal.module.scss';

const AuthModal = () => {
  const { authModalIsOpen: isOpen, onModalClose } = useAuthModal();
  const [phone, setPhone] = useState('');
  const [fillProfileDataFromGuestMutation] = useFillProfileDataFromGuest();
  const { address, profile, products, onClearData } = useGuestProfile();

  const handleAuthSubmit = (data: AuthFormValues) => {
    setPhone(data.phone);
  };

  const isEmpty = (obj: Object) => Object.keys(obj).length === 0;

  const handleVerifySuccess = async () => {
    handleSendAnalytics(AUTH_SUCCESS);

    const { passport, ...restProfile } = profile;

    const input = {
      address: !isEmpty(address) ? address : null,
      profile: !isEmpty(restProfile) ? restProfile : null,
      products: products?.length ? products : null,
      passport: passport?.passportNumber ? passport : null,
    } as FillBasketDataInput;

    try {
      await fillProfileDataFromGuestMutation({
        variables: {
          input,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }

    onClearData();

    onModalClose();
  };

  const handleCloseModal = () => {
    setPhone('');
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleCloseModal();
        onModalClose();
      }}
    >
      <div className={s.root}>
        <div className="container-small">
          {!phone ? (
            <>
              <h1 className={s.title}>Авторизация</h1>
              <Text className={s.description} size="medium" fontgraphik muted>
                Мы вышлем SMS с кодом. Если профиля нет — мы его создадим для вас
              </Text>
            </>
          ) : (
            <>
              <Title level={2} className={s.title} weight="medium">
                Введите код
              </Title>
              <Text className={s.description} size="medium" fontgraphik>
                Мы отправили код на номер <span className={s.phone}>{formatPhone(phone)}</span>
              </Text>
            </>
          )}

          {!phone ? (
            <div className={s.authForm}>
              <AuthForm onSubmit={handleAuthSubmit} />
            </div>
          ) : (
            <CodeVerificationForm phone={phone} onSuccess={handleVerifySuccess} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AuthModal;
