import { useCallback, useMemo, useState } from 'react';

import {
  Product,
  useGetMyBasketQuery,
  useRemoveProductFromBasketMutation,
  useAddProductsToBasketMutation,
  useGetServiceByKeyQuery,
  useEmptyBasketMutation,
  useProductsByIdsQuery,
} from '@graphql/generated';

import { GET_MY_BASKET } from '@graphql/queries/basket';
import { FOR_CHILD_STORAGE_KEY, isServer } from '@constants/index';
import { useProfile } from '@modules/profile/hooks/useProfile';
import { useGuestProfile } from '@modules/profile/GuestProfileContext';

export interface MinimalBasketProps {
  products: Array<{ id: number | string; product: Product }>;
}

export const useBasket = () => {
  const { isUnauthorized } = useProfile();
  const {
    productIds: guestProductIds,
    onAddToProducts: onGuestAddToProducts,
    onRemoveFromProducts: onGuestRemoveFromProducts,
    onEmptyProducts: onGuestEmptyProducts,
  } = useGuestProfile();
  const {
    data: guestProducts,
    previousData: prevGuestProducts,
    loading: guestProductsLoading,
  } = useProductsByIdsQuery({
    variables: { ids: guestProductIds },
  });
  const { data: basket, loading: basketLoading, refetch } = useGetMyBasketQuery();
  const { data: nurseVisitService } = useGetServiceByKeyQuery({ variables: { key: 'nurseVisit' } });
  const [forChild, setForChild] = useState(() => {
    if (isServer) {
      return false;
    }

    const value = localStorage.getItem(FOR_CHILD_STORAGE_KEY);
    return value ? JSON.parse(value) : false;
  });

  const [emptyBasketMutation] = useEmptyBasketMutation({
    refetchQueries: [GET_MY_BASKET],
  });

  const [addProductsToBasketMutation] = useAddProductsToBasketMutation({
    refetchQueries: [GET_MY_BASKET],
  });

  const [removeProductFromBasketMutation, { loading: removeLoading }] = useRemoveProductFromBasketMutation({
    refetchQueries: [GET_MY_BASKET],
  });

  const guestBasket = useMemo(() => {
    const guestProductsCurrent = guestProducts || {
      productsByIds: prevGuestProducts?.productsByIds.filter((data) => guestProductIds.includes(data.id)) ?? [],
    };
    const fullGuestProducts = guestProductsCurrent
      ? guestProductsCurrent.productsByIds.map((product) => ({
          id: `guestBasketItem-${product.id}`,
          product: product,
        }))
      : [];
    const guestProductsSum = fullGuestProducts.reduce((acc, curr) => acc + Number(curr.product.price), 0);
    const nurseVisitPrice = parseInt(nurseVisitService?.getServiceByKey.value ?? '', 10) || 0;

    return {
      sum: guestProductsSum,
      total: guestProductsSum + Number(nurseVisitPrice),
      nurseVisit: nurseVisitPrice,
      products: fullGuestProducts,
    };
  }, [guestProducts, prevGuestProducts, guestProductIds, nurseVisitService?.getServiceByKey.value]);

  const onAddToBasket = useCallback(
    async (id: Product['id']) => {
      try {
        if (isUnauthorized) {
          onGuestAddToProducts(id);
        } else {
          await addProductsToBasketMutation({
            variables: {
              products: [
                {
                  productId: id || '',
                },
              ],
            },
          });
        }
      } catch (error) {
        // alert(error);
      }
    },
    [addProductsToBasketMutation, isUnauthorized, onGuestAddToProducts]
  );

  const onAddPartnerAuthToBasket = useCallback(
    async (id: Product['id']) => {
      try {
        await addProductsToBasketMutation({
          variables: {
            products: [
              {
                productId: id || '',
              },
            ],
          },
        });
      } catch (error) {
        // alert(error);
      }
    },
    [addProductsToBasketMutation]
  );

  const onAddPartnerGuestToBasket = useCallback(async (id: Product['id']) => {
    try {
      await onGuestAddToProducts(id);
    } catch (error) {
      // alert(error);
    }
  }, []);

  const onRemoveProductFromBasket = useCallback(
    async (id: Product['id']) => {
      try {
        if (isUnauthorized) {
          onGuestRemoveFromProducts(id);
        } else {
          await removeProductFromBasketMutation({ variables: { productId: id } });
        }
      } catch (error) {
        alert(error);
      }
    },
    [removeProductFromBasketMutation, isUnauthorized, onGuestRemoveFromProducts]
  );

  const onEmptyBasket = useCallback(async () => {
    try {
      if (isUnauthorized) {
        onGuestEmptyProducts();
      } else {
        await emptyBasketMutation();
      }
    } catch (error) {
      alert(error);
    }
  }, [isUnauthorized, emptyBasketMutation, onGuestEmptyProducts]);

  const onForChildChange = useCallback((value: boolean) => {
    setForChild(value);
    localStorage.setItem(FOR_CHILD_STORAGE_KEY, JSON.stringify(value));
  }, []);

  return {
    basket: isUnauthorized ? guestBasket : basket?.getMyBasket,
    loading: guestProductsLoading || basketLoading,
    disabledRemoveButton: removeLoading,
    onRemoveProductFromBasket,
    onAddToBasket,
    onEmptyBasket,
    forChild,
    onForChildChange,
    onAddPartnerAuthToBasket,
    onAddPartnerGuestToBasket,
    updateBasket: refetch,
  };
};
