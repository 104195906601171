import { format, getHours, isToday, isTomorrow } from 'date-fns';
import ru from 'date-fns/locale/ru';

export const formatDate = (date: Date) => {
  const dateIsReset = getHours(date) === 0;
  const dateFormat = dateIsReset ? 'd MMM' : 'd MMM HH:mm';

  if (isToday(date)) {
    return format(date, `'Сегодня,' ${dateFormat}`, { locale: ru });
  }
  if (isTomorrow(date)) {
    return format(date, `'Завтра,' ${dateFormat}`, { locale: ru });
  }
  return format(date, `EEEE',' ${dateFormat}`, { locale: ru });
};

export const resetDateTime = (date: Date) => new Date(date).setHours(0, 0, 0, 0);
