import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';

import {
  EditEmailMutationVariables,
  EditProfileInput,
  PassportInput,
  useEditEmailMutation,
  useEditPassportMutation,
  useEditProfileMutation,
  useMyProfileQuery,
  useSendEmailConfirmTokenMutation,
} from '@graphql/generated';
import { PersonalDataFormValues } from '@modules/profile/personalDataFormSchema';
import { isUnauthorizedError } from '@modules/profile/isUnauthorizedError';
import { useGuestProfile } from '@modules/profile/GuestProfileContext';

type PersonalDataFormValuesRequiredOnly = Omit<PersonalDataFormValues, 'email' | 'passport'>;

function transformPersonalData(data: PersonalDataFormValuesRequiredOnly): EditProfileInput {
  let res = { ...data };

  for (const key in res) {
    switch (key) {
      case 'birthday':
        // @ts-ignore
        res[key] = format(res[key], 'yyyy-MM-dd');
        break;

      case 'gender':
        // @ts-ignore
        res[key] = res[key] === '' ? undefined : res[key];
        break;
    }
  }

  return res;
}

export function useProfile() {
  const { data: profile, loading, error } = useMyProfileQuery();
  const { profile: guestProfile } = useGuestProfile();
  const [editProfileMutation] = useEditProfileMutation();
  const [editEmailMutation] = useEditEmailMutation();
  const [editPassportMutation] = useEditPassportMutation();
  const [sendConfirmEmailToken] = useSendEmailConfirmTokenMutation();

  const isUnauthorized = useMemo(() => (!profile && loading) || isUnauthorizedError(error), [profile, loading, error]);

  const onEditProfile = useCallback(
    async (values: PersonalDataFormValuesRequiredOnly) => {
      if (isUnauthorized) {
        return;
      }

      await editProfileMutation({
        variables: {
          input: transformPersonalData(values),
        },
      });
    },
    [isUnauthorized, editProfileMutation]
  );

  const onEditPassport = async (values: PassportInput) => {
    if (isUnauthorized) {
      return;
    }

    await editPassportMutation({ variables: { input: values } });
  };

  const onEditEmail = async (values: EditEmailMutationVariables) => {
    if (isUnauthorized) {
      return;
    }

    await editEmailMutation({ variables: values });
  };

  const onSendConfirmEmailToken = async () => {
    if (isUnauthorized) {
      return;
    }

    await sendConfirmEmailToken();
  };

  return {
    profile: isUnauthorized ? guestProfile : profile?.myProfile,
    isUnauthorized,
    onEditProfile,
    onEditEmail,
    onEditPassport,
    onSendConfirmEmailToken,
  };
}
