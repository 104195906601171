import { ReactNode } from 'react';
import { useCombobox } from 'downshift';
import cn from 'classnames';

import { Suggestion, useAddressAutocomplete } from '@lib/addressAutocomplete';
import Input from '@components/Input';
import { SuggestionsMenu, SuggestionsMenuItem } from '@components/SuggestionsMenu';
import Text from '@components/Text';

import s from './AddressAutocompleteInput.module.scss';

const itemToString = (item: Suggestion | null) => item?.value ?? '';

interface Props {
  inputClassName?: string;
  suggestionsClassName?: string;
  value: string | undefined;
  onChange: (value?: string) => void;
  setCoordinates?: (value: any) => void;
  label?: string;
  isError?: boolean;
  isDisabled?: boolean;
  postContent?: ReactNode;
}

const AddressAutocompleteInput = ({
  inputClassName,
  suggestionsClassName,
  value,
  onChange,
  label,
  isError,
  isDisabled,
  postContent,
  setCoordinates,
}: Props) => {
  const { suggestions, fetchSuggestions, validateAddress, getCoordinates } = useAddressAutocomplete();
  const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps } = useCombobox({
    items: suggestions,
    itemToString: itemToString,
    onSelectedItemChange: async (changes) => {
      const isValid = await validateAddress(changes.selectedItem?.value);

      if (isValid && setCoordinates) {
        const { coordinates } = await getCoordinates(changes.selectedItem?.value);

        setCoordinates(coordinates);
      }

      // eslint-disable-next-line no-console
      console.log({ isValid });
    },
    onInputValueChange: ({ inputValue }) => {
      onChange(inputValue);
      fetchSuggestions(inputValue);
    },
  });

  const listClasses = cn(s.list, isOpen && s.active, suggestionsClassName);

  return (
    <>
      <div className={s.root}>
        <div {...getComboboxProps()}>
          <Input
            {...getInputProps({ value: value ?? '', disabled: isDisabled, autoComplete: 'street-address' })}
            className={inputClassName}
            label={label}
            isError={isError}
            postContent={postContent}
          />
        </div>

        <SuggestionsMenu isOpen={isOpen} className={listClasses} {...getMenuProps()}>
          {suggestions.map((item, index) => (
            <SuggestionsMenuItem
              key={`${item.value}${index}`}
              className={cn(s.option, highlightedIndex === index && s.active)}
              {...getItemProps({ item, index })}
            >
              <div className={s.menuItem}>
                <Text>{item.displayName}</Text>
              </div>
            </SuggestionsMenuItem>
          ))}
        </SuggestionsMenu>
      </div>
    </>
  );
};

export default AddressAutocompleteInput;
