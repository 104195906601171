const formatPhone = (number?: string) => {
  if (!number) return;
  const array = number.split('');
  let res = '';
  for (let index = 0; index < array.length; index++) {
    switch (index) {
      case 2: {
        res += ' (' + array[index];
        break;
      }
      case 5: {
        res += ') ' + array[index];
        break;
      }
      case 8:
      case 10:
      case 12: {
        res += '-' + array[index];
        break;
      }
      default:
        res += array[index];
    }
  }
  return res;
};

export default formatPhone;
