import { ReactNode } from 'react';
import Text from '@components/Text';

type Props = {
  children?: string | ReactNode;
  title?: string;
};

const Tab = ({ children }: Props) => {
  const isString = typeof children === 'string';

  return isString ? <Text fontgraphik>{children}</Text> : <>{children}</>;
};

export default Tab;
