import { useDebouncedCallback } from 'use-debounce';
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';

import { useGetUserFieldsSuggestionsLazyQuery } from '@graphql/generated';

export enum NameParts {
  Surname = 'SURNAME',
  Name = 'NAME',
  Patronymic = 'PATRONYMIC',
  Email = 'EMAIL',
}

const useUserFieldsSuggestions = (
  namePart?: NameParts,
  debounceTimeInMsc?: number
): [string[], DebouncedState<(query?: string) => Promise<void>>] => {
  // TODO SZ: return loading status
  const [runQuery, { data: fetchedSuggestions }] = useGetUserFieldsSuggestionsLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const fetchSuggestions = useDebouncedCallback(async (query?: string) => {
    if (!query) {
      return;
    }

    await runQuery({
      variables: { query, ...(namePart && { parts: [namePart] }) },
    });
  }, debounceTimeInMsc || 600);

  return [fetchedSuggestions?.userFieldsSuggestions || [], fetchSuggestions];
};

export default useUserFieldsSuggestions;
