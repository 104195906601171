import * as Sentry from '@sentry/nextjs';
import { useCallback } from 'react';
import Cookie from 'js-cookie';

import { useAuthMutation, useAuthVerifyMutation } from '@graphql/generated';
import { TOKEN_STORAGE_KEY } from '@constants/index';
import { useApolloClient } from '@apollo/client';

export function usePhoneAuth() {
  const phoneAuthMutation = useAuthMutation();

  return phoneAuthMutation;
}

export function usePhoneAuthVerify() {
  const client = useApolloClient();

  const phoneAuthVerifyMutation = useAuthVerifyMutation({
    update: async (_cache, { data }) => {
      try {
        if (data?.verify.accessToken) {
          Cookie.set(TOKEN_STORAGE_KEY, data.verify.accessToken, {
            expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
          });
          await client.resetStore();
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  });

  return phoneAuthVerifyMutation;
}

export function useLogout() {
  const client = useApolloClient();

  const logout = useCallback(async () => {
    try {
      Cookie.remove(TOKEN_STORAGE_KEY);
      await client.resetStore();
    } catch (error) {}
  }, [client]);

  return logout;
}
