import React, { InputHTMLAttributes, useState } from 'react';

import cn from 'classnames';

import Text from '@components/Text';

import InputWrapper from '../InputWrapper';

import s from './Input.module.scss';

export interface InputPropsTypes extends InputHTMLAttributes<HTMLInputElement> {
  preContent?: React.ReactNode;
  postContent?: React.ReactNode;
  label?: string;
  labelClassName?: string;
  className?: string;
  isError?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputPropsTypes>(
  (
    {
      preContent,
      postContent,
      disabled = false,
      isError = false,
      name,
      type = 'text',
      onChange,
      label,
      labelClassName,
      placeholder,
      className,
      ...props
    },
    ref
  ) => {
    const [hasFocus, setFocus] = useState(false);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      const { onFocus } = props;

      setFocus(true);
      onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const { onBlur } = props;

      setFocus(false);
      onBlur?.(event);
    };

    const classNames = cn(
      s.root,
      className,
      label && s.hasLabel,
      hasFocus && s.focus,
      disabled && s.disabled,
      isError && s.error
    );

    return (
      <InputWrapper
        isError={isError}
        isDisabled={disabled}
        isFocused={hasFocus}
        preContent={preContent}
        postContent={postContent}
      >
        <>
          <input
            {...props}
            className={classNames}
            id={name}
            type={type}
            name={name}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            disabled={disabled}
            placeholder={label ? label : placeholder}
            ref={ref}
          />
          {label ? (
            <Text className={cn(s.label, labelClassName)} inline>
              {label}
            </Text>
          ) : null}
        </>
      </InputWrapper>
    );
  }
);

export default Input;
