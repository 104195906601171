import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '@graphql/fragments/profile';

export const GET_PROFILE = gql`
  query MyProfile {
    myProfile {
      ...UserFields
    }
  }
  ${USER_FRAGMENT}
`;
