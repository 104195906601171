import { FormEvent, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { parseISO, setHours } from 'date-fns';
import Link from 'next/link';
import classNames from 'classnames';

import { GENDER_VALUES } from '@constants/index';
import { TODAY } from '@modules/checkout/components/DateChooser/constants';
import { stepPersonalDataFormSchema, StepPersonalDataFormValues } from '@modules/checkout/stepPersonalDataFormSchema';
import { useProfile } from '@modules/profile/hooks/useProfile';
import { useGuestProfile } from '@modules/profile/GuestProfileContext';
import { useCheckoutWizard } from '@modules/checkout/CheckoutWizardContext';
import { ProtectIcon } from '@components/Icon';
import { Button, DatePicker, Text, Select } from '@components/index';
import InputMask from '@components/InputMask';
import StepHeader from '@modules/checkout/components/StepHeader';
import InputError from '@modules/checkout/components/InputError';
import useUserFieldsSuggestions, { NameParts } from '@hooks/useUserFieldsSuggestions';
import AutocompleteInput from '@components/AutocompleteInput/AutocompleteInput';

import { PERSONAL_FORM, handleSendAnalytics } from '@constants/yandexMetrikaEvents';

import s from '@modules/checkout/components/CheckoutWizard/CheckoutWizard.module.scss';
import PhoneInput from '@components/PhoneInput/PhoneInput';
import Checkbox from '@components/Checkbox';
import { usePhoneAuth } from '@modules/auth/hooks/useAuth';

const StepPersonalData = () => {
  const [loading, setLoading] = useState(false);
  const { onProfileChange: onGuestProfileChange } = useGuestProfile();
  const { profile, isUnauthorized, onEditProfile, onEditPassport } = useProfile();
  const { formValues, handleStepSubmit, goToNextStep, goToPrevStep } = useCheckoutWizard();
  const [authMutation] = usePhoneAuth();
  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<StepPersonalDataFormValues>({
    defaultValues: formValues,
    resolver: yupResolver(stepPersonalDataFormSchema),
  });

  const [surnameSuggestions, fetchSurnameSuggestions] = useUserFieldsSuggestions(NameParts.Surname);
  const [nameSuggestions, fetchNameSuggestions] = useUserFieldsSuggestions(NameParts.Name);
  const [patronymicSuggestions, fetchPatronymicSuggestions] = useUserFieldsSuggestions(NameParts.Patronymic);
  const [emailSuggestions, fetchEmailSuggestions] = useUserFieldsSuggestions(NameParts.Email);

  useEffect(() => {
    if (profile) {
      reset({
        nameLast: profile.nameLast ?? undefined,
        nameFirst: profile.nameFirst ?? undefined,
        nameMiddle: profile.nameMiddle ?? undefined,
        birthday: profile?.birthday ? parseISO(profile.birthday) : undefined,
        gender: profile.gender ?? undefined,
        passportNumber: profile.passport?.passportNumber,
        phone: profile.phone ?? undefined,
        email: profile.email ?? undefined,
      });
    }
  }, [reset, profile]);

  const handleFormSubmit = async (values: StepPersonalDataFormValues) => {
    try {
      if (loading) {
        return;
      }

      setLoading(true);

      handleStepSubmit(values);

      if (isUnauthorized) {
        let { passportNumber, birthday, ...profileFields } = values;
        onGuestProfileChange({
          ...profileFields,
          birthday: birthday && setHours(birthday, 23)?.toISOString(),
          passport: values.passportNumber
            ? { id: values.passportNumber, passportNumber: values.passportNumber }
            : undefined,
        });
        await authMutation({ variables: { phone: values.phone } });
      } else {
        let { passportNumber, phone, agreeOffer, agreePersonalData, ...personaldDataValues } = values;
        if (personaldDataValues.birthday) {
          personaldDataValues.birthday = setHours(personaldDataValues.birthday, 23);
        }
        if (passportNumber && profile?.passport?.passportNumber !== passportNumber) {
          await onEditPassport({ passportNumber });
        }

        await onEditProfile(personaldDataValues);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
    } finally {
      setLoading(false);

      handleSendAnalytics(PERSONAL_FORM);

      goToNextStep();
    }
  };

  const getSuggestFieldChangeHandler =
    (onFieldChange: (...event: any[]) => void, fetchSuggestionsFunc: (value: string) => void) =>
    (value?: string | FormEvent<HTMLInputElement>) => {
      if (typeof value === 'string') {
        fetchSuggestionsFunc(value);
      }
      onFieldChange(value);
    };

  const getEmailChangeHandler =
    (onFieldChange: (...event: any[]) => void, fetchSuggestionsFunc: (value: string) => void) =>
    (value?: string | FormEvent<HTMLInputElement>) => {
      if (typeof value === 'string') {
        fetchSuggestionsFunc(value);
      }
      onFieldChange(value);
    };

  const handleOnClick = () => {
    goToPrevStep();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className={s.summWrapper}>
      <StepHeader title="Личные данные" />
      <div className={classNames(s.partWrapper, s.last)}>
        <div className={s.inputRow}>
          <Controller
            control={control}
            name="nameLast"
            render={({ field: { value, onChange } }) => (
              <AutocompleteInput
                value={value}
                onChange={getSuggestFieldChangeHandler(onChange, fetchSurnameSuggestions)}
                label="Фамилия"
                containsError={Boolean(errors.nameLast)}
                suggestions={surnameSuggestions}
              />
            )}
          />
          {errors.nameLast?.message ? <InputError message={errors.nameLast.message} /> : null}
        </div>
        <div className={s.inputRow}>
          <Controller
            control={control}
            name="nameFirst"
            render={({ field: { value, onChange } }) => (
              <AutocompleteInput
                value={value}
                onChange={getSuggestFieldChangeHandler(onChange, fetchNameSuggestions)}
                label="Имя"
                containsError={Boolean(errors.nameFirst)}
                suggestions={nameSuggestions}
              />
            )}
          />
          {errors.nameFirst?.message ? <InputError message={errors.nameFirst.message} /> : null}
        </div>
        <div className={s.inputRow}>
          <Controller
            control={control}
            name="nameMiddle"
            render={({ field: { value, onChange } }) => (
              <AutocompleteInput
                value={value}
                onChange={getSuggestFieldChangeHandler(onChange, fetchPatronymicSuggestions)}
                label="Отчество"
                containsError={Boolean(errors.nameMiddle)}
                suggestions={patronymicSuggestions}
              />
            )}
          />
          {errors.nameMiddle?.message ? <InputError message={errors.nameMiddle.message} /> : null}
        </div>
        <div className={classNames(s.inputRow, s.inputHalfRow)}>
          <div className="birthdayPicker">
            <Controller
              control={control}
              name="birthday"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  popperClassName={s.birthdayCalendarPopper}
                  calendarClassName="birthdayCalendar"
                  selected={value}
                  maxDate={TODAY}
                  dateFormat="dd.MM.yyyy"
                  onChange={onChange}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={<InputMask mask="99.99.9999" label="Дата рождения" isError={Boolean(errors.birthday)} />}
                />
              )}
            />

            {errors.birthday?.message ? <InputError message={errors.birthday.message} /> : null}
          </div>

          <div>
            <Controller
              control={control}
              name="gender"
              render={({ field: { value, onChange } }) => (
                <Select
                  label="Пол"
                  options={GENDER_VALUES}
                  value={value}
                  onChange={(changes) => onChange(changes.selectedItem?.value)}
                  isError={Boolean(errors.gender)}
                />
              )}
            />
            {errors.gender?.message ? <InputError message={errors.gender.message} /> : null}
          </div>
        </div>
        <div className={s.inputRow}>
          <Controller
            control={control}
            name="passportNumber"
            render={({ field: { value, onChange, onBlur } }) => (
              <InputMask
                mask="9999 999999"
                maskPlaceholder=""
                label="Серия и номер паспорта"
                value={value}
                isError={Boolean(errors.passportNumber)}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          {errors.passportNumber?.message ? <InputError message={errors.passportNumber.message} /> : null}
        </div>

        <div className={classNames(s.inputRow, s.inputHalfRow)}>
          <div>
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <PhoneInput
                  value={field.value}
                  onChange={field.onChange}
                  label="Ваш номер телефона"
                  disabled={!isUnauthorized}
                  isError={Boolean(errors.phone)}
                />
              )}
            />
            {errors.phone?.message ? <InputError message={errors.phone.message} /> : null}
          </div>

          <div>
            <Controller
              control={control}
              name="email"
              render={({ field: { value, onChange } }) => (
                <AutocompleteInput
                  type="email"
                  autoComplete="email"
                  value={value}
                  onChange={getEmailChangeHandler(onChange, fetchEmailSuggestions)}
                  label="Ваш e-mail"
                  containsError={Boolean(errors.email)}
                  suggestions={emailSuggestions}
                />
              )}
            />

            {errors.email?.message ? <InputError message={errors.email.message} /> : null}
          </div>
        </div>

        <div className={s.checkboxBlock}>
          <div className={s.checkboxRow}>
            <Controller
              name="agreeOffer"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  label={
                    <>
                      Я согласен на обработку{' '}
                      <Link href="/terms">
                        <a className={s.link} target="_blank" rel="noreferrer">
                          персональных данных
                        </a>
                      </Link>
                    </>
                  }
                  isError={Boolean(errors.agreeOffer)}
                  checked={field.value}
                  onChange={(event) => field.onChange(event.target.checked)}
                />
              )}
            />
          </div>

          <div className={s.checkboxRow}>
            <Controller
              name="agreePersonalData"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  className={s.checkBoxText}
                  label={
                    <>
                      Ознакомлен и согласен с{' '}
                      <Link href="/oferta">
                        <a className={s.link} target="_blank" rel="noreferrer">
                          публичной офертой
                        </a>
                      </Link>
                    </>
                  }
                  isError={Boolean(errors.agreePersonalData)}
                  checked={field.value}
                  onChange={(event) => field.onChange(event.target.checked)}
                />
              )}
            />
          </div>
        </div>

        <div className={s.info}>
          <div className={s.infoContainer}>
            <div className={s.iconProtectContainer}>
              <ProtectIcon />
            </div>

            <div className={s.textContent}>
              <Text>
                Информация выше используется для заключения договора на медицинское оборудование. Не беспокойтесь, ваши
                данные не передаются третьим лицам.{' '}
                <Link href="/privacy-policy">
                  <div>
                    <a className={s.link} target="_blank" rel="noreferrer">
                      Политика конфиденциальности
                    </a>
                  </div>
                </Link>
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Button type="submit" size="large" fullWidth loading={loading} disabled={loading}>
        Продолжить
      </Button>

      <Button onClick={handleOnClick} theme={'secondary'} className={s.prevStepButton} size="large" fullWidth>
        Назад
      </Button>
    </form>
  );
};

export default StepPersonalData;
