import classNames from 'classnames';
import { intlFormat } from 'date-fns';

import { ArrowRightRoundRedesignIcon } from '@components/Icon';
import Button from '@components/Button';
import Text from '@components/Text';

import styles from './CalendarHeader.module.scss';

interface CalendarHeaderProps {
  date: Date;
  decrementIsDisabled: boolean;
  incrementIsDisabled: boolean;
  onDecrementMonth: () => void;
  onIncrementMonth: () => void;
}

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  date,
  decrementIsDisabled,
  incrementIsDisabled,
  onDecrementMonth,
  onIncrementMonth,
}) => {
  const monthYearText = intlFormat(
    new Date(date),
    {
      year: 'numeric',
      month: 'long',
    },
    {
      locale: 'ru-RU',
    }
  );

  return (
    <div className={styles.root}>
      <Text className={styles.monthTitle}>{monthYearText.slice(0, -2)}</Text>
      <div className={styles.monthTogglers}>
        <Button
          className={classNames(styles.icon, styles.mirroredIcon)}
          theme="ghost"
          disabled={decrementIsDisabled}
          onClick={onDecrementMonth}
        >
          <ArrowRightRoundRedesignIcon />
        </Button>

        <Button className={styles.icon} theme="ghost" disabled={incrementIsDisabled} onClick={onIncrementMonth}>
          <ArrowRightRoundRedesignIcon />
        </Button>
      </div>
    </div>
  );
};

export default CalendarHeader;
