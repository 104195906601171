import { useCallback, useEffect, useRef, useState } from 'react';

import Modal from '@components/Modal';
import { getApolloErrorMessage } from '@utils/getApolloErrorMessage';
import Toast from '@components/Toast';
import { ERROR_MISSING_ITEM_IN_ORDER } from '@constants/errors';
import { useBasket } from '@hooks/useBasket';
import { useCheckoutWizard } from '@modules/checkout/CheckoutWizardContext';
import { useAddOrderMutation } from '@graphql/generated';
import { useRouter } from 'next/router';
import { handleSendAnalytics, PAID_ORDER, PAYMENT_SUCCESS } from '@constants/yandexMetrikaEvents';
import { useGuestProfile } from '@modules/profile/GuestProfileContext';
import { useProfile } from '@modules/profile/hooks/useProfile';
import s from '@modules/checkout/components/CheckoutWizard/CheckoutWizard.module.scss';
import Text from '@components/Text';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onModalError: () => void;
  getOrderId: (id: string) => void;
}

const PaymentModal = ({ isOpen, onClose, onModalError, getOrderId }: Props) => {
  const { basket, forChild, updateBasket, onEmptyBasket } = useBasket();
  const { formValues } = useCheckoutWizard();
  const [addOrderMutation] = useAddOrderMutation();
  const addOrderCalled = useRef(false);
  const [confirmationToken, setConfirmationToken] = useState<string | null>(null);
  const [checkoutWidget, setCheckoutWidget] = useState(null);
  const [checkoutWidgetRendered, setCheckoutWidgetRendered] = useState(false);
  const { onEditEmail } = useProfile();
  const { profile } = useGuestProfile();
  const router = useRouter();
  const [orderId, setOrderId] = useState<string | null>(null);

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (orderId) {
      getOrderId(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    async function addOrder() {
      try {
        if (
          !basket?.products ||
          basket.products.length === 0 ||
          !formValues?.address ||
          !formValues?.email ||
          !formValues?.gender ||
          !formValues?.nameFirst ||
          !formValues?.nameLast ||
          !formValues?.nameMiddle ||
          !formValues?.passportNumber ||
          !formValues?.slotId ||
          !formValues?.phone ||
          !formValues?.laboratories
        ) {
          return;
        }

        addOrderCalled.current = true;

        const partner = localStorage.getItem('partner');

        const res = await addOrderMutation({
          variables: {
            input: {
              address: formValues.address,
              apartment: formValues.apartment,
              birthday: formValues.birthday,
              comment: formValues.comment,
              date: formValues.date,
              email: formValues.email,
              entrance: formValues.entrance,
              floor: formValues.floor,
              gender: formValues.gender,
              nameFirst: formValues.nameFirst,
              nameLast: formValues.nameLast,
              nameMiddle: formValues.nameMiddle,
              passportNumber: formValues.passportNumber,
              phone: formValues.phone,
              forChild: forChild,
              partner,
              products:
                formValues.laboratories.map((item) => ({ productId: item.product.id, laboratory: item.laboratory })) ||
                [],
              slotId: formValues.slotId,
            },
          },
        });

        setOrderId(res.data?.addOrder?.id || null);
        setConfirmationToken(res.data?.addOrder.payment?.confirmationToken ?? null);
        localStorage.removeItem('partner');
      } catch (error) {
        const message = getApolloErrorMessage(error);
        if (message) {
          Toast({ type: 'error', message });
        }

        if (message === ERROR_MISSING_ITEM_IN_ORDER) {
          setTimeout(() => {
            router.push('basket');
          }, 1000);
        }

        onModalError();
      }
    }

    if (addOrderCalled.current === false) {
      addOrder();
    }
  }, [router, formValues, addOrderMutation, basket, forChild]);

  const renderWidget = async (widget: any) => {
    await delay(1500);
    widget.render('payment-form');
    setCheckoutWidgetRendered(true);
  };

  const initializeWidget = useCallback(async () => {
    // @ts-ignore
    if (!window.YooMoneyCheckoutWidget) {
      return;
    }

    // @ts-ignore
    const widget = new window.YooMoneyCheckoutWidget({
      confirmation_token: confirmationToken,
      error_callback: function (error: string) {
        // eslint-disable-next-line no-console
        console.log(error);
        router.push('payment-failure');
      },
    });

    widget.on('success', async () => {
      try {
        let { email } = formValues;
        const emailChanged = email && profile?.email !== email;
        if (emailChanged && email) {
          await onEditEmail({ email });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        router.push('payment-success');
        handleSendAnalytics(PAYMENT_SUCCESS);

        onEmptyBasket();
        updateBasket();
      }
    });

    widget.on('fail', () => {
      router.push('payment-failure');
      widget.destroy();
    });

    await renderWidget(widget);

    setCheckoutWidget(widget);

    handleSendAnalytics(PAID_ORDER);
  }, [router, confirmationToken]);

  useEffect(() => {
    if (confirmationToken !== null && isOpen) {
      if (checkoutWidgetRendered) {
        return;
      }
      if (!!checkoutWidget) {
        renderWidget(checkoutWidget);
      } else {
        initializeWidget();
      }
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setCheckoutWidgetRendered(false);
        onClose();
      }}
    >
      <div id="payment-form" className={s.paymentForm}>
        {checkoutWidgetRendered ? null : <Text fontgraphik>Загрузка виджета оплаты...</Text>}
      </div>
    </Modal>
  );
};

export default PaymentModal;
