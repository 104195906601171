import Image from 'next/image';
import cn from 'classnames';

import { LoaderIcon } from '@components/Icon';

import s from './Loader.module.scss';

interface Props {
  className?: string;
  variant?: 'spinner' | 'logo';
}

const Loader: React.FC<Props> = ({ className, variant = 'spinner' }) => (
  <span className={cn(s.loader, className)}>
    {variant === 'spinner' ? (
      <LoaderIcon />
    ) : (
      <Image alt="loading..." src="/images/loading.svg" layout="fixed" width="28" height="39" />
    )}
  </span>
);

export default Loader;
