import React from 'react';
import cn from 'classnames';
import { Maybe } from 'yup/es/types';

import { AnalysisLabels, Text } from '@components/index';
import { Product } from '@graphql/generated';
import { formatPrice } from '@utils/formatPrice';

import s from './CheckOutProductCard.module.scss';

interface TOwnProps {
  lab: string;
  title: string;
  price: number;
  code?: Maybe<string>;
  deadline?: number;
  subProducts?: Maybe<Product[]>;
  className?: string;
  biomaterial?: string | null | undefined;
  isPackage?: boolean;
  orderPage?: boolean;
}

const CheckOutProductCard: React.FC<TOwnProps & JSX.IntrinsicElements['div']> = ({
  className,
  title,
  price,
  biomaterial,
  code,
  deadline,
  subProducts,
  lab,
  isPackage,
  orderPage = false,
}) => {
  const classes = cn(s.root, className);

  const formatedPrice = formatPrice(price);

  return (
    <div className={classes}>
      <div className={s.content}>
        <div className={s.topContent}>
          <div className={s.firstRow}>
            <Text className={s.title}>{title}</Text>
            <Text className={s.price}>{formatedPrice}</Text>
          </div>
          <div className={s.labelsRow}>
            <AnalysisLabels
              code={code}
              subProductsNumber={subProducts?.length}
              deadline={deadline}
              biomaterial={biomaterial}
              subProducts={subProducts}
              isPackage={isPackage}
              textSize="extra-small"
              isCodeInline={false}
              orderPage={orderPage}
            />
          </div>
          <div className={s.labRow}>
            <Text className={s.labName}>{lab}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOutProductCard;
