import { gql } from '@apollo/client';

import { PRODUCT_FRAGMENT } from '@graphql/fragments/products';

export const BASKET_PRODUCT_FRAGMENT = gql`
  fragment BasketProductsFields on Basket {
    id
    product {
      ...ProductFields
    }
  }
  ${PRODUCT_FRAGMENT}
`;
