import { ReactElement } from 'react';
import classNames from 'classnames';

import styles from './SuggestionsMenuItem.module.scss';

interface SuggestionsMenuItemProps {
  className?: string;
  children?: ReactElement;
}

const SuggestionsMenuItem: React.FC<SuggestionsMenuItemProps> = ({ className, children, ...rest }) => (
  <li className={classNames(styles.root, className)} {...rest}>
    {children}
  </li>
);

export default SuggestionsMenuItem;
