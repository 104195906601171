import { useEffect, useState } from 'react';

const useDeviceType = () => {
  const [isVerticalMenu, setIsVerticalMenu] = useState(false);
  const [isPhoneIcon, setIsPhoneIcon] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  const resizeHandler = () => {
    const screen = window.innerWidth;
    setIsPhoneIcon(screen <= 1200);
    setIsVerticalMenu(screen <= 1200);
    setIsTablet(screen <= 820);
    setIsMobile(screen <= 480);
    setScreenWidth(screen);
  };

  useEffect(() => {
    resizeHandler();

    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return { isMobile, isTablet, isVerticalMenu, isPhoneIcon, screenWidth };
};

export default useDeviceType;
