import { useGetServiceByKeyQuery } from '@graphql/generated';

export const useService = () => {
  const { data: nurseVisitService } = useGetServiceByKeyQuery({ variables: { key: 'nurseVisit' } });
  const { data: defaultLaboratory } = useGetServiceByKeyQuery({ variables: { key: 'defaultLaboratory' } });

  return {
    nurseVisitService: nurseVisitService,
    defaultLaboratory: defaultLaboratory,
  };
};
