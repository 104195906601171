import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';

import useClickOutside from '@hooks/useClickOutSide';

import Text from '@components/Text';
import { CheckIcon } from '@components/Icon';
import { Divider } from '@components/index';

import { LaboratoryEnum, Product } from '@graphql/generated';

import { Laboratories } from '../../types';

import s from './LabsPopUp.module.scss';

type Value = {
  id: string;
  icon: string;
  text: string;
  laboratory: LaboratoryEnum;
};

type LabsPopUpProps = {
  className?: string;
  label: string;
  values: Value[];
  basketProduct: { id: string; product: Product };
  setLabsToProducts: React.Dispatch<React.SetStateAction<Laboratories>>;
  handelChangeLab?: () => void;
};

const LabsPopUp: FC<LabsPopUpProps> = ({
  className,
  label,
  values,
  basketProduct,
  setLabsToProducts,
  handelChangeLab,
}) => {
  const isSingleValue = values.length === 1;
  const [isOpen, setIsOpen] = useState(false);
  const [currentLab, setCurrentLab] = useState<Value | null>(isSingleValue ? values[0] : null);

  useEffect(() => {
    if (currentLab) {
      const newLabList = (prevState: Laboratories) => {
        if (prevState.find((item) => item.id === basketProduct.id)) {
          return prevState.map((item) =>
            item.id === basketProduct.id ? { ...basketProduct, laboratory: currentLab?.laboratory } : item
          );
        } else {
          return [...prevState, { ...basketProduct, laboratory: currentLab?.laboratory }];
        }
      };
      setLabsToProducts((prevState) => newLabList(prevState));
    }
  }, [currentLab, basketProduct, setLabsToProducts]);

  const valuesRef = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  useClickOutside(() => {
    if (isOpen) handleClose();
  }, valuesRef);

  return (
    <div className={classNames(s.root, className)}>
      <div className={s.label}>
        <div className={s.labelText} onClick={() => setIsOpen((current) => !current)}>
          {currentLab ? <Text className={s.currentLab}>{currentLab.text}</Text> : <Text>{label}</Text>}
          {!isSingleValue && (
            <Image src={'/images/chevron-down.svg'} alt={'chevron-down'} width={'24px'} height={'24px'} />
          )}
        </div>
        {isOpen && (
          <div className={s.values} ref={valuesRef}>
            {values.map((item, index) => (
              <>
                <div
                  key={item.id}
                  className={s.value}
                  onClick={() => {
                    if (handelChangeLab) {
                      handelChangeLab();
                    }
                    setCurrentLab(item);
                    setIsOpen(false);
                  }}
                >
                  <Image src={item.icon} alt={''} width={'32px'} height={'32px'} />
                  <Text>{item.text}</Text>
                  {item.id === currentLab?.id && (
                    <div className={s.checkIcon}>
                      <CheckIcon />
                    </div>
                  )}
                </div>

                {index !== values.length - 1 && <Divider className={s.divider} />}
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LabsPopUp;
