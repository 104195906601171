import { gql } from '@apollo/client';

import { ADDRESS_FRAGMENT } from '@graphql/fragments/addresses';

export const GET_MY_ADDRESSES = gql`
  query MyAddresses {
    myAddresses {
      ...AddressFields
    }
  }
  ${ADDRESS_FRAGMENT}
`;
