import ym from 'react-yandex-metrika';
import { trackEvent } from '@phntms/next-gtm';

import { isServer } from './index';

export const ADDRESS_TIME = 'address-time';
export const PERSONAL_FORM = 'personal-form';
export const LABA_CHOICE = 'laba-choice';
export const ACCEPTING_RULES = 'accepting-rules';
export const PAID_ORDER = 'paid-order';
export const PAYMENT_SUCCESS = 'payment-success';
export const ADD_TO_CARD = 'add-to-card';
export const AUTH_SUCCESS = 'auth-success';

export const handleSendAnalytics = (param: string) => {
  if (isServer) {
    return null;
  }

  ym('reachGoal', param);

  trackEvent({
    event: param,
  });

  return false;
};
