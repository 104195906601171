export function removeEmpty<T extends Record<string, any>>(obj: T) {
  const res = { ...obj };

  for (const key in obj) {
    if (res[key] === '' || res[key] === null || res[key] === undefined) {
      delete res[key];
    }
  }

  return res;
}
