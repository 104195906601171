import { useState } from 'react';

import { useCheckoutWizard } from '@modules/checkout/CheckoutWizardContext';
import StepHeader from '@modules/checkout/components/StepHeader';
import Lab from '@modules/checkout/components/Lab';

import IndependentChoice from './components/IndependentChoice';

import isEnumValue from '@utils/isEnumValue';
import { useBasket } from '@hooks/useBasket';
import { useService } from '@hooks/useService';

import { LaboratoryEnum } from '@graphql/generated';

import s from '@modules/checkout/components/CheckoutWizard/CheckoutWizard.module.scss';

import StepsFooter from '../StepsFooter/StepsFooter';
import classNames from 'classnames';

const StepLabs = () => {
  const [selectedOptionId, setSelectedOptionId] = useState<string>();
  const [isChangeLab, setIsChangeLab] = useState(false);
  const { activeStepIndex, goToNextStep, goToPrevStep, handleStepSubmit } = useCheckoutWizard();
  const { basket } = useBasket();
  const products = basket?.products;

  const { defaultLaboratory } = useService();

  const defaultLab = defaultLaboratory?.getServiceByKey?.value
    ? (defaultLaboratory?.getServiceByKey?.value as unknown as LaboratoryEnum)
    : LaboratoryEnum.Labquest;

  const handleFormSubmit = () => {
    if (isEnumValue(selectedOptionId, LaboratoryEnum)) {
      if (products) {
        handleStepSubmit({
          laboratories: products.map((item) => ({ ...item, laboratory: null })),
        });
      }
      if (goToNextStep) goToNextStep();
    } else {
      setIsChangeLab(true);
    }
  };

  const handleOptionSelection = (id: string) => {
    setSelectedOptionId(id);
  };

  const handleReturnToOptionChoice = () => {
    setIsChangeLab(false);
  };

  const LABS = [
    {
      id: 'self',
      title: 'Хочу выбрать сам',
      image: '/images/bulb.svg',
      notice: 'Нажмите, если хотите самостоятельно выбрать лабораторию',
    },
    {
      id: defaultLab,
      title: 'Сделайте выбор за меня',
      image: '/images/bulb.svg',
      notice: 'Мы распределим ваш заказ между доступными лабораториями',
    },
  ];

  return (
    <>
      <StepHeader title="Выберите лабораторию" notice="В выбранную лабораторию мы отвезем ваши анализы.
      Все исследования соответствуют европейскому стандарту ISO-8493." />
      {isChangeLab && (
        <section className={classNames(s.partWrapper, s.last)}>
          <IndependentChoice onBackClick={handleReturnToOptionChoice} />
        </section>
      )}
      {!isChangeLab && (
        <>
          <section className={classNames(s.partWrapper, s.last)}>
            {LABS.map((item) => (
              <Lab
                key={item.title}
                id={item.id}
                isActive={selectedOptionId === item.id}
                title={item.title}
                image={item.image}
                onClick={handleOptionSelection}
                notice={item.notice}
              />
            ))}
          </section>

          <StepsFooter
            onSubmitClick={handleFormSubmit}
            showBackButton={activeStepIndex !== 0}
            onBackClick={goToPrevStep}
            disabled={!selectedOptionId}
          />
        </>
      )}
    </>
  );
};

export default StepLabs;
