import { gql } from '@apollo/client';

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFields on Address {
    id
    name
    country
    city
    address
    entrance
    floor
    apartment
    isPrimary
  }
`;
