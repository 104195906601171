import React from 'react';

import cn from 'classnames';

import Link from 'next/link';

import { LogoFooter } from '@components/index';
import { footerNavList } from '@components/constants';
import Text from '@components/Text';

import MailLink from './MailLink';
import PhoneNumber from './PhoneNumber';
import SocialLinks from './SocialLinks';
import FooterNavItem from '../FooterNavItem';
import s from './FooterNav.module.scss';
import useDeviceType from '@hooks/useDeviceType';
import { CURRENT_YEAR } from '@constants/index';
import { SCREEN } from '@constants/screen';

interface Props {
  className?: string;
}

const FooterNav: React.FC<Props> = ({ className }) => {
  const containerClass = 'container-wide';
  const classes = cn(s.root, className, containerClass);
  const { screenWidth } = useDeviceType();

  return (
    <div className={classes}>
      <div className={s.info}>
        <div className={s.logo}>
          <Link href="/">
            <a>
              <LogoFooter />
            </a>
          </Link>
        </div>

        <PhoneNumber className={s.links} />
        <MailLink className={s.links} />

        <SocialLinks className={s.socialLinks} />
        {screenWidth >= SCREEN.MOBILE_MD_BREAKPOINT ? (
          <></>
        ) : (
          <Text size={'medium'} className={s.brand}>
            © {CURRENT_YEAR} Лабклик. Все права защищены
          </Text>
        )}
      </div>
      <div className={s.navItems}>
        {footerNavList.map(({ title, id, links }) => (
          <FooterNavItem title={title} key={id} links={links} />
        ))}
      </div>
    </div>
  );
};

export default FooterNav;
