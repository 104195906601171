import React, { ReactElement } from 'react';
import classNames from 'classnames';

import styles from './SuggestionsMenu.module.scss';

interface MenuProps {
  className?: string;
  isOpen?: boolean;
  children?: ReactElement;
}

const SuggestionsMenu = React.forwardRef<HTMLUListElement, MenuProps>(
  ({ className, isOpen, children, ...rest }, ref) => (
    <ul ref={ref} className={classNames(styles.root, isOpen && styles.active, className)} {...rest}>
      {isOpen && children}
    </ul>
  )
);

export default SuggestionsMenu;
