import { FormEvent } from 'react';
import { useCombobox } from 'downshift';

import { Input, SuggestionsMenu, SuggestionsMenuItem } from '@components/index';

import styles from './AutocompleteInput.module.scss';

interface AutocompleteInputProps {
  type?: string;
  className?: string;
  autoComplete?: string;
  value?: string;
  onChange: (value?: string | FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
  containsError?: boolean;
  name?: string;
  suggestions?: string[];
}

const itemToString = (item: string | null) => item ?? '';

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  containsError,
  disabled,
  label,
  value,
  onChange,
  name,
  suggestions = [],
  ...otherProps
}) => {
  const { isOpen, getMenuProps, getInputProps, getComboboxProps, getItemProps } = useCombobox({
    items: suggestions,
    itemToString,
    onSelectedItemChange: (changes) => {
      onChange(changes.selectedItem!);
    },
    onInputValueChange: ({ inputValue }) => {
      onChange(inputValue);
    },
  });

  return (
    <div className={styles.root}>
      <div {...getComboboxProps()}>
        <Input
          {...getInputProps({ defaultValue: value, disabled, onChange, value, name, label })}
          {...otherProps}
          isError={containsError}
        />
      </div>

      <SuggestionsMenu isOpen={isOpen} {...getMenuProps()}>
        {suggestions.map((item, index) => (
          <SuggestionsMenuItem key={`${item}${index}`} {...getItemProps({ item, index })}>
            {item}
          </SuggestionsMenuItem>
        ))}
      </SuggestionsMenu>
    </div>
  );
};

export default AutocompleteInput;
