import cn from 'classnames';

import s from './InputWrapper.module.scss';

export interface InputPropsTypes {
  preContent?: React.ReactNode;
  postContent?: React.ReactNode;
  isError?: boolean;
  name?: string;
  label?: string;
}

export interface TOwnProps {
  isDisabled?: boolean;
  isFocused?: boolean;
}

const InputWrapper: React.FC<TOwnProps & InputPropsTypes> = ({
  preContent,
  postContent,
  isDisabled = false,
  isError = false,
  children,
  isFocused,
  ...props
}) => {
  const classes = cn(s.root, isDisabled && s.disabled, isError && s.err, isFocused && s.focused);

  return (
    <div className={classes} {...props}>
      {preContent ? <span className={s.preContent}>{preContent}</span> : null}

      {children}

      {postContent ? <span className={s.postContent}>{postContent}</span> : null}
    </div>
  );
};

export default InputWrapper;
