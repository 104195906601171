import { ReactNode } from 'react';
import cn from 'classnames';

import s from './Text.module.scss';

type HTMLTag = 'p' | 'span';

export type TextSize = 'extra-small' | 'small' | 'medium' | 'large' | 'large-plus' | 'extra-large';

interface TOwnProps {
  size?: TextSize;
  weight?: 'medium' | 'bold';
  fontgraphik?: boolean;
  children: ReactNode | string;
  className?: string;
  inline?: boolean;
  muted?: boolean;
  lineInherit?: boolean;
  uppercase?: boolean;
}

const Text: React.FC<TOwnProps> = ({
  size = 'medium',
  className,
  inline = false,
  weight = 'medium',
  fontgraphik,
  muted = false,
  lineInherit = false,
  uppercase = false,
  children,
}) => {
  const classes = cn(s[`size-${size}`], s[`weight-${weight}`], className, {
    [s.fontGraphik]: fontgraphik,
    [s.muted]: muted,
    [s.lineInherit]: lineInherit,
    [s.uppercase]: uppercase,
  });
  const Tag: HTMLTag = inline ? 'span' : 'p';

  return <Tag className={classes}>{children}</Tag>;
};

export default Text;
