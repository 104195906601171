export function renderAddressSuggestionMap(
  id: string,
  onAddressFinded?: (address: string) => void,
  coordinates?: ymaps.Coordinate | null
) {
  let myPlacemark: ymaps.Placemark | undefined;

  const myMap = new ymaps.Map(
    id,
    {
      center: [55.753994, 37.622093],
      zoom: 9,
      controls: ['zoomControl'],
    },
    {
      searchControlProvider: 'yandex#search',
    }
  );

  if (coordinates) {
    if (myPlacemark) {
      myPlacemark.geometry.setCoordinates(coordinates);
    } else {
      myPlacemark = createPlacemark(coordinates);
      myMap.geoObjects.add(myPlacemark);
    }
  }

  // Слушаем клик на карте.
  myMap.events.add('click', function (event) {
    const coords = event.get<ymaps.Coordinate>('coords');

    // Если метка уже создана – просто передвигаем ее.
    if (myPlacemark) {
      myPlacemark.geometry.setCoordinates(coords);
    }
    // Если нет – создаем.
    else {
      myPlacemark = createPlacemark(coords);
      myMap.geoObjects.add(myPlacemark);
      // Слушаем событие окончания перетаскивания на метке.
      myPlacemark?.events.add('dragend', function () {
        getAddress(myPlacemark?.geometry.getCoordinates());
      });
    }
    getAddress(coords);
  });

  // Создание метки.
  function createPlacemark(coords: ymaps.Coordinate | null) {
    if (!coords) {
      return;
    }

    return new ymaps.Placemark(
      coords,
      {},
      {
        iconLayout: 'default#image',
        iconImageHref: 'images/marker.svg',
        iconImageSize: [40, 40],
        iconImageOffset: [-25, -40],
        draggable: false,
      }
    );
  }

  // Определяем адрес по координатам (обратное геокодирование).
  async function getAddress(coords: ymaps.Coordinate | null | undefined) {
    const res = await ymaps.geocode(coords);

    const firstGeoObject = res.geoObjects.get(0);

    if (onAddressFinded) {
      onAddressFinded(firstGeoObject.getAddressLine());
    }
  }
}
