import React from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import { InputPropsTypes } from '@components/Input/Input';
import Input from '@components/Input';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ru', ru);

const DatePicker = React.forwardRef(
  ({
    isError,
    placeholderText,
    customInput,
    showPopperArrow = false,
    ...rest
  }: ReactDatePickerProps & Omit<InputPropsTypes, 'onChange'>) => (
    <div className="date-picker-labclick">
      <ReactDatePicker
        locale="ru"
        showPopperArrow={showPopperArrow}
        customInput={customInput ? customInput : <Input label={placeholderText} isError={isError} />}
        {...rest}
      />
    </div>
  )
);

export default DatePicker;
