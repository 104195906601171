import React, { useState } from 'react';
import classNames from 'classnames';

import { LaboratoryEnum, Product } from '@graphql/generated';
import { formatPrice } from '@utils/formatPrice';
import { getPackageLabs, isAvailable } from '@utils/isValidLabs';
import { useBasket } from '@hooks/useBasket';
import { useCheckoutWizard } from '@modules/checkout/CheckoutWizardContext';
import { AnalysisLabels, Divider, Title, Text } from '@components/index';

import LabsPopUp from '../LabsPopUp';
import { Laboratories } from '../../types';
import StepsFooter from '../../../StepsFooter/StepsFooter';

import styles from '@modules/checkout/components/CheckoutWizard/CheckoutWizard.module.scss';
import s from './IndependentChoice.module.scss';
import { useDeadline } from '@hooks/useDeadline';

const IndependentChoice: React.FC<{ onBackClick: () => void }> = ({ onBackClick }) => {
  const { goToNextStep, handleStepSubmit } = useCheckoutWizard();
  const { basket } = useBasket();
  const products = basket?.products;
  const [labsToProducts, setLabsToProducts] = useState<Laboratories>([]);
  const labsValues = (product: Product) => {
    const { id, isPackage, subProducts, unavailable, disabled } = product;
    const res: { id: string; icon: string; text: string; laboratory: LaboratoryEnum }[] = [];
    const addDialab = () => {
      res.push({
        id: `${id}-2`,
        icon: '/images/labs/dialab-icon-filled.svg',
        text: 'Диалаб',
        laboratory: LaboratoryEnum.Dialab,
      });
    };
    const addGemotest = () => {
      res.push({
        id: `${id}-1`,
        icon: '/images/labs/gemotest-icon.svg',
        text: 'Гемотест',
        laboratory: LaboratoryEnum.Gemotest,
      });
    };
    const addLabquest = () => {
      res.push({
        id: `${id}-3`,
        icon: '/images/labs/labquest-icon.svg',
        text: 'Лабквест',
        laboratory: LaboratoryEnum.Labquest,
      });
    };

    if (isPackage && subProducts) {
      const labs = getPackageLabs(subProducts);
      if (labs.includes(LaboratoryEnum.Labquest)) addLabquest();
      if (labs.includes(LaboratoryEnum.Gemotest)) addGemotest();
      if (labs.includes(LaboratoryEnum.Dialab)) addDialab();
    } else if (!unavailable && !disabled) {
      if (isAvailable(product, LaboratoryEnum.Labquest)) addLabquest();
      if (isAvailable(product, LaboratoryEnum.Gemotest)) addGemotest();
      if (isAvailable(product, LaboratoryEnum.Dialab)) addDialab();
    }
    return res;
  };

  const handleContinueClick = () => {
    handleStepSubmit({ laboratories: labsToProducts });
    if (goToNextStep) goToNextStep();
  };

  const checkoutProductView = () =>
    products?.map((item) => {
      const { id: productId } = item;

      let currentLab = undefined;
      if (labsToProducts.length > 0) {
        const labType = labsToProducts.filter((items) => items.id === productId);
        currentLab = labType[0]?.laboratory;
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { deadline } = useDeadline(item.product, currentLab);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [orderPage, setOrderPage] = useState<boolean>(labsValues(item.product).length === 1);
      const handelChangeLab = () => {
        setOrderPage(true);
      };

      return (
        <div className={s.root} key={item.product.id}>
          <div className={s.content}>
            <Title level={3} className={s.title} weight="medium">
              {item.product.title}
            </Title>
            <Text className={s.price} size="extra-large">
              {formatPrice(item.product.price)}
            </Text>
            <div className={s.labLabels}>
              <AnalysisLabels
                {...item.product}
                deadline={deadline}
                subProductsNumber={item.product.subProducts?.length}
                showLabsLabel
                labs={labsValues(item.product).map(({ text }) => text)}
                isCodeInline={false}
                orderPage={orderPage}
              />
            </div>
          </div>

          <Divider className={s.divider} />

          <div className={s.flex}>
            <LabsPopUp
              className={s.labsPopUp}
              label={'Выберите лабораторию'}
              values={labsValues(item.product)}
              basketProduct={item}
              setLabsToProducts={setLabsToProducts}
              handelChangeLab={handelChangeLab}
            />
          </div>
        </div>
      );
    });

  return (
    <>
      <section className={classNames(styles.partWrapper, styles.last)}>
        {checkoutProductView()}
        {/*{products?.map((item) => (*/}
        {/*  <div className={s.root} key={item.product.id}>*/}
        {/*    <div className={s.content}>*/}
        {/*      <Title level={3} className={s.title} weight="medium">*/}
        {/*        {item.product.title}*/}
        {/*      </Title>*/}
        {/*      <Text className={s.price} size="extra-large">*/}
        {/*        {formatPrice(item.product.price)}*/}
        {/*      </Text>*/}
        {/*      <div className={s.labLabels}>*/}
        {/*        <AnalysisLabels*/}
        {/*          {...item.product}*/}
        {/*          subProductsNumber={item.product.subProducts?.length}*/}
        {/*          showLabsLabel*/}
        {/*          labs={labsValues(item.product).map(({ text }) => text)}*/}
        {/*          isCodeInline={false}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*    <Divider className={s.divider} />*/}

        {/*    <div className={s.flex}>*/}
        {/*      <LabsPopUp*/}
        {/*        className={s.labsPopUp}*/}
        {/*        label={'Выберите лабораторию'}*/}
        {/*        values={labsValues(item.product)}*/}
        {/*        basketProduct={item}*/}
        {/*        setLabsToProducts={setLabsToProducts}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*))}*/}
      </section>

      <StepsFooter
        onSubmitClick={handleContinueClick}
        showBackButton={true}
        onBackClick={onBackClick}
        disabled={labsToProducts.length !== products?.length}
      />
    </>
  );
};

export default IndependentChoice;
